import { IModel } from '../../../../core/domain/types/model_interface';
import { Coupon } from './coupon';
import { Store } from './store';
export type PurchaseArgs = {
    id: number;
    coupon: Coupon;
    price: number;
    purchaseDate: string;
    exchangeDate?: string;
    exchangeStore?: Store;
    status: 'pending' | 'exchanged' | 'expired';
    code: string;
};
export class Purchase implements IModel {
    type = 'PurchaseModel';
    id: number;
    coupon: Coupon;
    price: number;
    purchaseDate: string;
    exchangeDate?: string;
    exchangeStore?: Store;
    status: 'pending' | 'exchanged' | 'expired';
    code: string;

    constructor({ id, coupon, price, purchaseDate, exchangeDate, exchangeStore, status, code }: PurchaseArgs) {
        this.id = id;
        this.coupon = coupon;
        this.price = price;
        this.purchaseDate = purchaseDate;
        this.exchangeDate = exchangeDate;
        this.exchangeStore = exchangeStore;
        this.status = status;
        this.code = code;
    }
}
