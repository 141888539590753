import React from 'react';
type SVGIconProps = {
    url: string;
};
export const SVGIcon = ({ url }: SVGIconProps) => {
    return (
        <span className="icon" style={{ marginRight: '5px' }}>
            <img src={url}></img>
        </span>
    );
};
