import { Consumer } from '../../../../core/domain/models/consumer_model';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { IAuthenticationRepository } from '../repositories/IAuthenticationRepository';

export class GetAuthenticatedConsumerUseCase implements IUseCase<Consumer, void> {
    repository: IAuthenticationRepository;

    constructor(authenticationRepository: IAuthenticationRepository) {
        this.repository = authenticationRepository;
    }

    async execute(): Promise<Consumer | IFailure> {
        const result = await this.repository.getAuthenticated();
        return Promise.resolve(result);
    }
}
