import { CircularProgress, Grid, Typography } from '@material-ui/core';

import { AuthorizationFailure } from './../../../../core/domain/failures/authorization_failure';
import { BlocBuilder } from '../../../../common/bloc';
import { EcoPointsMapState } from '../blocs/ecopoints_states';
import FullScreenDialog from '../../../../core/presentation/components/FullScreenDialog/fullscreen_dialog';
import Language from './../../../../core/presentation/strings/LanguageManager';
import { Logout } from '../../../../core/presentation/components/LogoutRedirect/logout_redirect';
import { Map } from '../../../../core/presentation/components/Map/map';
import React from 'react';
import { RoundedContainer } from '../../../../core/presentation/components/RoundedContainer/rounded_container';
import { isDesktop } from 'react-device-detect';
import { useBlocSuscription } from '../../../../core/presentation/hooks/useBlocSuscription';
import { useEcoPointsMapBloc } from '../../../../core/presentation/contexts/contexts';
import useGeoLocation from '../../../../core/presentation/hooks/useLocation';
import { useSnackbar } from 'notistack';

const server_url = process.env.REACT_APP_ADMIN_SERVER_URL;

type EcoPointsPageProps = {
    onClose?: () => void;
};

export const EcoPointsPage: React.FC<EcoPointsPageProps> = ({
    onClose = () => {
        return;
    },
}: EcoPointsPageProps) => {
    const bloc = useEcoPointsMapBloc();
    const location = useGeoLocation();
    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        bloc.close();
        onClose();
    };

    useBlocSuscription<EcoPointsMapState>({
        bloc,
        action: (state) => {
            if (state.type === 'Failure') {
                enqueueSnackbar(Language.strings.unknown_failure, { variant: 'error' });
            }
        },
    });

    useBlocSuscription<EcoPointsMapState>({
        bloc,
        action: (state) => {
            if (state.type !== 'EcoPointsMapClosed' && isDesktop) {
                enqueueSnackbar(Language.strings.location_uncertain, { variant: 'info' });
            }
        },
    });

    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: EcoPointsMapState) => {
                if (state.type === 'Failure' && state.failure instanceof AuthorizationFailure) return <Logout />;
                const open = state.type !== 'EcoPointsMapClosed';
                return (
                    <FullScreenDialog
                        open={open}
                        title={Language.strings.ecopoint_where_to_validate}
                        onClose={handleClose}
                    >
                        <>
                            <RoundedContainer
                                top={80}
                                maxHeight={true}
                                title={Language.strings.ecopoint_ecopoints}
                                color="white-transparent"
                            >
                                <>
                                    <Grid container spacing={2} style={{ marginTop: '0.2rem' }}>
                                        {state.type === 'EcoPointsMapLoading' ? (
                                            <CircularProgress color="primary" style={{ margin: '0 auto' }} />
                                        ) : null}
                                        {state.type === 'EcoPointsMapLoaded' &&
                                        location &&
                                        location.loaded &&
                                        location.coordinates &&
                                        !location.error ? (
                                            <Map
                                                points={state.ecoPoints.map((ecoPoint) => ({
                                                    coordinates: ecoPoint.coordinates,
                                                    popup: (
                                                        <>
                                                            <span>{`${ecoPoint.name}`}</span>
                                                            <br />
                                                            <span>{`${Language.strings.ecopoint_address}: ${ecoPoint.address}`}</span>
                                                            <br />
                                                            <span>{`${Language.strings.ecopoint_schedules}: ${ecoPoint.schedules}`}</span>
                                                            <br />
                                                            <span>{`${Language.strings.ecopoint_residue_types}:`}</span>
                                                            <br />
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexWrap: 'wrap',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                {ecoPoint.residueTypes.map((residueType, id) => (
                                                                    <img
                                                                        key={id}
                                                                        src={`${server_url}${residueType.image}`}
                                                                        style={{ maxWidth: '40px', padding: '2px' }}
                                                                    />
                                                                ))}
                                                            </div>
                                                        </>
                                                    ),
                                                }))}
                                                center={location.coordinates}
                                            />
                                        ) : null}

                                        {state.type === 'Failure' ? (
                                            <Grid item xs={12}>
                                                <Typography
                                                    color="error"
                                                    style={{ margin: '0 auto', textAlign: 'center' }}
                                                >
                                                    <Map
                                                        center={
                                                            location?.coordinates || {
                                                                lat: '-31.419973',
                                                                lng: '-64.188929',
                                                            }
                                                        }
                                                        points={[]}
                                                    />
                                                </Typography>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </>
                            </RoundedContainer>
                        </>
                    </FullScreenDialog>
                );
            }}
        ></BlocBuilder>
    );
};
