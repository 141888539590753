import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { areAnyNil } from '../../../../core/data/utils/areAnyNil';
import { Coordinates } from '../../../../core/domain/types/Coordinates';
import { IEntity } from '../../../../core/domain/types/entity_interface';
import { ResidueTypeEntity } from '../../../residue_types/data/entities/residue_type_entity';
import { EcoPoint, EcoPointArgs } from '../../domain/models/ecopoint';

type EcoPointEntityArgs = {
    id: number;
    name: string;
    address: string;
    coordinates: Coordinates;
    schedules: string;
    residueTypes: ResidueTypeEntity[];
};

export class EcoPointEntity implements IEntity {
    type = 'EcoPointEntity';
    id: number;
    name: string;
    address: string;
    coordinates: Coordinates;
    schedules: string;
    residueTypes: ResidueTypeEntity[];

    constructor({ id, name, address, coordinates, schedules, residueTypes }: EcoPointEntityArgs & EcoPointArgs) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.coordinates = coordinates;
        this.schedules = schedules;
        this.residueTypes = residueTypes;
    }

    toModel(): EcoPoint {
        return new EcoPoint({ ...this, residueTypes: this.residueTypes.map((rt) => rt.toModel()) });
    }

    static fromObject(obj: any) {
        try {
            const { id, address, coord_lat, coord_lng, schedules, name, residue_types } = obj;
            if (areAnyNil([id, address, coord_lat, coord_lng, schedules, name, residue_types])) {
                throw new Error();
            }
            return new EcoPointEntity({
                id,
                name,
                address,
                schedules,
                coordinates: { lat: coord_lat, lng: coord_lng },
                residueTypes: residue_types.map((rt: any) => ResidueTypeEntity.fromObject(rt)),
            });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'EcoPointEntity');
        }
    }
}
