import { Coordinates } from '../../../../core/domain/types/Coordinates';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { Coupon } from '../models/coupon';
import { Store } from '../models/store';
import { ICouponsRepository } from '../repositories/coupons_repository_interface';

export type GetCouponExchangeStoresParams = {
    coupon: Coupon;
    coordinates: Coordinates;
};

export class GetCouponExchangeStoresUseCase implements IUseCase<Store[], GetCouponExchangeStoresParams> {
    repository: ICouponsRepository;

    constructor(repository: ICouponsRepository) {
        this.repository = repository;
    }

    async execute({ coupon, coordinates }: GetCouponExchangeStoresParams): Promise<Store[] | IFailure> {
        return await this.repository.getCouponExchangeStores({ coupon, coordinates });
    }
}
