import { Bloc } from '../../../../../common/bloc';
import { Failure } from '../../../../../core/domain/failures/failure';
import { RecoverPassword } from '../../../domain/usecases/recover_password';
import { initialState, RecoveryConfirmationState } from './recovery_confirmation_states';

export class RecoveryConfirmationBloc extends Bloc<RecoveryConfirmationState> {
    usecase: RecoverPassword;
    constructor(usecase: RecoverPassword) {
        super(initialState);
        this.usecase = usecase;
    }

    async submitConfirmation(token: string): Promise<void> {
        this.changeState({ ...this.state, type: 'SubmittingConfirmation' });
        const result = await this.usecase.execute({ token, password: this.state.password });
        if (result instanceof Failure) {
            this.changeState({ ...this.state, type: 'Failure', failure: result });
            return;
        }
        this.changeState({ ...this.state, type: 'ConfirmationSubmitted' });
    }

    async changePassword(password: string): Promise<void> {
        this.changeState({ ...this.state, type: 'PasswordChanged', password });
    }
}
