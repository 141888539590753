import { Failure } from '../../../../../core/domain/failures/failure';

export interface ICommonState {
    email: string;
}

export interface IDefaultState {
    type: 'DefaultState';
    email: '';
}

export interface ISubmittingRequest {
    type: 'SubmittingRequest';
}

export interface IRequestSubmitted {
    type: 'RequestSubmitted';
}

export interface IEmailChanged {
    type: 'EmailChanged';
}

export interface IFailure {
    type: 'Failure';
    failure: Failure;
}

export type RecoveryRequestState = (IDefaultState | ISubmittingRequest | IRequestSubmitted | IEmailChanged | IFailure) &
    ICommonState;

export const initialState: RecoveryRequestState = { type: 'DefaultState', email: '' };
