import { AxiosInstance } from 'axios';
import { IAxiosDataSource } from '../../../../core/data/types/axios_datasource_interface';
import { httpToError } from '../../../../core/data/utils/httpToError';
import { GetResiduesUseCaseParams } from '../../domain/usecases/get_residues_use_case';
import { ResidueEntity } from '../entities/residues_entity';
import { IResiduesDataSource } from './types/residues_datasource_interface';

export class AxiosResiduesDataSource implements IResiduesDataSource, IAxiosDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async getResidues({ query, page, size }: GetResiduesUseCaseParams): Promise<ResidueEntity[]> {
        try {
            const params = { criteria: query, page, size };
            const { data } = await this.axiosInstance.get('residues', { params });
            return Promise.resolve((data as Array<any>).map((item: any) => ResidueEntity.fromObject(item)));
        } catch (error) {
            return Promise.reject(httpToError<null>(error));
        }
    }
}
