export interface ResidueStrings {
    residue_type: string;
    residue_title: string;
    residue_subtitle_reminder: string;
    residue_subtitle_browser: string;
    residue_no_results: string;
    residues_search: string;
}

export const residueStrings: ResidueStrings = {
    residue_type: 'Tipo',
    residue_title: 'BUSCAR RESIDUOS',
    residue_subtitle_browser: 'Seleccioná un material para reciclar',
    residue_subtitle_reminder: 'TE RECORDAMOS',
    residue_no_results: 'No se han encontrado resultados...  :(',
    residues_search: 'Buscar: Ej. Botella de plástico',
};
