import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { Residue } from '../models/residue';
import { IResiduesRepository } from '../repositories/residues_repository_interface';

export type GetResiduesUseCaseParams = {
    query?: string;
    page: number;
    size: number;
};

export class GetResiduesUseCase implements IUseCase<Residue[], GetResiduesUseCaseParams> {
    repository: IResiduesRepository;

    constructor(repository: IResiduesRepository) {
        this.repository = repository;
    }
    async execute({ query, page, size }: GetResiduesUseCaseParams): Promise<Residue[] | IFailure> {
        return Promise.resolve(await this.repository.getResidues({ query, page, size }));
    }
}
