import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { areAnyNil } from '../../../../core/data/utils/areAnyNil';
import { IEntity } from '../../../../core/domain/types/entity_interface';
import { WalletModel, WalletModelParams } from '../../domain/models/wallet_model';

type WalletEntityParams = {
    id: number;
};

export class WalletEntity extends WalletModel implements IEntity {
    id: number;

    constructor({ id, pending_balance, effective_balance }: WalletEntityParams & WalletModelParams) {
        super({ pending_balance, effective_balance });
        this.id = id;
    }
    toModel(): WalletModel {
        return new WalletModel({ pending_balance: this.pending_balance, effective_balance: this.effective_balance });
    }

    static fromObject(obj: any): WalletEntity {
        try {
            const { id, pending_balance, effective_balance } = obj;
            if (areAnyNil([id, pending_balance, effective_balance])) throw new Error();
            return new WalletEntity({ id, pending_balance, effective_balance });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'WalletEntity');
        }
    }
}
