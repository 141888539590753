import { CircularProgress, Grid } from '@material-ui/core';
import {
    default as Language,
    default as LanguageManager,
} from './../../../../../core/presentation/strings/LanguageManager';

import { AuthorizationFailure } from './../../../../../core/domain/failures/authorization_failure';
import { BlocBuilder } from '../../../../../common/bloc';
import FullScreenDialog from '../../../../../core/presentation/components/FullScreenDialog/fullscreen_dialog';
import { Logout } from '../../../../../core/presentation/components/LogoutRedirect/logout_redirect';
import { Map } from '../../../../../core/presentation/components/Map/map';
import React from 'react';
import { RoundedContainer } from '../../../../../core/presentation/components/RoundedContainer/rounded_container';
import { StoresMapState } from '../../blocs/StoresMapBloc/store_map_bloc_states';
import { isDesktop } from 'react-device-detect';
import { useBlocSuscription } from '../../../../../core/presentation/hooks/useBlocSuscription';
import { useSnackbar } from 'notistack';
import { useStoresMapBloc } from '../../../../../core/presentation/contexts/contexts';

type ExchangeStoresPageProps = {
    onClose?: () => void;
};

export const ExchangeStoresPage: React.FC<ExchangeStoresPageProps> = ({
    onClose = () => {
        return;
    },
}: ExchangeStoresPageProps) => {
    const bloc = useStoresMapBloc();
    const { enqueueSnackbar } = useSnackbar();

    useBlocSuscription<StoresMapState>({
        bloc,
        action: (state) => {
            if (state.type === 'Failure') {
                enqueueSnackbar(LanguageManager.strings.unknown_failure, { variant: 'error' });
            }
        },
    });

    const handleClose = () => {
        bloc.close();
        onClose();
    };
    useBlocSuscription<StoresMapState>({
        bloc,
        action: (state) => {
            if (state.type !== 'StoresMapClosed' && isDesktop) {
                enqueueSnackbar(LanguageManager.strings.location_uncertain, { variant: 'info' });
            }
        },
    });

    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: StoresMapState) => {
                if (state.type === 'Failure' && state.failure instanceof AuthorizationFailure) return <Logout />;
                const open = state.type !== 'StoresMapClosed';
                return (
                    <FullScreenDialog
                        open={open}
                        title={Language.strings.coupons_where_to_exchange}
                        onClose={handleClose}
                    >
                        <>
                            <RoundedContainer top={80} maxHeight={true} title="Tiendas" color="white-transparent">
                                <>
                                    <Grid container spacing={2} style={{ marginTop: '0.2rem' }}>
                                        {state.type === 'StoresMapLoading' ? (
                                            <CircularProgress color="primary" style={{ margin: '0 auto' }} />
                                        ) : null}
                                        {state.type === 'StoresMapLoaded' ? (
                                            <Map
                                                points={state.stores.map((store) => ({
                                                    coordinates: store.coordinates,
                                                    popup: (
                                                        <>
                                                            <span>{`${store.name}`}</span>
                                                            <br />
                                                            <span>{`${Language.strings.coupons_store_address}: ${store.address}`}</span>
                                                            <br />
                                                            <span>{`${Language.strings.coupons_store_phoneNumber}: ${store.phoneNumber}`}</span>
                                                        </>
                                                    ),
                                                }))}
                                            />
                                        ) : null}

                                        {state.type === 'Failure' ? (
                                            <Map
                                                center={{
                                                    lat: '-31.419973',
                                                    lng: '-64.188929',
                                                }}
                                                points={[]}
                                            />
                                        ) : null}
                                    </Grid>
                                </>
                            </RoundedContainer>
                        </>
                    </FullScreenDialog>
                );
            }}
        ></BlocBuilder>
    );
};
