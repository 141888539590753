export interface WalletStrings {
    wallet_balance: string;
    wallet_pending_balance: string;
    wallet_helper: string;
    wallet_collect: string;
    wallet_ecopoints_map: string;
    wallet_your_wallet: string;
    wallet_available_balance: string;
    wallet_buy_coupons: string;
    wallet_my_coupons: string;
}

export const walletStrings: WalletStrings = {
    wallet_balance: 'Puntos',
    wallet_pending_balance: 'Puntos a Validar',
    wallet_helper: '*Validar en Ecopuntos',
    wallet_collect: 'CARGAR RESIDUOS',
    wallet_ecopoints_map: 'VALIDAR en ECOPUNTOS',
    wallet_your_wallet: 'Tus Puntos',
    wallet_available_balance: 'Puntos Disponibles',
    wallet_buy_coupons: 'COMPRAR CUPONES',
    wallet_my_coupons: 'MIS CUPONES',
};
