import { Button, Grid, Typography } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';

import { AuthenticationState } from '../blocs/authentication_bloc/authentication_states';
import { AuthorizationFailure } from './../../../../core/domain/failures/authorization_failure';
import { BlocBuilder } from '../../../../common/bloc';
import Language from '../../../../core/presentation/strings/LanguageManager';
import MuiTextField from '../../../../core/presentation/components/MUITextField/MuiTextField';
import { PasswordInput } from '../../../../core/presentation/components/PasswordInput/password_input';
import React from 'react';
import { ValidationFailure } from './../../../../core/domain/failures/validation_failure';
import { useAuthBloc } from '../pages/LoginPage/login_page';
import { useStyles } from './styles';

export const LoginForm: React.FC = () => {
    const classes = useStyles();
    const bloc = useAuthBloc();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        bloc.changeInput({ property: event.target.name, value: event.target.value });
    };
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        bloc.authenticate();
    };

    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: AuthenticationState) => {
                if (state.type === 'AuthenticationSuccessful') return <Redirect to="/home" />;
                if (
                    state.type === 'DefaultAuthentication' ||
                    state.type === 'AuthenticationChanged' ||
                    state.type === 'SubmittingAuthentication' ||
                    state.type === 'Failure'
                )
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} justify="space-between">
                                <Grid item xs={12}>
                                    <MuiTextField
                                        autoFocus
                                        name="username"
                                        value={state.username}
                                        onChange={handleChange}
                                        type="text"
                                        label={Language.strings.login_username}
                                        variant="filled"
                                        fullWidth={true}
                                        className={`${classes.input}`}
                                        disabled={state.type === 'SubmittingAuthentication'}
                                        error={
                                            state.type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.username !== undefined
                                        }
                                        helperText={Language.getErrorString('login', 'username', state)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordInput
                                        name="password"
                                        value={state.password}
                                        onChange={handleChange}
                                        className={`${classes.input}`}
                                        fullWidth={true}
                                        disabled={state.type === 'SubmittingAuthentication'}
                                        label={Language.strings.login_password}
                                        error={
                                            state.type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.password !== undefined
                                        }
                                        helperText={Language.getErrorString('login', 'password', state)}
                                    />
                                </Grid>
                                <Grid item container justify="flex-end">
                                    {state.type === 'Failure' &&
                                    !(state.failure instanceof ValidationFailure) &&
                                    !(state.failure instanceof AuthorizationFailure) ? (
                                        <Grid item xs={12}>
                                            <Typography color="error">{Language.strings.unknown_failure}</Typography>
                                        </Grid>
                                    ) : null}
                                    {state.type === 'Failure' && state.failure instanceof AuthorizationFailure ? (
                                        <Grid item xs={12}>
                                            <Typography color="error">
                                                {Language.strings.login_login_authentication_error}
                                            </Typography>
                                        </Grid>
                                    ) : null}
                                    <Grid item xs={12} className={classes.actionButton}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={state.type === 'SubmittingAuthentication'}
                                            type="submit"
                                        >
                                            {state.type === 'SubmittingAuthentication' ? 'Ingresando...' : 'INGRESAR'}
                                        </Button>
                                    </Grid>
                                    <Grid item className={classes.link} xs={12}>
                                        <Link to="password/request">Olvidé mi usuario o contraseña</Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    );
                else return <></>;
            }}
        />
    );
};
