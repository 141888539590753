import { Grid, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import di from '../../../../../common/di/di';
import { SimpleHeader } from '../../../../../core/presentation/components/SimpleHeader/simple_header';
import { WaveBackground } from '../../../../../core/presentation/components/wave_background';
import { recoveryRequestContext } from '../../../../../core/presentation/contexts/contexts';
import Language from './../../../../../core/presentation/strings/LanguageManager';
import RecoveryRequestForm from './components/RecoveryRequestForm/recovery_request_form';
import { useStyles } from './styles';

const RecoveryRequestPage: React.FC = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <WaveBackground top={28} />
            <Grid container className={`${classes.pageContainer}`}>
                <Grid item container direction="column" spacing={2}>
                    <SimpleHeader text="" variant="big" logo="large" />
                    <Grid item className={classes.title}>
                        <Typography variant="h1" color="textSecondary">
                            {Language.strings.request_title}
                        </Typography>
                    </Grid>
                    <Grid item className={`${classes.formContainer}`}>
                        <recoveryRequestContext.Provider value={di.recoveryRequestBloc()}>
                            <RecoveryRequestForm />
                        </recoveryRequestContext.Provider>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default RecoveryRequestPage;
