import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const media = theme.breakpoints.up('sm');

    return createStyles({
        pageContainer: {
            padding: theme.spacing(2, 5),
        },
        [theme.breakpoints.up('sm')]: {
            pageContainer: {
                width: '550px',
                margin: '0 auto',
            },
        },
        space2: {
            marginTop: '2rem',
        },
    });
});
