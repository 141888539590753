import { Bloc } from '../../../../../common/bloc';
import { Failure } from '../../../../../core/domain/failures/failure';
import { Coordinates } from '../../../../../core/domain/types/Coordinates';
import { Coupon } from '../../../domain/models/coupon';
import { GetCouponsUseCase } from '../../../domain/usecases/get_coupons_use_case';
import { browserInitialState, CouponsBrowserState } from './coupons_browser_states';

type CouponsBrowserBlocParams = {
    getCouponsUseCase: GetCouponsUseCase;
    couponsPageSize: number;
};

export class CouponsBrowserBloc extends Bloc<CouponsBrowserState> {
    getCouponsUseCase: GetCouponsUseCase;
    couponsPageSize: number;
    constructor({ getCouponsUseCase, couponsPageSize }: CouponsBrowserBlocParams) {
        super(browserInitialState);
        this.getCouponsUseCase = getCouponsUseCase;
        this.couponsPageSize = couponsPageSize;
    }

    async resetBrowser(): Promise<void> {
        this.changeState({ ...browserInitialState, query: this.state.query });
    }

    async getCoupons(coordinates: Coordinates): Promise<void> {
        const { page, query } = this.state;
        const params = {
            query,
            page,
            size: this.couponsPageSize,
        };
        const result = await this.getCouponsUseCase.execute({ ...params, coordinates });
        if (result instanceof Failure) {
            this.changeState({ ...this.state, type: 'Failure', failure: result });
            return;
        }
        this.changeState({
            ...this.state,
            type: 'CouponsLoaded',
            coupons: [...this.state.coupons, ...(result as Coupon[])],
            page: this.state.page + 1,
            hasMore: (result as Coupon[]).length / this.couponsPageSize >= 1,
        });
        return;
    }

    async changeQuery(query: string): Promise<void> {
        this.changeState({ ...this.state, type: 'CouponsQueryChanged', query });
    }

    async locationPermissionDeniedFailure(): Promise<void> {
        this.changeState({ ...this.state, type: 'LocationPermissionDeniedFailure' });
    }
    async locationFailure(): Promise<void> {
        this.changeState({ ...this.state, type: 'LocationFailure' });
    }
}
