import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Language from '../../../../core/presentation/strings/LanguageManager';
import { useStyles } from './styles';

const SuccessMessage = ({ history }: RouteComponentProps) => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <Paper className={classes.success} elevation={3}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography>{Language.strings.registration_success}</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Button variant="contained" color="primary" onClick={() => history.push('/login')}>
                            {Language.strings.registration_login}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default withRouter(SuccessMessage);
