import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface PaperProps {
    backgroundColor: string | undefined;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginBottom: '15px',
            padding: '0 1.5rem',
            [theme.breakpoints.up('sm')]: {
                padding: '0',
            },
        },
        balance: {
            fontWeight: 'bold',
            fontSize: '2rem',
        },
    }),
);
