import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { areAnyNil } from '../../../../core/data/utils/areAnyNil';
import { IEntity } from '../../../../core/domain/types/entity_interface';
import { Coupon, CouponParams } from '../../domain/models/coupon';

export class CouponEntity extends Coupon implements IEntity {
    constructor({ id, title, description, price, image, terms, finishDate, stock, category }: CouponParams) {
        super({ id, title, description, price, image, terms, finishDate, stock, category });
    }
    toModel(): Coupon {
        return new Coupon(this);
    }

    static fromObject(obj: any): CouponEntity {
        try {
            const { id, title, description, price, image, terms, finish_date, stock } = obj;
            if (areAnyNil([id, title, description, price, image, terms, finish_date, finish_date?.date, stock]))
                throw new Error();
            const category = obj.category.name;
            return new CouponEntity({
                id,
                title,
                description,
                price: parseFloat(price),
                image,
                terms,
                finishDate: finish_date.date,
                stock,
                category,
            });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'CouponEntity');
        }
    }
}
