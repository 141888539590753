import { Failure } from '../../../../../core/domain/failures/failure';

export interface ICommonState {
    name: string;
    surname: string;
    email: string;
    identification_number: number;
    image?: string;
    uploadedImage?: File;
}

export interface IPageOpened {
    type: 'PageOpened';
}
export interface IPageClosed {
    type: 'PageClosed';
}
export interface ISubmittingProfile {
    type: 'SubmittingProfile';
}
export interface IProfileSubmitted {
    type: 'ProfileSubmitted';
}

export interface IInputChanged {
    type: 'InputChanged';
}

export interface IFailure {
    type: 'Failure';
    failure: Failure;
}

export type ProfilePageState = (
    | IPageOpened
    | IPageClosed
    | ISubmittingProfile
    | IProfileSubmitted
    | IInputChanged
    | IFailure
) &
    ICommonState;

export const initialState: ProfilePageState = {
    type: 'PageClosed',
    name: '',
    surname: '',
    email: '',
    identification_number: 0,
};
