import React from 'react';
import ecofootprint_icon from '../../../../assets/images/ecoFootPrint.svg';
import cart_icon from '../../../../assets/images/icons/cart_icon.svg';
import cupon_icon from '../../../../assets/images/icons/cupon_icon.svg';
import location_icon from '../../../../assets/images/icons/location_icon.svg';
import recicle_icon from '../../../../assets/images/icons/recicle_icon.svg';
import { SVGIcon } from '../SVGIcon/svg_icon';

export const LocationIcon: React.FC = () => {
    return <SVGIcon url={`${location_icon}`} />;
};
export const RecicleIcon: React.FC = () => {
    return <SVGIcon url={`${recicle_icon}`} />;
};
export const CartIcon: React.FC = () => {
    return <SVGIcon url={`${cart_icon}`} />;
};

export const CuponIcon: React.FC = () => {
    return <SVGIcon url={`${cupon_icon}`} />;
};

export const EcoFootprintIcon: React.FC = () => {
    return <SVGIcon url={`${ecofootprint_icon}`} />;
};
