export interface CouponStrings {
    coupons_coupon: string;
    coupons_coupons: string;

    coupons_browser_not_found: string;
    coupons_search: string;
    coupons_expired_abr: string;
    coupons_exchanged_at: string;
    coupons_valid_until: string;
    coupons_stock: string;
    coupons_purchase_successful: string;
    coupons_code: string;
    coupons_use: string;
    coupons_purchase: string;
    coupons_where_to_exchange: string;
    coupons_store_address: string;
    coupons_store_phoneNumber: string;
    coupons_purchase_details: string;
    coupons_see_stores: string;
    coupons_my_coupons: string;
    coupons_available_coupons: string;
    coupons_expired_coupons: string;
    coupons_exchanged_coupons: string;

    coupons_createPurchase_expired_coupon_error: string;
    coupons_createPurchase_amount_wallet_error: string;
    coupons_createPurchase_stock_coupon_error: string;

    coupons_purchase_location_failure: string;
    coupons_purchase_location_permission_failure: string;
}

export const couponStrings: CouponStrings = {
    coupons_coupon: 'Cupón',
    coupons_coupons: 'Cupones',
    coupons_browser_not_found: 'No se han encontrado cupones  :(',
    coupons_search: 'Buscar: Ej. Helado',
    coupons_expired_abr: 'Venc.',
    coupons_exchanged_at: 'Canjeado el',
    coupons_valid_until: 'Cupón válido hasta el',
    coupons_stock: 'STOCK',
    coupons_purchase_successful: 'COMPRA EXITOSA',
    coupons_code: ' Código de Cupón',
    coupons_use: '*Presentar éste código en cualquiera de los comercios adheridos.',
    coupons_purchase: 'COMPRAR CUPONES',
    coupons_where_to_exchange: 'DONDE CANJEAR',
    coupons_store_address: 'Dirección',
    coupons_store_phoneNumber: 'Teléfono',
    coupons_purchase_details: 'DETALLES DE COMPRA',
    coupons_see_stores: 'Ver Comercios',
    coupons_my_coupons: 'MIS CUPONES',
    coupons_available_coupons: 'CUPONES HABILITADOS',
    coupons_expired_coupons: 'CUPONES VENCIDOS',
    coupons_exchanged_coupons: 'CUPONES CANJEADOS',

    coupons_createPurchase_expired_coupon_error: 'Operación fallida, el cupón seleccionado ha expirado.',
    coupons_createPurchase_amount_wallet_error:
        'Operación fallida, no posee suficientes fondos para realizar esta compra.',
    coupons_createPurchase_stock_coupon_error: 'Operación fallida, no hay stock disponible',

    coupons_purchase_location_failure: 'Error: No podemos obtener tu ubicación, inténtalo de nuevo más tarde.',
    coupons_purchase_location_permission_failure: 'Error: No podemos obtener tu ubicación por falta de permisos.',
};
