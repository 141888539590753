import { Coordinates } from '../../../../core/domain/types/Coordinates';
import { IModel } from '../../../../core/domain/types/model_interface';
import { ResidueType } from '../../../residue_types/domain/models/residue_type';

export type EcoPointArgs = {
    name: string;
    address: string;
    coordinates: Coordinates;
    schedules: string;
    residueTypes: ResidueType[];
};

export class EcoPoint implements IModel {
    type = 'EcoPointModel';
    name: string;
    address: string;
    coordinates: Coordinates;
    schedules: string;
    residueTypes: ResidueType[];

    constructor({ name, address, coordinates, schedules, residueTypes }: EcoPointArgs) {
        this.name = name;
        this.address = address;
        this.coordinates = coordinates;
        this.schedules = schedules;
        this.residueTypes = residueTypes;
    }
}
