import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const media = theme.breakpoints.up('sm');

    return createStyles({
        searchBox: {
            ['& .MuiInputBase-root']: {
                backgroundColor: '#FBFBFBC4',
            },
            ['& .MuiOutlinedInput-adornedEnd']: {
                paddingRight: 0,
            },
            ['& .MuiIconButton-root']: {
                borderLeft: `1px solid ${theme.palette.secondary.main}`,
            },
            ['& .MuiOutlinedInput-notchedOutline']: {
                border: `1px solid ${theme.palette.secondary.main}`,
            },
        },
        searchBoxContainer: {
            marginBottom: '0.5rem',
            padding: '0 1rem',
            [theme.breakpoints.up('sm')]: {
                padding: '0',
            },
        },
    });
});
