import { IconButton } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import React from 'react';
import { useStyles } from './styles';

type UploadButtonProps = {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    icon?: React.ReactElement;
    color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
    className?: string;
    name?: string;
};

export const UploadButton = ({ onChange, name, icon, color, className }: UploadButtonProps) => {
    const classes = useStyles();
    return (
        <>
            <input
                accept=".png,.jpg,.jpeg"
                name={name}
                className={classes.input}
                id="icon-button-file"
                type="file"
                onChange={onChange}
            />
            <label htmlFor="icon-button-file">
                <IconButton color={color} aria-label="upload picture" component="span" className={className}>
                    {icon ? icon : <PublishIcon />}
                </IconButton>
            </label>
        </>
    );
};
