import { CircularProgress, Grid } from '@material-ui/core';
import React from 'react';
import BlocBuilder from '../../../../../common/bloc/BlocBuilder';
import FullScreenDialog from '../../../../../core/presentation/components/FullScreenDialog/fullscreen_dialog';
import { Logout } from '../../../../../core/presentation/components/LogoutRedirect/logout_redirect';
import { RoundedContainer } from '../../../../../core/presentation/components/RoundedContainer/rounded_container';
import { usePurchaseBloc, usePurchasesPageBloc } from '../../../../../core/presentation/contexts/contexts';
import { Purchase } from '../../../domain/models/purchase';
import { PurchasesPageState } from '../../blocs/PurchasesPageBloc/purchases_page_bloc_states';
import { CouponCard } from '../../components/CouponCard/coupon_card';
import { AuthorizationFailure } from './../../../../../core/domain/failures/authorization_failure';
import Language from './../../../../../core/presentation/strings/LanguageManager';
import { useStyles } from './styles';

export const PurchasesPage = () => {
    const classes = useStyles();
    const purchasesBloc = usePurchasesPageBloc();
    const purchaseBloc = usePurchaseBloc();
    const handleClose = () => {
        purchasesBloc.close();
    };
    const handlePurchaseClick = (purchase: Purchase) => {
        purchaseBloc.displayPurchase(purchase);
    };
    return (
        <BlocBuilder
            bloc={purchasesBloc}
            builder={(state: PurchasesPageState) => {
                if (state.type === 'Failure' && state.failure instanceof AuthorizationFailure) return <Logout />;
                return (
                    <FullScreenDialog
                        open={state.open}
                        title={Language.strings.coupons_my_coupons}
                        onClose={handleClose}
                        allowScroll={true}
                    >
                        <>
                            <div className={classes.stackItem}>
                                <RoundedContainer
                                    top={200}
                                    maxHeight={true}
                                    title={`${Language.strings.coupons_available_coupons} ${
                                        state.type === 'PurchasesLoaded' ? '(' + state.pending.length + ')' : ''
                                    }`}
                                    color="white-transparent"
                                    className={'rounded-container'}
                                >
                                    <Grid container spacing={2} className={classes.horizontalContainer}>
                                        {state.type === 'LoadingPurchases' ? (
                                            <Grid item xs={12}>
                                                <div style={{ textAlign: 'center', padding: '10px', margin: '1rem' }}>
                                                    <CircularProgress style={{ margin: 'auto' }} />
                                                </div>
                                            </Grid>
                                        ) : null}
                                        {state.type === 'PurchasesLoaded'
                                            ? state.pending.map((purchase: Purchase) => (
                                                  <Grid item key={purchase.id}>
                                                      <CouponCard
                                                          coupon={purchase.coupon}
                                                          buttonText="Ver"
                                                          showExpireDate={true}
                                                          onClick={() => handlePurchaseClick(purchase)}
                                                      />
                                                  </Grid>
                                              ))
                                            : null}
                                    </Grid>
                                </RoundedContainer>
                            </div>
                            <div className={classes.stackItem}>
                                <RoundedContainer
                                    top={200}
                                    maxHeight={true}
                                    title={`${Language.strings.coupons_expired_coupons} ${
                                        state.type === 'PurchasesLoaded' ? '(' + state.expired.length + ')' : ''
                                    }`}
                                    color="grey"
                                    className={'rounded-container'}
                                >
                                    <Grid container spacing={2} className={classes.horizontalContainer}>
                                        {state.type === 'LoadingPurchases' ? (
                                            <Grid item xs={12}>
                                                <div style={{ textAlign: 'center', padding: '10px', margin: '1rem' }}>
                                                    <CircularProgress style={{ margin: 'auto' }} />
                                                </div>
                                            </Grid>
                                        ) : null}
                                        {state.type === 'PurchasesLoaded'
                                            ? state.expired.map((purchase: Purchase) => (
                                                  <Grid item key={purchase.id}>
                                                      <CouponCard
                                                          coupon={purchase.coupon}
                                                          showExpireDate={true}
                                                          showAction={false}
                                                      />
                                                  </Grid>
                                              ))
                                            : null}
                                    </Grid>
                                </RoundedContainer>
                            </div>
                            <div className={classes.stackItem}>
                                <RoundedContainer
                                    top={200}
                                    maxHeight={true}
                                    title={`${Language.strings.coupons_exchanged_coupons} ${
                                        state.type === 'PurchasesLoaded' ? '(' + state.exchanged.length + ')' : ''
                                    }`}
                                    color="white"
                                    className={'rounded-container'}
                                >
                                    <Grid container spacing={2} className={classes.horizontalContainer}>
                                        {state.type === 'LoadingPurchases' ? (
                                            <Grid item xs={12}>
                                                <div style={{ textAlign: 'center', padding: '10px', margin: '1rem' }}>
                                                    <CircularProgress style={{ margin: 'auto' }} />
                                                </div>
                                            </Grid>
                                        ) : null}
                                        {state.type === 'PurchasesLoaded'
                                            ? state.exchanged.map((purchase: Purchase) => (
                                                  <Grid item key={purchase.id}>
                                                      <CouponCard
                                                          coupon={purchase.coupon}
                                                          showExchangeDate={true}
                                                          exchangeDate={purchase.exchangeDate}
                                                          showAction={false}
                                                      />
                                                  </Grid>
                                              ))
                                            : null}
                                    </Grid>
                                </RoundedContainer>
                            </div>
                        </>
                    </FullScreenDialog>
                );
            }}
        />
    );
};
