import { IModel } from '../../../../core/domain/types/model_interface';

export type ResidueArgs = {
    id: number;
    name: string;
    image?: string;
    value: number;
    kind: string;
};

export class Residue implements IModel {
    type = 'ResidueModel';

    id: number;
    name: string;
    image?: string;
    value: number;
    kind: string;

    constructor({ id, name, image, value, kind }: ResidueArgs) {
        this.id = id;
        this.name = name;
        this.image = image;
        this.value = value;
        this.kind = kind;
    }
}
