import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { BlocBuilder } from '../../../../../../../common/bloc';
import { ValidationFailure } from '../../../../../../../core/domain/failures/validation_failure';
import { PasswordInput } from '../../../../../../../core/presentation/components/PasswordInput/password_input';
import { useRecoveryConfirmationBloc } from '../../../../../../../core/presentation/contexts/contexts';
import Language from '../../../../../../../core/presentation/strings/LanguageManager';
import { RecoveryConfirmationState } from '../../../../blocs/recovery_confirmation_bloc/recovery_confirmation_states';
import { useStyles } from './styles';

type URLParams = {
    token: string;
};

const RecoveryConfirmationForm = ({ history, location }: RouteComponentProps) => {
    const classes = useStyles();
    const bloc = useRecoveryConfirmationBloc();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        bloc.changePassword(event.target.value);
    };
    const handleSubmit = (e: any) => {
        bloc.submitConfirmation(new URLSearchParams(window.location.search).get('token') || '');
    };

    const handleBack = (e: any) => {
        history.push('/login');
    };
    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: RecoveryConfirmationState) => {
                const errors =
                    state.type === 'Failure' && state.failure instanceof ValidationFailure && state.failure.fails;
                console.log(state);
                return (
                    <Grid container spacing={2} justify="space-between">
                        {state.type !== 'ConfirmationSubmitted' ? (
                            <>
                                <Grid item>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {Language.strings.recovery_subtitle}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordInput
                                        name="password"
                                        value={state.password}
                                        onChange={handleChange}
                                        fullWidth={true}
                                        label={Language.strings.recovery_password}
                                        className={`${classes.input}`}
                                        error={errors && errors.password}
                                        helperText={Language.getErrorString('recovery', 'password', state)}
                                        disabled={false}
                                    />
                                </Grid>
                                {state.type === 'Failure' &&
                                state.failure instanceof ValidationFailure &&
                                state.failure.fails.token ? (
                                    <Grid item xs={12}>
                                        <Typography color="error">{Language.strings.recovery_token}</Typography>
                                    </Grid>
                                ) : null}
                                {state.type === 'Failure' && !(state.failure instanceof ValidationFailure) ? (
                                    <Grid item xs={12}>
                                        <Typography color="error">{Language.strings.unknown_failure}</Typography>
                                    </Grid>
                                ) : null}
                                <Grid item container justify="flex-end">
                                    <Grid item xs={12} className={classes.actionButton}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleBack}
                                            className={classes.backButton}
                                        >
                                            {Language.strings.back}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={state.type === 'SubmittingConfirmation'}
                                            onClick={handleSubmit}
                                        >
                                            {state.type === 'SubmittingConfirmation'
                                                ? Language.strings.submitting
                                                : Language.strings.submit}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <Typography color="primary" variant="h2">
                                        {`Hemos actualizado tu contraseña, por favor ingresá de nuevo a tu cuenta!`}
                                    </Typography>
                                </Grid>
                                <Grid item container justify="flex-end">
                                    <Grid item xs={12} className={classes.actionButton}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleBack}
                                            className={classes.backButton}
                                        >
                                            {Language.strings.login}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                );
            }}
        />
    );
};

export default withRouter(RecoveryConfirmationForm);
