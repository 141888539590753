import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const media = theme.breakpoints.up('sm');

    return createStyles({
        pageContainer: {
            padding: theme.spacing(2, 4),
        },
        [theme.breakpoints.up('sm')]: {
            pageContainer: {
                width: '550px',
                margin: '0 auto',
            },
        },
        formContainer: {
            marginTop: theme.spacing(2),
            height: 'calc(100vh - 200px)',
            overflowY: 'scroll',
            ['& -ms-overflow-style']: 'none',
            scrollbarWidth: 'none',
            ['&::-webkit-scrollbar']: { display: 'none' },
        },
    });
});
