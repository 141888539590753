import { Failure } from '../../../../../core/domain/failures/failure';
import { Ecofootprint } from '../../../domain/models/ecofootprint';
import { GetEcoFootprint } from '../../../domain/usecases/get_ecofootprint_usecase';
import { ModalBloc } from './../../../../../core/presentation/blocs/modal/modal_bloc';
import { EcoFootprintPageState, initialState } from './ecofootprint_page_states';

//TODO: This bloc is temporary and should be correclty implemented and tested
export class EcoFootprintPageBloc extends ModalBloc<EcoFootprintPageState> {
    usecase: GetEcoFootprint;
    constructor(usecase: GetEcoFootprint) {
        super(initialState);
        this.usecase = usecase;
    }
    async getEcoFootprint(): Promise<void> {
        const { open, block } = this.state;

        this.changeState({ open, block, type: 'LoadingEcoFootprint' });
        const result = await this.usecase.execute();
        if (result instanceof Failure) {
            this.changeState({ open, block, type: 'Failure', failure: result });
            return;
        }
        this.changeState({ open, block, type: 'EcoFootprintLoaded', ecofootprint: result as Ecofootprint[] });
    }
}
