import { Avatar, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { BlocBuilder } from '../../../../../common/bloc';
import { EcoFootprintPageState } from '../../blocs/EcoFootprintBloc/ecofootprint_page_states';
import { Ecofootprint } from '../../../domain/models/ecofootprint';
import FullScreenDialog from '../../../../../core/presentation/components/FullScreenDialog/fullscreen_dialog';
import LanguageManager from '../../../../../core/presentation/strings/LanguageManager';
import { Logout } from '../../../../../core/presentation/components/LogoutRedirect/logout_redirect';
import _ from 'lodash';
import carsImg from '../../../../../assets/images/cars.svg';
import energyImg from '../../../../../assets/images/energy.svg';
import treesImg from '../../../../../assets/images/trees.svg';
import { useBlocSuscription } from '../../../../../core/presentation/hooks/useBlocSuscription';
import { useEcoFootprintPageBloc } from '../../../../../core/presentation/contexts/contexts';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';
import waterImg from '../../../../../assets/images/water.svg';

const server_url = process.env.REACT_APP_ADMIN_SERVER_URL;

export const EcoFootprintPage: React.FC = () => {
    const ecoFootprintPageBloc = useEcoFootprintPageBloc();
    const classes = useStyles();
    const handleClose = () => {
        ecoFootprintPageBloc.close();
    };
    useEffect(() => {
        if (ecoFootprintPageBloc.state.open) ecoFootprintPageBloc.getEcoFootprint();
    }, []);
    const { enqueueSnackbar } = useSnackbar();

    useBlocSuscription<EcoFootprintPageState>({
        bloc: ecoFootprintPageBloc,
        action: (state) => {
            if (state.type === 'Failure') {
                enqueueSnackbar(LanguageManager.strings.unknown_failure, { variant: 'error' });
            }
        },
    });
    return (
        <>
            <BlocBuilder
                bloc={ecoFootprintPageBloc}
                builder={(state: EcoFootprintPageState) => {
                    return (
                        <FullScreenDialog
                            allowScroll={true}
                            open={state.open}
                            title="HUELLA VERDE"
                            onClose={handleClose}
                            waveTop={10}
                            className={classes.overflow}
                            disablePortal={true}
                        >
                            <EcoFootprintContent />
                        </FullScreenDialog>
                    );
                }}
            />
        </>
    );
};

const EcoFootprintContent = () => {
    const bloc = useEcoFootprintPageBloc();
    useEffect(() => {
        if (bloc.state.open) bloc.getEcoFootprint();
    }, []);
    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: EcoFootprintPageState) => {
                if (state.type === 'Failure' && state.failure instanceof AuthorizationFailure) return <Logout />;

                if (state.type === 'LoadingEcoFootprint')
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                <div style={{ textAlign: 'center', padding: '10px', margin: '1rem' }}>
                                    <CircularProgress style={{ margin: 'auto' }} />
                                </div>
                            </Grid>
                        </Grid>
                    );
                if (state.type === 'EcoFootprintLoaded') return <EcoFootprintTabs ecofootprints={state.ecofootprint} />;

                return <></>;
            }}
        />
    );
};

type EcoFootprintTabsProps = {
    ecofootprints: Ecofootprint[];
};
const EcoFootprintTabs: React.FC<EcoFootprintTabsProps> = ({ ecofootprints }: EcoFootprintTabsProps) => {
    const [current, setCurrent] = useState(0);
    const [previous, setPrevious] = useState<number>(-1);
    const classes = useStyles();
    return (
        <>
            <Grid container>
                {ecofootprints.map((ecofootprint, index) => {
                    return (
                        <>
                            <EcoFootprintButton
                                key={index}
                                active={index === current}
                                onClick={() => {
                                    setPrevious(current);
                                    setCurrent(index);
                                }}
                                ecofootprint={ecofootprint}
                            />
                        </>
                    );
                })}
            </Grid>
            <Grid container className={classes.cardContainer}>
                {ecofootprints.map((ecofootprint, index) => (
                    <EcoFootprintCard
                        ecofootprint={ecofootprint}
                        key={index}
                        className={index === current ? 'active' : index === previous ? 'out' : 'inactive'}
                    />
                ))}
            </Grid>
        </>
    );
};

type EcoFootprintButtonProps = {
    ecofootprint: Ecofootprint;
    active: boolean;
    onClick: () => void;
};
const EcoFootprintButton: React.FC<EcoFootprintButtonProps> = ({
    ecofootprint,
    active = false,
    onClick,
}: EcoFootprintButtonProps) => {
    const classes = useStyles();

    return (
        <div onClick={onClick} className={classes.button}>
            <Avatar
                variant="square"
                className={active ? 'active' : ''}
                style={{ borderRadius: '10px', marginRight: '.5rem' }}
            >
                {_.capitalize(ecofootprint.residueType.name[0])}
            </Avatar>

            <Grid container direction="column">
                <Grid item>
                    <Typography variant="caption" color="textSecondary">
                        {_.capitalize(ecofootprint.residueType.name)}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">{`${
                        ecofootprint.count || 0
                    } unid.`}</Typography>
                </Grid>
            </Grid>
        </div>
    );
};

type EcoFootprintCardProps = {
    ecofootprint: Ecofootprint;
    className?: string;
};
const EcoFootprintCard: React.FC<EcoFootprintCardProps> = ({ ecofootprint, className }: EcoFootprintCardProps) => {
    const classes = useStyles();
    return (
        <Paper className={classes.card + ' ' + className}>
            <Grid container alignItems="center">
                <Grid item className={classes.cardImg}>
                    <img src={`${server_url}${ecofootprint.residueType.image}`} />
                </Grid>
                <Grid item container direction="column" style={{ paddingLeft: '1rem' }}>
                    <Typography variant="h1" className={classes.cardTitle}>{`Huella Verde ${_.capitalize(
                        ecofootprint.residueType.name,
                    )}`}</Typography>
                    <Typography variant="caption" className={classes.cardCount}>{`${
                        ecofootprint.count || 0
                    } unidades`}</Typography>
                </Grid>
            </Grid>
            <Typography variant="subtitle1">
                Has recuperado
                <strong>{` ${ecofootprint.count || 0} residuos de ${_.capitalize(
                    ecofootprint.residueType.name,
                )}`}</strong>
            </Typography>
            <Typography variant="subtitle1">Esto generó un ahorro de:</Typography>
            <Grid container alignItems="center" className="separated" style={{ paddingRight: '3rem' }}>
                <Typography variant="subtitle1">
                    <strong>{`${ecofootprint.residueType.water * (ecofootprint.count || 0)} litros de agua.`}</strong>
                </Typography>
                <img style={{ marginLeft: 'auto' }} src={`${waterImg}`} />
            </Grid>
            <Grid container alignItems="center" className="separated" style={{ paddingRight: '3rem' }}>
                <Typography variant="subtitle1">
                    <strong>{`${ecofootprint.residueType.energy * (ecofootprint.count || 0)} kW/h`}</strong>
                </Typography>
                <img style={{ marginLeft: 'auto' }} src={`${energyImg}`} />
            </Grid>
            <span
                className={classes.separator}
                style={{
                    height: '35px',
                    backgroundImage: `url(${treesImg})`,
                }}
            />
            <Typography variant="subtitle1">Con esta recuperacion pudiste evitar la emisión de:</Typography>
            <Typography variant="subtitle1" className="tabed">
                <strong>
                    {`${(ecofootprint.residueType.co2 * (ecofootprint.count || 0)) / 1000} Kg de CO2 `}
                    <br />
                    (gas efecto invernadero)
                </strong>
            </Typography>
            <Typography variant="subtitle1">Equivale a:</Typography>
            <Typography variant="subtitle1" className="tabed">
                <strong>{`${
                    ecofootprint.residueType.km * (ecofootprint.count || 0)
                } Km de recorrido en un auto promedio`}</strong>
            </Typography>
            <span
                className={classes.separator}
                style={{
                    height: '16px',
                    backgroundImage: `url(${carsImg})`,
                }}
            />
        </Paper>
    );
};
