import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { WalletModel } from '../models/wallet_model';
import { IWalletRepository } from '../repositories/wallet_repository_interface';

export type GetConsumerWalletUseCaseParams = {
    repository: IWalletRepository;
};

export class GetConsumerWalletUseCase implements IUseCase<WalletModel, GetConsumerWalletUseCaseParams> {
    repository: IWalletRepository;

    constructor({ repository }: GetConsumerWalletUseCaseParams) {
        this.repository = repository;
    }

    execute(): Promise<WalletModel | IFailure> {
        return this.repository.getConsumerWallet();
    }
}
