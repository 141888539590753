import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { areAnyNil } from '../../../../core/data/utils/areAnyNil';
import { IEntity } from '../../../../core/domain/types/entity_interface';
import { Residue, ResidueArgs } from '../../domain/models/residue';

export class ResidueEntity extends Residue implements IEntity {
    constructor(params: ResidueArgs) {
        super(params);
    }
    toModel(): Residue {
        return new Residue(this);
    }

    static fromObject(obj: any): ResidueEntity {
        try {
            const { id, name, value, residue_type } = obj;
            if (areAnyNil([obj, id, name, value, residue_type, residue_type?.name])) {
                throw new Error();
            }
            return new ResidueEntity({
                id: obj.id,
                name: obj.name,
                image: obj.image,
                value: obj.value,
                kind: obj.residue_type.name,
            });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'ResiudeEntity');
        }
    }
}
