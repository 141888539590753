import { CacheError } from '../../../../core/data/errors/cache_error';
import { IAuthenticationCacheDataSource } from '../../../../core/data/types/authentication_cache_datasource_interface';
import { AuthorizationFailure } from '../../../../core/domain/failures/authorization_failure';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { WalletModel } from '../../domain/models/wallet_model';
import { IWalletRepository } from '../../domain/repositories/wallet_repository_interface';
import { IWalletDataSource } from '../datasources/types/wallet_datasource_interface';
import { errorToFailure } from './../../../../core/data/utils/error_to_failure';

export type WalletRepositoryParams = {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: IWalletDataSource;
};

export class WalletRepository implements IWalletRepository {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: IWalletDataSource;

    constructor({ authCacheDataSource, apiDataSource }: WalletRepositoryParams) {
        this.authCacheDataSource = authCacheDataSource;
        this.apiDataSource = apiDataSource;
    }

    async getConsumerWallet(): Promise<WalletModel | IFailure> {
        try {
            const isAuthenticated = await this.authCacheDataSource.isAuthenticated();
            if (!isAuthenticated) {
                const failure = new AuthorizationFailure();
                return Promise.resolve(failure);
            }
            const entity = await this.apiDataSource.getConsumerWallet();
            return Promise.resolve(entity.toModel());
        } catch (error) {
            if (error instanceof CacheError) {
                const failure = new AuthorizationFailure();
                return Promise.resolve(failure);
            }
            return Promise.resolve(errorToFailure<null>(error));
        }
    }
}
