import { Bloc } from '../../../../../common/bloc';
import { Failure } from '../../../../../core/domain/failures/failure';
import { AuthenticateConsumerUseCase } from '../../../domain/usecases/authenticate_consumer';
import { authenticationInitialState, AuthenticationState } from './authentication_states';

export type AuthenticationBlocArgs = {
    authenticationUseCase: AuthenticateConsumerUseCase;
};
type InputChangedParams = {
    property: string;
    value: string;
};

export class AuthenticationBloc extends Bloc<AuthenticationState> {
    authenticationUseCase: AuthenticateConsumerUseCase;

    constructor({ authenticationUseCase }: AuthenticationBlocArgs) {
        super(authenticationInitialState);
        this.authenticationUseCase = authenticationUseCase;
    }

    async authenticate(): Promise<void> {
        this.changeState({ ...this.state, type: 'SubmittingAuthentication' });
        const { username, password } = this.state;
        const result = await this.authenticationUseCase.execute({
            username,
            password,
        });
        if (result instanceof Failure) {
            this.changeState({ ...this.state, type: 'Failure', failure: result });
            return;
        }
        this.changeState({ ...this.state, type: 'AuthenticationSuccessful' });
        return Promise.resolve();
    }

    async changeInput(params: InputChangedParams): Promise<void> {
        this.changeState({ ...this.state, type: 'AuthenticationChanged', [params.property]: params.value });
    }
}
