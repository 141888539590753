import { Avatar, Button, Grid, Paper } from '@material-ui/core';
import {
    default as Language,
    default as LanguageManager,
} from '../../../../../core/presentation/strings/LanguageManager';
import React, { useState } from 'react';

import { AuthorizationFailure } from './../../../../../core/domain/failures/authorization_failure';
import BlocBuilder from '../../../../../common/bloc/BlocBuilder';
import { Edit } from '@material-ui/icons';
import FullScreenDialog from '../../../../../core/presentation/components/FullScreenDialog/fullscreen_dialog';
import { Logout } from '../../../../../core/presentation/components/LogoutRedirect/logout_redirect';
import MuiTextField from '../../../../../core/presentation/components/MUITextField/MuiTextField';
import { ProfilePageBloc } from '../../blocs/profile_page_bloc/profile_page_bloc';
import { ProfilePageState } from '../../blocs/profile_page_bloc/profile_page_state';
import { UploadButton } from '../../../../../core/presentation/components/UploadButton/upload_button';
import { ValidationFailure } from './../../../../../core/domain/failures/validation_failure';
import _ from 'lodash';
import { useBlocSuscription } from '../../../../../core/presentation/hooks/useBlocSuscription';
import { useHistory } from 'react-router-dom';
import { useProfilePageBloc } from '../../../../../core/presentation/contexts/contexts';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';

const server_url = process.env.REACT_APP_CONSUMER_SERVER_URL;

export const ProfilePage: React.FC = () => {
    const history = useHistory();
    const bloc: ProfilePageBloc = useProfilePageBloc();
    const [logout, setLogout] = useState(false);
    const classes = useStyles();
    const handleClose = () => {
        bloc.close();
    };

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'uploadedImage' && event.target.files && event.target.files[0] !== null) {
            bloc.changeInput({ input: event.target.name, value: event.target.files[0] });
            return;
        }
        bloc.changeInput({ input: event.target.name, value: event.target.value });
    };
    const handleSubmit = () => {
        bloc.submitProfile();
    };
    const handleLogout = () => {
        setLogout(true);
    };
    const handleChangePassword = () => {
        history.push('/password/request');
    };

    const { enqueueSnackbar } = useSnackbar();

    useBlocSuscription<ProfilePageState>({
        bloc,
        action: (state) => {
            if (
                state.type === 'Failure' &&
                state.failure instanceof ValidationFailure &&
                state.failure.fails['image']
            ) {
                enqueueSnackbar(Language.getErrorString('profile', 'image', state), { variant: 'error' });
            }
            if (state.type === 'Failure' && !(state.failure instanceof ValidationFailure)) {
                enqueueSnackbar(LanguageManager.strings.unknown_failure, { variant: 'error' });
            }
            if (state.type === 'ProfileSubmitted') {
                enqueueSnackbar(Language.strings.profile_saved, { variant: 'success' });
            }
        },
    });

    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: ProfilePageState) => {
                if (state.type === 'Failure' && state.failure instanceof AuthorizationFailure) return <Logout />;

                const open = state.type !== 'PageClosed';

                let content;
                if (state.type !== 'PageClosed') {
                    const errors =
                        state.type === 'Failure' && state.failure instanceof ValidationFailure
                            ? state.failure.fails
                            : undefined;
                    let avatar = ``;
                    if (state.image) {
                        avatar = `${server_url}${state.image}`;
                        if (state.uploadedImage) avatar = state.image;
                    }

                    content = (
                        <>
                            <Paper className={classes.paper}>
                                <Grid item container direction="column" alignItems="center" spacing={2}>
                                    <Avatar src={avatar} className={classes.avatar} />
                                    <UploadButton
                                        className={classes.editButton}
                                        icon={<Edit />}
                                        name={'uploadedImage'}
                                        onChange={handleInputChanged}
                                    />
                                    <Grid container item className={`${classes.space} ${classes.center}`}>
                                        <MuiTextField
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            fullWidth
                                            value={state.surname}
                                            label={Language.strings.profile_surname}
                                            onChange={handleInputChanged}
                                            name="surname"
                                            error={!_.isNil(errors) && !_.isNil(errors.surname)}
                                            helperText={Language.getErrorString('profile', 'surname', state)}
                                        />
                                    </Grid>
                                    <Grid container item className={`${classes.center}`}>
                                        <MuiTextField
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            fullWidth
                                            value={state.name}
                                            label={Language.strings.profile_name}
                                            onChange={handleInputChanged}
                                            name="name"
                                            error={!_.isNil(errors) && !_.isNil(errors.name)}
                                            helperText={Language.getErrorString('profile', 'name', state)}
                                        />
                                    </Grid>
                                    <Grid container item className={`${classes.center}`}>
                                        <MuiTextField
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            fullWidth
                                            value={state.identification_number}
                                            label={Language.strings.profile_identification_number}
                                            type="number"
                                            onChange={handleInputChanged}
                                            name="identification_number"
                                            error={!_.isNil(errors) && !_.isNil(errors.identification_number)}
                                            helperText={Language.getErrorString(
                                                'profile',
                                                'identification_number',
                                                state,
                                            )}
                                        />
                                    </Grid>
                                    <Grid container item className={`${classes.center}`}>
                                        <MuiTextField
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            fullWidth
                                            type="email"
                                            value={state.email}
                                            label={Language.strings.profile_email}
                                            onChange={handleInputChanged}
                                            name="email"
                                            error={!_.isNil(errors) && !_.isNil(errors.email)}
                                            helperText={Language.getErrorString('profile', 'email', state)}
                                        />
                                    </Grid>
                                    <Grid container item className={`${classes.right}`}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ marginLeft: 'auto' }}
                                            onClick={handleSubmit}
                                            disabled={state.type !== 'InputChanged' && state.type !== 'Failure'}
                                        >
                                            {state.type === 'SubmittingProfile'
                                                ? Language.strings.profile_saving
                                                : Language.strings.profile_save}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper className={classes.paper2}>
                                <Grid container item className={`${classes.center}`}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        color="secondary"
                                        style={{ margin: '0 1rem', marginBottom: '1rem' }}
                                        onClick={handleChangePassword}
                                    >
                                        {Language.strings.profile_change_password}
                                    </Button>
                                </Grid>
                                <Grid container item className={`${classes.center}`}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        color="secondary"
                                        style={{ margin: '0 1rem', marginBottom: '1rem' }}
                                        onClick={handleLogout}
                                    >
                                        {Language.strings.profile_logout}
                                    </Button>
                                    {logout ? <Logout /> : null}
                                </Grid>
                            </Paper>
                        </>
                    );
                }
                return (
                    <FullScreenDialog
                        open={open}
                        title="MI PERFIL"
                        onClose={handleClose}
                        className={classes.background}
                        allowScroll={true}
                    >
                        <>{content}</>
                    </FullScreenDialog>
                );
            }}
        />
    );
};
