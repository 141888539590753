import { Button, Checkbox, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import { BlocBuilder } from '../../../../common/bloc';
import Language from '../../../../core/presentation/strings/LanguageManager';
import { Link } from 'react-router-dom';
import MuiTextField from '../../../../core/presentation/components/MUITextField/MuiTextField';
import { PasswordInput } from '../../../../core/presentation/components/PasswordInput/password_input';
import { RegistrationState } from '../blocs/registration_bloc/registration_states';
import SuccessMessage from './success_message';
import { ValidationFailure } from '../../../../core/domain/failures/validation_failure';
import { useRegistrationBloc } from '../pages/RegisterPage/register_page';
import { useStyles } from './styles';

export const RegistrationForm: React.FC = () => {
    const classes = useStyles();
    const bloc = useRegistrationBloc();
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        bloc.changeInput({ property: event.target.name, value: event.target.value });
    };
    const handleSubmit = (e: any) => {
        bloc.register();
    };

    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: RegistrationState) => {
                if (state.type === 'RegistrationSuccessful') return <SuccessMessage />;
                if (
                    state.type === 'RegistrationDefaultState' ||
                    state.type === 'FillingRegistration' ||
                    state.type === 'SubmittingRegistration' ||
                    state.type === 'Failure'
                )
                    return (
                        <Grid container spacing={2} justify="space-between">
                            <Grid item xs={12}>
                                <MuiTextField
                                    value={state.surname}
                                    onChange={handleChange}
                                    name="surname"
                                    type="text"
                                    label={Language.strings.registration_surname}
                                    variant="filled"
                                    fullWidth={true}
                                    className={`${classes.input}`}
                                    disabled={state.type === 'SubmittingRegistration'}
                                    error={
                                        state.type === 'Failure' &&
                                        state.failure instanceof ValidationFailure &&
                                        state.failure.fails.surname !== undefined
                                    }
                                    helperText={Language.getErrorString('registration', 'surname', state)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MuiTextField
                                    name="name"
                                    value={state.name}
                                    onChange={handleChange}
                                    type="text"
                                    label={Language.strings.registration_name}
                                    variant="filled"
                                    fullWidth={true}
                                    className={`${classes.input}`}
                                    disabled={state.type === 'SubmittingRegistration'}
                                    error={
                                        state.type === 'Failure' &&
                                        state.failure instanceof ValidationFailure &&
                                        state.failure.fails.name !== undefined
                                    }
                                    helperText={Language.getErrorString('registration', 'name', state)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MuiTextField
                                    name="identification_number"
                                    value={state.identification_number || ''}
                                    onChange={handleChange}
                                    type="number"
                                    label={Language.strings.registration_identification_number}
                                    variant="filled"
                                    fullWidth={true}
                                    className={`${classes.input}`}
                                    disabled={state.type === 'SubmittingRegistration'}
                                    error={
                                        state.type === 'Failure' &&
                                        state.failure instanceof ValidationFailure &&
                                        state.failure.fails.identification_number !== undefined
                                    }
                                    helperText={Language.getErrorString('registration', 'identification_number', state)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MuiTextField
                                    name="email"
                                    value={state.email}
                                    onChange={handleChange}
                                    type="email"
                                    label={Language.strings.registration_email}
                                    variant="filled"
                                    fullWidth={true}
                                    className={`${classes.input}`}
                                    disabled={state.type === 'SubmittingRegistration'}
                                    error={
                                        state.type === 'Failure' &&
                                        state.failure instanceof ValidationFailure &&
                                        state.failure.fails.email !== undefined
                                    }
                                    helperText={Language.getErrorString('registration', 'email', state)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MuiTextField
                                    name="username"
                                    value={state.username}
                                    onChange={handleChange}
                                    type="text"
                                    label={Language.strings.registration_username}
                                    variant="filled"
                                    fullWidth={true}
                                    className={`${classes.input}`}
                                    disabled={state.type === 'SubmittingRegistration'}
                                    error={
                                        state.type === 'Failure' &&
                                        state.failure instanceof ValidationFailure &&
                                        state.failure.fails.username !== undefined
                                    }
                                    helperText={Language.getErrorString('registration', 'username', state)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordInput
                                    name="password"
                                    value={state.password}
                                    onChange={handleChange}
                                    className={`${classes.input}`}
                                    fullWidth={true}
                                    disabled={state.type === 'SubmittingRegistration'}
                                    label={Language.strings.registration_password}
                                    error={
                                        state.type === 'Failure' &&
                                        state.failure instanceof ValidationFailure &&
                                        state.failure.fails.password !== undefined
                                    }
                                    helperText={Language.getErrorString('registration', 'password', state)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Checkbox
                                        className={classes.checkbox}
                                        color="default"
                                        checked={acceptedTerms}
                                        onChange={() => setAcceptedTerms(!acceptedTerms)}
                                    />
                                    <Typography color="secondary">
                                        Acepto los{' '}
                                        <Link
                                            color="primary"
                                            to={{ pathname: 'https://ecobuy.com.ar/legales' }}
                                            target="_blank"
                                        >
                                            Términos y Condiciones de Uso.
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container justify="flex-end">
                                {state.type === 'Failure' && !(state.failure instanceof ValidationFailure) ? (
                                    <Grid item>
                                        <Typography color="error">{Language.strings.unknown_failure}</Typography>
                                    </Grid>
                                ) : null}
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={state.type === 'SubmittingRegistration' || !acceptedTerms}
                                        onClick={handleSubmit}
                                    >
                                        {state.type === 'SubmittingRegistration' ? 'Enviando...' : 'Enviar REGISTRO'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                else return <></>;
            }}
        />
    );
};
