import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface PaperProps {
    backgroundColor: string | undefined;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: (props: PaperProps) => ({
            position: 'relative',
            padding: '11px',
            borderRadius: '20px',
            backgroundColor: props.backgroundColor || '#f2c6a1bf',
            //backdropFilter: 'blur(3px)',
        }),
        card_title: {
            fontWeight: 'bold',
        },
        balance: {
            fontWeight: 'bold',
            fontSize: '2rem',
        },
        helper_text: {
            fontSize: '0.8rem',
        },
        button: {
            textAlign: 'right',
            padding: '4px 0',
            ['& .MuiButton-root']: {
                lineHeight: '.9rem',
                maxWidth: '160px',
                minHeight: '40px',
                ['& .icon']: {
                    marginRight: '5px',
                },
            },
        },
        separator: {
            marginBottom: '1rem',
        },
    }),
);
