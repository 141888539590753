import { Bloc } from '../../../common/bloc';
import { GetAuthenticatedConsumerUseCase } from '../../../features/authentication/domain/usecases/get_authenticated_consumer';
import { Consumer } from '../../domain/models/consumer_model';
import { athorizedRouteDefaultState, AuthorizedRouteState } from './authorized_route_bloc_states';

type AuthorizationBlocProps = {
    usecase: GetAuthenticatedConsumerUseCase;
};

export class AuthorizedRouteBloc extends Bloc<AuthorizedRouteState> {
    usecase: GetAuthenticatedConsumerUseCase;
    constructor({ usecase }: AuthorizationBlocProps) {
        super(athorizedRouteDefaultState);
        this.usecase = usecase;
    }

    async getAuthenticated(): Promise<void> {
        this.changeState({ type: 'LoadingAuthorizedConsumer' });
        const result = await this.usecase.execute();
        if (result instanceof Consumer) {
            this.changeState({ type: 'AuthorizedConsumerLoaded', consumer: result });
            return;
        }
        this.changeState({ type: 'AuthorizedConsumerFailed' });
        return;
    }
}
