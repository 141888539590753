import { IModel } from '../../../../core/domain/types/model_interface';

export type ResidueTypeArgs = {
    name: string;
    kg: number;
    water: number;
    energy: number;
    co2: number;
    km: number;
    description: string;
    image: string;
};
export class ResidueType implements IModel {
    type = 'ResidueTypeModel';
    name: string;
    kg: number;
    water: number;
    energy: number;
    co2: number;
    km: number;
    description: string;
    image: string;

    constructor({ name, kg, water, energy, co2, km, description, image }: ResidueTypeArgs) {
        this.name = name;
        this.water = water;
        this.energy = energy;
        this.co2 = co2;
        this.kg = kg;
        this.km = km;
        this.description = description;
        this.image = image;
    }
}
