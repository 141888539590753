import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        dialog: (props: any) => ({
            zIndex: props.zIndex || 'inherit',
        }),
        appBar: {
            //position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
            lineHeight: '1.3rem',
        },
        [theme.breakpoints.up('sm')]: {
            pageContainer: {
                padding: theme.spacing(0, 2),
                width: '534px',
                margin: '0 auto',
            },
        },
    });
});
