import { Failure } from '../../../../../core/domain/failures/failure';

export const authenticationInitialState: AuthenticationState = {
    type: 'DefaultAuthentication',
    username: '',
    password: '',
};

export type AuthenticationState = (
    | ISubmittingAuthentication
    | IFailure
    | IAuthenticationChanged
    | IDefaultAuthentication
    | IAuthenticationSuccessful
) &
    ICommonAuthentication;

export interface ICommonAuthentication {
    username: string;
    password: string;
}
export interface IAuthenticationSuccessful {
    type: 'AuthenticationSuccessful';
}

export interface ISubmittingAuthentication {
    type: 'SubmittingAuthentication';
}
export interface IFailure {
    type: 'Failure';
    failure: Failure;
}

export interface IAuthenticationChanged {
    type: 'AuthenticationChanged';
}
export interface IDefaultAuthentication {
    type: 'DefaultAuthentication';
}
