import { Bloc } from '../../../../../common/bloc';
import { Failure } from '../../../../../core/domain/failures/failure';
import { Coupon } from '../../../domain/models/coupon';
import { Purchase } from '../../../domain/models/purchase';
import { PurchaseCouponUseCase } from '../../../domain/usecases/purchase_coupon_use_case';
import { initialState, PurchaseState } from './purchase_states';

export class PurchaseBloc extends Bloc<PurchaseState> {
    purchaseCouponUseCase: PurchaseCouponUseCase;
    constructor(purchaseCouponUseCase: PurchaseCouponUseCase) {
        super(initialState);
        this.purchaseCouponUseCase = purchaseCouponUseCase;
    }

    selectCoupon = async (coupon: Coupon): Promise<void> => {
        this.changeState({ type: 'PurchaseStarted', coupon });
    };

    confirmPurchase = async (): Promise<void> => {
        const currentState = this.state;
        if (currentState.type === 'PurchaseStarted' || currentState.type === 'Failure') {
            this.changeState({ type: 'SubmittingPurchase', coupon: currentState.coupon });
            const result = await this.purchaseCouponUseCase.execute({ coupon: currentState.coupon });
            if (result instanceof Failure) {
                this.changeState({ type: 'Failure', failure: result, coupon: currentState.coupon });
                return;
            }
            this.changeState({
                type: 'PurchaseCompleted',
                coupon: (result as Purchase).coupon,
                purchase: result as Purchase,
            });
            return;
        }
        this.changeState({ type: 'EmptyPurchase' });
    };

    displayPurchase = async (purchase: Purchase): Promise<void> => {
        this.changeState({ type: 'PurchaseCompleted', coupon: purchase.coupon, purchase });
    };

    resetPurchase = async (): Promise<void> => {
        this.changeState({ type: 'EmptyPurchase' });
    };
}
