import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        background: {
            ['& .MuiDialog-paper']: {
                backgroundColor: 'antiquewhite',
            },
        },
        paper: {
            padding: `0px 16px 16px 16px`,
            backgroundColor: theme.colors.whiteTransparent,
            bottom: 0,
            borderRadius: '15px',
            position: 'relative',
            margin: '7rem 1rem 1rem 1rem',
            ['& .MuiFormLabel-root:not(.Mui-focused)']: {
                color: theme.palette.secondary.main,
            },
        },
        paper2: {
            padding: '24px 8px 8px 8px',
            margin: '1rem',
            backgroundColor: theme.colors.whiteTransparent,
            bottom: 0,
            borderRadius: '15px',
            position: 'relative',
        },
        editButton: {
            position: 'absolute',
            background: 'white',
            color: '#0c3a2d',
            boxShadow: '0px 5px 5px #00000042',
            left: '55%',
            top: '1rem',
            ['&:hover']: {
                backgroundColor: 'white',
            },
        },
        avatar: {
            width: '130px',
            height: '130px',
            position: 'absolute',
            top: '-65px',
            margin: '0 auto',
        },
        space: {
            marginTop: '80px',
        },
        center: { textAlign: 'center' },
        right: { textAlign: 'right' },
    });
});
