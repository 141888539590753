import { Bloc } from '../../../../../common/bloc';
import { Failure } from '../../../../../core/domain/failures/failure';
import { WalletModel } from '../../../domain/models/wallet_model';
import { GetConsumerWalletUseCase } from '../../../domain/usecases/get_consumer_wallet_usecase';
import { walletInitialState, WalletState } from './wallet_states';

type WalletBlocParams = {
    getConsumerWalletUseCase: GetConsumerWalletUseCase;
};

export class WalletBloc extends Bloc<WalletState> {
    getConsumerWalletUseCase: GetConsumerWalletUseCase;
    constructor({ getConsumerWalletUseCase }: WalletBlocParams) {
        super(walletInitialState);
        this.getConsumerWalletUseCase = getConsumerWalletUseCase;
    }

    async getConsumerWallet(): Promise<void> {
        this.changeState({ type: 'LoadingWallet' });
        const result = await this.getConsumerWalletUseCase.execute();
        if (result instanceof Failure) {
            this.changeState({ type: 'Failure', failure: result });
            return;
        }
        this.changeState({ type: 'WalletLoaded', wallet: result as WalletModel });
        return Promise.resolve();
    }
}
