import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { Ecofootprint } from '../models/ecofootprint';
import { IEcoFootprintRepository } from '../repositories/ecofootprint_repository_interface';

export class GetEcoFootprint implements IUseCase<Ecofootprint[], void> {
    repository: IEcoFootprintRepository;

    constructor(repository: IEcoFootprintRepository) {
        this.repository = repository;
    }

    execute(): Promise<Ecofootprint[] | IFailure> {
        return this.repository.getEcoFootprint();
    }
}
