import { CircularProgress, FormHelperText, Grid, IconButton, Typography } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { useCollectionPageBloc, useResiduesPageBloc } from '../../../../../core/presentation/contexts/contexts';

import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { BlocBuilder } from '../../../../../common/bloc';
import FullScreenDialog from '../../../../../core/presentation/components/FullScreenDialog/fullscreen_dialog';
import InfiniteScroll from 'react-infinite-scroller';
import LanguageManager from '../../../../../core/presentation/strings/LanguageManager';
import { Logout } from '../../../../../core/presentation/components/LogoutRedirect/logout_redirect';
import MuiTextField from '../../../../../core/presentation/components/MUITextField/MuiTextField';
import { Residue } from '../../../domain/models/residue';
import { ResidueCard } from '../../components/ResidueCard/residue_card';
import { ResidueType } from '../../../../residue_types/domain/models/residue_type';
import { ResiduesPageState } from '../../blocs/ResiduesPageBloc/residues_page_states';
import { RoundedContainer } from '../../../../../core/presentation/components/RoundedContainer/rounded_container';
import { SearchOutlined } from '@material-ui/icons';
import { useBlocSuscription } from '../../../../../core/presentation/hooks/useBlocSuscription';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';

const server_url = process.env.REACT_APP_ADMIN_SERVER_URL;

type ResiduesPageProps = {
    onClose?: () => void;
};

export const ResiduesPage: React.FC<ResiduesPageProps> = ({
    onClose = () => {
        return;
    },
}: ResiduesPageProps) => {
    const bloc = useResiduesPageBloc();
    const collectionPageBloc = useCollectionPageBloc();
    const classes = useStyles();
    const handleClose = () => {
        bloc.close();
        onClose();
    };

    const handleQueryChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        bloc.changeQuery(event.target.value);
    };

    const handleFetchMore = () => {
        bloc.getResidues();
    };

    const handleResidueSelected = (residue: Residue) => {
        collectionPageBloc.selectResidue(residue);
        collectionPageBloc.open();
    };
    const handleResetAndFetch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        bloc.reset().then(() => {
            handleFetchMore();
        });
    };

    useEffect(() => {
        const requestResidueTypes = (state: ResiduesPageState, prevState: ResiduesPageState) => {
            if (state.open && !prevState.open) bloc.getResiduesTypes();
        };
        bloc.subscribe(requestResidueTypes);
        return () => {
            bloc.unsubscribe(requestResidueTypes);
        };
    }, []);
    const { enqueueSnackbar } = useSnackbar();

    useBlocSuscription<ResiduesPageState>({
        bloc,
        action: (state) => {
            if (state.type === 'Failure') {
                enqueueSnackbar(LanguageManager.strings.unknown_failure, { variant: 'error' });
            }
        },
    });

    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: ResiduesPageState) => {
                if (state.type === 'Failure' && state.failure instanceof AuthorizationFailure) return <Logout />;

                const displayResults = !state.showInfo && state.type !== 'Failure';
                return (
                    <FullScreenDialog
                        open={state.open}
                        title={LanguageManager.strings.residue_title}
                        onClose={handleClose}
                    >
                        <>
                            <Grid item xs={12} className={classes.searchBoxContainer}>
                                <form onSubmit={handleResetAndFetch}>
                                    <MuiTextField
                                        autoFocus
                                        className={classes.searchBox}
                                        variant="outlined"
                                        size="small"
                                        placeholder={LanguageManager.strings.residues_search}
                                        fullWidth
                                        value={state.query || ''}
                                        onChange={handleQueryChanged}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton type="submit">
                                                    <SearchOutlined color="secondary" />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </form>
                            </Grid>

                            <RoundedContainer
                                top={160}
                                maxHeight={true}
                                title={
                                    !displayResults
                                        ? LanguageManager.strings.residue_subtitle_reminder
                                        : LanguageManager.strings.residue_subtitle_browser
                                }
                                color="grey-transparent"
                            >
                                <>
                                    {state.type === 'ReloadingResidues' ? (
                                        <Grid item xs={12}>
                                            <FormHelperText style={{ textAlign: 'center', marginTop: '1rem' }}>
                                                <CircularProgress color="primary" />
                                            </FormHelperText>
                                        </Grid>
                                    ) : (
                                        <Fragment>
                                            {displayResults && state.residues.length > 0 ? (
                                                <InfiniteScroll
                                                    pageStart={0}
                                                    threshold={500}
                                                    loadMore={handleFetchMore}
                                                    hasMore={state.hasMore}
                                                    useWindow={false}
                                                >
                                                    <Grid container spacing={2}>
                                                        {state.residues?.map((residue) => {
                                                            return (
                                                                <Grid item xs={6} key={residue.id}>
                                                                    <ResidueCard
                                                                        residue={residue}
                                                                        onClick={() => {
                                                                            handleResidueSelected(residue);
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </InfiniteScroll>
                                            ) : null}

                                            {(displayResults && state.residues.length === 0) ||
                                            state.type === 'Failure' ? (
                                                <Grid container>
                                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                        <Typography variant="subtitle1">
                                                            {LanguageManager.strings.residue_no_results}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            ) : null}

                                            {state.type === 'LoadingResiduesType' ? (
                                                <Grid container>
                                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                        <CircularProgress color="primary" />
                                                    </Grid>
                                                </Grid>
                                            ) : null}

                                            {!displayResults ? <Info residueTypes={state.residueTypes} /> : null}
                                        </Fragment>
                                    )}
                                </>
                            </RoundedContainer>
                        </>
                    </FullScreenDialog>
                );
            }}
        ></BlocBuilder>
    );
};

type InfoProps = {
    residueTypes: ResidueType[];
};
const Info = ({ residueTypes }: InfoProps) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2} className={classes.infoContainer}>
            {residueTypes.map((residueType, index) => {
                let descriptions;
                try {
                    descriptions = JSON.parse(residueType.description);
                } catch (error) {
                    descriptions = undefined;
                }
                return (
                    <Fragment key={index}>
                        <span
                            style={{
                                marginTop: '.5rem',
                                paddingLeft: '.5rem',
                                color: '#0C3A2D',
                                fontWeight: 700,
                            }}
                        >
                            {residueType.name}
                        </span>
                        <Grid item container xs={12}>
                            <Grid item xs={3} className={classes.imgContainer}>
                                <img src={`${server_url}${residueType.image}`} />
                            </Grid>
                            <Grid item xs={9} className={classes.listContainer}>
                                <ul>
                                    {descriptions?.good?.split('\n').map((item: string, i: number) => (
                                        <li key={i}>{item}</li>
                                    ))}
                                    {descriptions?.bad?.split('\n').map((item: string, i: number) => (
                                        <li className="no" key={i}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </Grid>
                        </Grid>
                    </Fragment>
                );
            })}
        </Grid>
    );
};
