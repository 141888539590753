import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        map: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            borderRadius: '15px 15px 0px 0px',
        },
    });
});
