import { Consumer } from '../../../../core/domain/models/consumer_model';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { IAuthenticationRepository } from '../repositories/IAuthenticationRepository';

export class AuthenticateConsumerUseCase implements IUseCase<Consumer, AuthenticationParams> {
    authRepository: IAuthenticationRepository;

    constructor(authRepository: IAuthenticationRepository) {
        this.authRepository = authRepository;
    }
    async execute({ username, password }: AuthenticationParams): Promise<Consumer | IFailure> {
        const result = await this.authRepository.authenticate({ username, password });
        return Promise.resolve(result);
    }
}

export type AuthenticationParams = {
    username: string;
    password: string;
};
