import { AuthorizationError } from '../../../../core/data/errors/authorization_error';
import { ValidationError } from '../../../../core/data/errors/validation_error';
import { IAuthenticationCacheDataSource } from '../../../../core/data/types/authentication_cache_datasource_interface';
import { IAuthenticationDataSource } from '../../../../core/data/types/authentication_datasource_interface';
import { IAuthenticationResponse } from '../../../../core/data/types/authentication_response_interface';
import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { AuthorizationFailure } from '../../../../core/domain/failures/authorization_failure';
import { Consumer } from '../../../../core/domain/models/consumer_model';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IAuthenticationRepository } from '../../domain/repositories/IAuthenticationRepository';
import { AuthenticationParams } from '../../domain/usecases/authenticate_consumer';
import { RecoverPasswordParams } from '../../domain/usecases/recover_password';
import { RegistrationParams } from '../../domain/usecases/register_consumer';
import { UpdateProfileUseCaseParams } from '../../domain/usecases/update_profile';
import { LoginValidations } from '../validations/authenticate_validations';
import { RegisterValidations } from '../validations/register_validations';
import { RecoverPasswordValidations } from './../validations/recover_password_validations';
import { RequestRecoveryValidations } from './../validations/request_recovery_validations';
import { UpdateProfileValidations } from './../validations/update_profile_validations';

type AuthenticationRepositoryArgs = {
    apiDataSource: IAuthenticationDataSource;
    cacheDataSource: IAuthenticationCacheDataSource;
};

export class AuthenticationRepository implements IAuthenticationRepository {
    apiDataSource: IAuthenticationDataSource;
    cacheDataSource: IAuthenticationCacheDataSource;

    constructor({ apiDataSource, cacheDataSource }: AuthenticationRepositoryArgs) {
        this.apiDataSource = apiDataSource;
        this.cacheDataSource = cacheDataSource;
    }

    async register(params: RegistrationParams): Promise<boolean | IFailure> {
        try {
            const response = await this.apiDataSource.register(params);
            return Promise.resolve(response);
        } catch (error) {
            return Promise.resolve(errorToFailure<RegisterValidations>(error));
        }
    }

    async authenticate(params: AuthenticationParams): Promise<Consumer | IFailure> {
        try {
            const result: IAuthenticationResponse = await this.apiDataSource.authenticate(params);
            await this.cacheDataSource.cacheConsumer(result.user);
            await this.cacheDataSource.cacheToken(result.token);
            return result.user.toModel() as Consumer;
        } catch (error) {
            if (error instanceof AuthorizationError) return Promise.resolve(new AuthorizationFailure(error.message));
            if (error instanceof ValidationError && error.fails['login'])
                return Promise.resolve(new AuthorizationFailure(error.message)); // this is because api responds with 400
            return Promise.resolve(errorToFailure<LoginValidations>(error));
        }
    }

    async getAuthenticated(): Promise<Consumer | IFailure> {
        try {
            const consumerEntity = await this.cacheDataSource.getConsumer();
            const consumer = consumerEntity.toModel() as Consumer;
            return Promise.resolve(consumer);
        } catch (error) {
            return Promise.resolve(errorToFailure<null>(error));
        }
    }

    async requestRecovery(email: string): Promise<void | IFailure> {
        try {
            return await this.apiDataSource.requestRecovery(email);
        } catch (error) {
            return Promise.resolve(errorToFailure<RequestRecoveryValidations>(error));
        }
    }

    async recoverPassword({ token, password }: RecoverPasswordParams): Promise<void | IFailure> {
        try {
            return await this.apiDataSource.recoverPassword({ token, password });
        } catch (error) {
            return Promise.resolve(errorToFailure<RecoverPasswordValidations>(error));
        }
    }

    async updateProfile({
        image,
        name,
        surname,
        email,
        identification_number,
    }: UpdateProfileUseCaseParams): Promise<Consumer | IFailure> {
        const isAuthenticated = await this.cacheDataSource.isAuthenticated();
        if (!isAuthenticated) {
            return Promise.resolve(new AuthorizationFailure('User not authenticated'));
        }
        try {
            const consumerEntity = await this.apiDataSource.updateProfile({
                image,
                name,
                surname,
                email,
                identification_number,
            });
            this.cacheDataSource.cacheConsumer(consumerEntity);
            return consumerEntity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure<UpdateProfileValidations>(error));
        }
    }
}
