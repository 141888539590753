import { FilledInput, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { ChangeEvent, useState } from 'react';

type PasswordInputProps = {
    autofocus?: boolean;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    className: string;
    fullWidth: boolean;
    error: boolean;
    helperText: string;
    disabled: boolean;
    name: string;
    label: string;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
    value,
    onChange,
    className,
    fullWidth,
    error,
    helperText,
    disabled,
    name,
    label,
}: PasswordInputProps) => {
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(!show);
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl error={error} variant="filled" className={className} fullWidth={fullWidth} disabled={disabled}>
            <InputLabel htmlFor="filled-adornment-password">{label}</InputLabel>
            <FilledInput
                name={name}
                id="filled-adornment-password"
                type={show ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShow}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {show ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText id="component-helper-text">{helperText}</FormHelperText>
        </FormControl>
    );
};
