import { Button, Grid, Paper, Typography } from '@material-ui/core';

import LanguageManager from '../../../../../core/presentation/strings/LanguageManager';
import React from 'react';
import { Residue } from '../../../domain/models/residue';
import residueImg from '../../../../../assets/images/residue.svg';
import { useStyles } from './styles';

const server_path = process.env.REACT_APP_ADMIN_SERVER_URL;

type ResidueCardProps = {
    residue: Residue;
    buttonText?: string;
    onClick?(event: React.MouseEvent): void;
};

export const ResidueCard: React.FC<ResidueCardProps> = ({
    residue,
    onClick,
    buttonText = LanguageManager.strings.select,
}: ResidueCardProps) => {
    const classes = useStyles();
    return (
        <Paper elevation={3} className={classes.paper}>
            <div className={classes.image_viewport}>
                <img
                    className={classes.image}
                    src={residue.image ? `${server_path}${residue.image}` : `${residueImg}`}
                />
            </div>
            <Grid container className={classes.info}>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.title}>
                        {residue.name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.price}>
                        {`${residue.value} pts.`}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.title}>
                        {LanguageManager.strings.residue_type}: {residue.kind}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Button color="primary" variant="contained" onClick={onClick}>
                        {buttonText}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};
