import { IModel } from '../../../../core/domain/types/model_interface';

export type WalletModelParams = {
    pending_balance: number;
    effective_balance: number;
};

export class WalletModel implements IModel {
    type = 'WalletModel';
    pending_balance: number;
    effective_balance: number;

    constructor({ pending_balance, effective_balance }: WalletModelParams) {
        this.pending_balance = pending_balance;
        this.effective_balance = effective_balance;
    }
}
