import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const media = theme.breakpoints.up('sm');

    return createStyles({
        count: {
            padding: '0 1rem',
            marginTop: '1rem',
            marginBottom: '1rem',
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
                padding: '0',
            },
        },
        button: {
            maxWidth: '250px',
            margin: '1rem auto',
        },
        imgContainer: { ['& img']: { width: '100%', maxWidth: '120px' } },
    });
});
