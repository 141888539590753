import { Grid, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { createContext } from '../../../../../common/bloc/Context';
import di from '../../../../../common/di/di';
import { SimpleHeader } from '../../../../../core/presentation/components/SimpleHeader/simple_header';
import { WaveBackground } from '../../../../../core/presentation/components/wave_background';
import Language from '../../../../../core/presentation/strings/LanguageManager';
import { AuthenticationBloc } from '../../blocs/authentication_bloc/authentication_bloc';
import { LoginForm } from '../../components/login_form';
import { useStyles } from './styles';

export const [authContext, useAuthBloc] = createContext<AuthenticationBloc>();

const LoginPage: React.FC = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <WaveBackground top={28} />
            <Grid container className={`${classes.pageContainer}`}>
                <Grid item container direction="column" spacing={2}>
                    <SimpleHeader text="" variant="big" logo="large" />
                    <Grid item className={classes.title}>
                        <Typography variant="h1" color="textSecondary">
                            {Language.strings.login_welcome}
                        </Typography>
                    </Grid>
                    <Grid item className={`${classes.formContainer}`}>
                        <authContext.Provider value={di.authenticationBloc()}>
                            <LoginForm></LoginForm>
                        </authContext.Provider>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default LoginPage;
