import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        paper: {
            borderRadius: '30px',
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '350px',
            margin: '0 auto',
        },
        info: {
            padding: theme.spacing(1),
            alignItems: 'center',
            flexGrow: 1,
            backgroundColor: '#F2C6A1',
        },
        icon: {
            fontWeight: 'bolder',
            fontSize: '5rem',
            paddingBottom: '0.25rem',
        },
        title: {
            fontWeight: 'bolder',
            fontSize: '2rem',
            paddingBottom: '0.25rem',
        },
        subtitle1: {
            fontSize: '1.2rem',
            fontWeight: 'normal',
            paddingBottom: '0.25rem',
        },
        subtitle2: {
            fontSize: '1.2rem',
            fontWeight: 'bold',
            paddingBottom: '0.25rem',
        },
        subtitle3: {
            fontSize: '1rem',
            fontWeight: 'bold',
            paddingBottom: '0.25rem',
        },
        button: {
            marginTop: '1rem',
        },
    });
});
