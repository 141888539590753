import { Failure } from '../../../../../core/domain/failures/failure';
import { WalletModel } from '../../../domain/models/wallet_model';

export const walletInitialState: IDefaultWalletState = { type: 'DefaultWalletState' };

export interface IDefaultWalletState {
    type: 'DefaultWalletState';
}

export interface ILoadingWallet {
    type: 'LoadingWallet';
}

export interface IWalletLoaded {
    type: 'WalletLoaded';
    wallet: WalletModel;
}
export interface IFailure {
    type: 'Failure';
    failure: Failure;
}

export type WalletState = IDefaultWalletState | ILoadingWallet | IWalletLoaded | IFailure;
