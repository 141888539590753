import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { CheckCircleOutlined } from '@material-ui/icons';
import React from 'react';
import { useCollectionPageBloc } from '../../../../../core/presentation/contexts/contexts';
import LanguageManager from '../../../../../core/presentation/strings/LanguageManager';
import { Residue } from '../../../domain/models/residue';
import { useStyles } from './styles';

type CollectionSuccessfulCardProps = {
    residue: Residue;
    count: number;
};

export const CollectionSuccessfulCard: React.FC<CollectionSuccessfulCardProps> = ({
    residue,
    count,
}: CollectionSuccessfulCardProps) => {
    const classes = useStyles();
    const collectionBloc = useCollectionPageBloc();
    const handleBack = () => {
        collectionBloc.close();
    };
    return (
        <Paper elevation={3} className={classes.paper}>
            <Grid container className={classes.info}>
                <Grid item xs={12}>
                    <CheckCircleOutlined className={classes.icon} />
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.title}>
                        {LanguageManager.strings.collection_congrats}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.subtitle1}>
                        {LanguageManager.strings.collection_subtitle}:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.subtitle2}>
                        {`${count} x ${residue.name}`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.subtitle3}>
                        {LanguageManager.strings.collection_info(residue.value * count)}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.button}>
                    <Button color="secondary" fullWidth variant="outlined" onClick={handleBack}>
                        {LanguageManager.strings.back}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};
