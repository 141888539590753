export interface RegistrationStrings {
    registration_surname: string;
    registration_name: string;
    registration_username: string;
    registration_password: string;
    registration_identification_number: string;
    registration_email: string;
    registration_success: string;
    registration_login: string;
    registration_instructions: string;

    registration_surname_required_error: string;
    registration_name_required_error: string;
    registration_username_required_error: string;
    registration_username_unique_error: string;
    registration_password_required_error: string;
    registration_password_min_error: string;
    registration_identification_number_required_error: string;
    registration_identification_number_min_error: string;
    registration_email_required_error: string;
    registration_email_email_error: string;
    registration_email_unique_error: string;
}

export const registrationStrings: RegistrationStrings = {
    registration_surname: 'Apellido',
    registration_name: 'Nombre',
    registration_username: 'Usuario',
    registration_password: 'Contraseña',
    registration_identification_number: 'DNI',
    registration_email: 'Email',
    registration_success: `Tu REGISTRO se ha completado exitosamente! \nFELICIDADES! Ahora formás parte de cambio.\nPodés iniciar sesión haciendo click en el botón ACCEDER, y empezar a recibir beneficios y a
    ayudar al planeta!`,
    registration_login: 'ACCEDER',
    registration_instructions: 'Completá el formulario y empezá a recolectar y sumar PESOS y beneficios!',

    registration_surname_required_error: 'El campo Apellido es requerido.',
    registration_name_required_error: 'El campo Nombre es requerido.',
    registration_username_required_error: 'El campo Usuario es requerido.',
    registration_username_unique_error: 'El Usuario ingresado ya existe.',
    registration_password_required_error: 'El campo Contraseña es requerido.',
    registration_password_min_error: 'La Contraseña debe tener al menos 8 caracteres.',
    registration_identification_number_required_error: 'El campo DNI es requerido.',
    registration_identification_number_min_error: 'El DNI debe tener al menos 8 dígitos.',
    registration_email_required_error: 'El campo Email es requerido.',
    registration_email_email_error: 'El valor del campo Email es inválido.',
    registration_email_unique_error: 'El Email ingresado ya existe.',
};
