import { Paper } from '@material-ui/core';
import React, { Fragment } from 'react';
import { RoundedContainerTitle } from './rounded_container_title';
import { useStyles } from './styles';

type RoundedContainerProps = {
    children?: React.ReactElement;
    top: number;
    maxHeight?: boolean;
    title?: string;
    icon?: React.ReactElement;
    color?: 'default' | 'white-transparent' | 'grey-transparent' | 'white' | 'grey';
    className?: string;
};

export const RoundedContainer = ({
    children,
    top,
    maxHeight = false,
    title,
    icon,
    color,
    className,
}: RoundedContainerProps) => {
    const classes = useStyles({ minHeight: `calc(100vh - ${top}px - ${title ? '47.2px' : '0px'})`, maxHeight, color });
    return (
        <Fragment>
            {title ? (
                <div className={classes.header}>
                    <RoundedContainerTitle title={title} icon={icon} />
                </div>
            ) : null}
            <Paper elevation={0} className={`${classes.paper} ${className}`}>
                {children}
            </Paper>
        </Fragment>
    );
};
