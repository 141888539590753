import { AxiosInstance } from 'axios';
import { IAxiosDataSource } from '../../../../core/data/types/axios_datasource_interface';
import { httpToError } from '../../../../core/data/utils/httpToError';
import { CollectionEntity } from '../entities/collection_entity';
import { CreateCollectionParams, ICollectionsDataSource } from './types/collections_datasource_interface';

export class AxiosCollectionsDataSource implements ICollectionsDataSource, IAxiosDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async getValidatedCollections(consumerId: number): Promise<CollectionEntity[]> {
        try {
            const { data } = await this.axiosInstance.get(`collections`);
            return Promise.resolve((data as Array<any>).map((item: any) => CollectionEntity.fromObject(item)));
        } catch (error) {
            return Promise.reject(httpToError<null>(error));
        }
    }
    async createCollection({ consumerId, residueId, count }: CreateCollectionParams): Promise<CollectionEntity> {
        try {
            const params = { residue_id: residueId, count };
            const { data } = await this.axiosInstance.post(`collections`, params);
            return Promise.resolve(CollectionEntity.fromObject(data as any));
        } catch (error) {
            return Promise.reject(httpToError<null>(error));
        }
    }
}
