export interface RecoveryStrings {
    recovery_password: string;
    recovery_token: string;
    recovery_title: string;
    recovery_subtitle: string;

    recovery_password_required_error: string;
    recovery_password_min_error: string;
    recovery_token_required_error: string;
    recovery_token_exists_error: string;
}

export const recoveryStrings: RecoveryStrings = {
    recovery_password: 'Contraseña',
    recovery_token:
        'El token de autorización de cambio es inválido o ha vencido. Por favor solicite el cambio de contraseña nuevamente.',
    recovery_title: 'NUEVA CONTRASEÑA',
    recovery_subtitle: '¡Escribí tu nueva contraseña!',
    recovery_password_required_error: 'El campo Contraseña es requerido.',
    recovery_password_min_error: 'La Contraseña debe tener al menos 8 caracteres.',
    recovery_token_required_error: 'El token de recuperación es inválido.',
    recovery_token_exists_error: 'El token de recuperación es inválido.',
};
