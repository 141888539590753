import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { BlocBuilder } from '../../../../../common/bloc';
import FullScreenDialog from '../../../../../core/presentation/components/FullScreenDialog/fullscreen_dialog';
import { RoundedContainer } from '../../../../../core/presentation/components/RoundedContainer/rounded_container';
import { useEcoPointsMapBloc, useValidationsPageBloc } from '../../../../../core/presentation/contexts/contexts';
import useGeoLocation from '../../../../../core/presentation/hooks/useLocation';
import LanguageManager from '../../../../../core/presentation/strings/LanguageManager';
import { EcoPointsBloc } from '../../../../ecopoints/presentation/blocs/ecopoints_bloc';
import SimpleWallet from '../../../../wallet/presentation/components/SimpleWallet/simple_wallet';
import { ValidatedCollectionsPageBloc } from '../../blocs/ValidatedCollectionsBloc/validated_collections_page_bloc';
import { ValidatedCollectionsPageState } from '../../blocs/ValidatedCollectionsBloc/validated_collections_page_states';
import { ValidatedCollectionsList } from '../../components/ValidatedCollectionsList/validated_collections_list';
import UserCode from './../../components/UserCode/user_code';
import { useStyles } from './styles';

export const ValidatedCollectionsPage: React.FC = () => {
    const ecoPointsBloc: EcoPointsBloc = useEcoPointsMapBloc();
    const validationsPageBloc: ValidatedCollectionsPageBloc = useValidationsPageBloc();
    const location = useGeoLocation();
    const classes = useStyles();

    const handleEcoPointMapButtonClick = () => {
        if (location && location.loaded && location.coordinates && !location.error)
            ecoPointsBloc.open(location.coordinates);
    };

    const handleClose = () => {
        validationsPageBloc.close();
    };

    return (
        <BlocBuilder
            bloc={validationsPageBloc}
            builder={(state: ValidatedCollectionsPageState) => {
                return (
                    <FullScreenDialog
                        open={state.open}
                        title={LanguageManager.strings.collection_validated_title}
                        onClose={handleClose}
                        waveTop={10}
                    >
                        <>
                            <Grid container justify="space-between">
                                <Grid item sm={6}>
                                    <SimpleWallet variant="pending_balance" />
                                </Grid>
                                <Grid item sm={6}>
                                    <UserCode />
                                </Grid>
                            </Grid>

                            <RoundedContainer
                                top={240}
                                maxHeight={true}
                                title={LanguageManager.strings.collection_collected_residue}
                                color="white-transparent"
                            >
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ textAlign: 'center', position: 'relative', paddingBottom: '1rem' }}
                                    >
                                        <ValidatedCollectionsList />
                                        <Button
                                            onClick={handleEcoPointMapButtonClick}
                                            variant="outlined"
                                            color="secondary"
                                            className={classes.button}
                                        >
                                            {LanguageManager.strings.collection_validated_ecopoints}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </RoundedContainer>
                        </>
                    </FullScreenDialog>
                );
            }}
        />
    );
};
