import { Bloc } from '../../../../../common/bloc';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { Failure } from '../../../../../core/domain/failures/failure';
import { UnknownFailure } from '../../../../../core/domain/failures/unknown_failure';
import { Consumer } from '../../../../../core/domain/models/consumer_model';
import { GetAuthenticatedConsumerUseCase } from '../../../domain/usecases/get_authenticated_consumer';
import { UpdateProfileUseCase } from '../../../domain/usecases/update_profile';
import { initialState, ProfilePageState } from './profile_page_state';

type ProfilePageBlocArgs = {
    updateProfileUseCase: UpdateProfileUseCase;
    getConsumerUseCase: GetAuthenticatedConsumerUseCase;
};

type ChangeInputParams = {
    input: string;
    value: string | File;
};

export class ProfilePageBloc extends Bloc<ProfilePageState> {
    updateProfileUseCase: UpdateProfileUseCase;
    getConsumerUseCase: GetAuthenticatedConsumerUseCase;
    fileReader = new FileReader();
    constructor({ updateProfileUseCase, getConsumerUseCase }: ProfilePageBlocArgs) {
        super(initialState);
        this.updateProfileUseCase = updateProfileUseCase;
        this.getConsumerUseCase = getConsumerUseCase;
    }

    async open(): Promise<void> {
        const consumer = await this.getConsumerUseCase.execute();
        if (consumer instanceof AuthorizationFailure || consumer instanceof UnknownFailure) {
            this.changeState({ ...this.state, type: 'Failure', failure: new AuthorizationFailure() });
            return;
        }
        const { name, surname, email, identification_number, image } = consumer as Consumer;

        this.changeState({
            type: 'PageOpened',
            name,
            surname,
            email,
            identification_number,
            image,
            uploadedImage: undefined,
        });
    }
    async close(): Promise<void> {
        this.changeState({ ...this.state, type: 'PageClosed' });
    }
    async changeInput({ input, value }: ChangeInputParams): Promise<void> {
        const state: ProfilePageState = this.state;
        if (state.type === 'PageClosed') return;

        if (input === 'uploadedImage') this.fileReader.readAsDataURL(value as File);
        this.changeState({
            type: 'InputChanged',
            name: input === 'name' ? (value as string) : state.name,
            surname: input === 'surname' ? (value as string) : state.surname,
            identification_number:
                input === 'identification_number' ? parseInt(value as string) : state.identification_number,
            email: input === 'email' ? (value as string) : state.email,
            uploadedImage: input === 'uploadedImage' ? (value as File) : state.uploadedImage,
            image: input === 'uploadedImage' ? URL.createObjectURL(value as File) : state.image,
        });
    }

    async submitProfile(): Promise<void> {
        const state: ProfilePageState = this.state;
        if (state.type === 'PageClosed') return;

        this.changeState({ ...state, type: 'SubmittingProfile' });
        const { surname, email, identification_number, uploadedImage } = state;
        const params = {
            name: state.name,
            surname,
            email,
            identification_number,
            image: uploadedImage,
        };
        const result = await this.updateProfileUseCase.execute(params);
        if (this.state.type !== 'PageClosed') {
            if (result instanceof Failure) this.changeState({ ...state, type: 'Failure', failure: result });
            if (result instanceof Consumer) this.changeState({ ...state, type: 'ProfileSubmitted' });
        }
    }
}
