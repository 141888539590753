import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import Logo from '../Logo/logo';
import { useStyles } from './styles';

type SimpleHeaderProps = {
    text: string;
    logo: 'large' | 'small';
    variant: 'big' | 'small';
};

export const SimpleHeader: React.FC<SimpleHeaderProps> = ({ text, variant, logo }: SimpleHeaderProps) => {
    const classes = useStyles();

    return (
        <>
            <Grid container item alignItems="baseline">
                {logo === 'large' ? (
                    <Logo size={variant === 'big' ? 15 : 10} variant="large" />
                ) : (
                    <Logo size={variant === 'big' ? 3 : 1} variant="small" />
                )}
            </Grid>
            <Grid container item>
                <Typography variant={variant === 'big' ? 'h1' : 'h2'} color="textSecondary">
                    {text}
                </Typography>
            </Grid>
        </>
    );
};
