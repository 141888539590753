import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        button: {
            position: 'sticky',
            bottom: '1rem',
            backgroundColor: theme.colors.white,
            ['&:hover']: {
                backgroundColor: theme.colors.white,
            },
            marginTop: '2rem',
            boxShadow:
                '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        },
    });
});
