import { Failure } from '../../../../../core/domain/failures/failure';

export interface ICommonState {
    password: string;
}

export interface IDefaultState {
    type: 'DefaultState';
    password: '';
}

export interface ISubmittingConfirmation {
    type: 'SubmittingConfirmation';
}

export interface IConfirmationSubmitted {
    type: 'ConfirmationSubmitted';
}

export interface IPasswordChanged {
    type: 'PasswordChanged';
}

export interface IFailure {
    type: 'Failure';
    failure: Failure;
}

export type RecoveryConfirmationState = (
    | IDefaultState
    | ISubmittingConfirmation
    | IConfirmationSubmitted
    | IPasswordChanged
    | IFailure
) &
    ICommonState;

export const initialState: RecoveryConfirmationState = { type: 'DefaultState', password: '' };
