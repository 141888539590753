import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        raised_button: {
            backgroundColor: theme.palette.primary.main,
            ['&:hover']: { backgroundColor: theme.palette.primary.light },
        },
    });
});
