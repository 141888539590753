import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { Collection } from '../models/collection';
import { ICollectionsRepository } from '../repositories/collection_repository_interface';

export class GetValidatedCollections implements IUseCase<Collection[], void> {
    repository: ICollectionsRepository;

    constructor(repository: ICollectionsRepository) {
        this.repository = repository;
    }

    execute(): Promise<Collection[] | IFailure> {
        return this.repository.getValidatedCollections();
    }
}
