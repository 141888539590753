import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { areAnyNil } from '../../../../core/data/utils/areAnyNil';
import { IEntity } from '../../../../core/domain/types/entity_interface';
import { Store, StoreArgs } from '../../domain/models/store';

type StoreEntityArgs = {
    id: number;
};

export class StoreEntity extends Store implements IEntity {
    id: number;
    constructor(params: StoreArgs & StoreEntityArgs) {
        super(params);
        this.id = params.id;
    }

    toModel(): Store {
        return new Store(this);
    }

    static fromObject(obj: any): StoreEntity {
        try {
            const { id, address, coord_lat, coord_lng, phonenumber, name } = obj;
            if (areAnyNil([id, address, coord_lat, coord_lng, phonenumber, name])) {
                throw new Error();
            }
            return new StoreEntity({
                id,
                address,
                coordinates: { lat: coord_lat, lng: coord_lng },
                phoneNumber: phonenumber,
                name: name,
            });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'StoreEntity');
        }
    }
}
