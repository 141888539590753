import { Failure } from '../../../../../core/domain/failures/failure';
import { Purchase } from '../../../domain/models/purchase';

export interface IPageState {
    open: boolean;
}

export interface IPageClosed {
    type: 'PageClosed';
}

export interface ILoadingPurchases {
    type: 'LoadingPurchases';
}
export interface IPurchasesLoaded {
    type: 'PurchasesLoaded';
    pending: Purchase[];
    expired: Purchase[];
    exchanged: Purchase[];
}
export interface IFailure {
    type: 'Failure';
    failure: Failure;
}

export type PurchasesPageState = (IPageClosed | ILoadingPurchases | IPurchasesLoaded | IFailure) & IPageState;

export const initialState: PurchasesPageState = { type: 'PageClosed', open: false };
