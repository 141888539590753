import { Bloc } from '../../../../common/bloc';
import { ModalState } from './modal_states';

export class ModalBloc<T> extends Bloc<ModalState & T> {
    constructor(initialState: ModalState & T) {
        super(initialState);
    }

    async open(): Promise<void> {
        this.changeState({ ...this.state, open: true });
    }
    async close(): Promise<void> {
        this.changeState({ ...this.state, open: false });
    }
    async block(): Promise<void> {
        this.changeState({ ...this.state, block: true });
    }

    async unblock(): Promise<void> {
        this.changeState({ ...this.state, block: false });
    }
}
