import { IConsumer } from '../types/consumer_interface';
import { IModel } from '../types/model_interface';

export class Consumer implements IConsumer, IModel {
    type = 'ConsumerModel';
    username: string;
    name: string;
    surname: string;
    identification_number: number;
    email: string;
    image?: string;
    code: string;
    constructor({ username, name, surname, identification_number, email, image, code }: IConsumer) {
        this.username = username;
        this.name = name;
        this.surname = surname;
        this.identification_number = identification_number;
        this.email = email;
        this.image = image;
        this.code = code;
    }
}
