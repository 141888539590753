import { Grid, Typography } from '@material-ui/core';
import { CheckCircleOutlined } from '@material-ui/icons';
import React from 'react';
import Language from './../../../../../core/presentation/strings/LanguageManager';

type PurchaseDetailsProps = {
    purchase: any;
};
export function PurchaseDetails({ purchase }: PurchaseDetailsProps) {
    return (
        <>
            <Grid container alignItems="center" item xs={12}>
                <CheckCircleOutlined fontSize="large" />
                <Typography variant="h5" color="secondary">
                    {Language.strings.coupons_purchase_successful}
                </Typography>
            </Grid>
            <Grid container alignItems="center" item xs={12}>
                <Typography variant="subtitle1" color="secondary">
                    {`${Language.strings.coupons_code}:`}
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Typography
                    variant="h1"
                    color="secondary"
                    style={{
                        margin: '0 auto',
                    }}
                >
                    {purchase.code}
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Typography
                    variant="caption"
                    color="secondary"
                    style={{
                        margin: '0 auto',
                    }}
                >
                    {Language.strings.coupons_use}
                </Typography>
            </Grid>
        </>
    );
}
