import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        overflow: {
            overflowX: 'hidden',
        },
        card: {
            margin: '1rem',
            padding: '1rem',
            borderRadius: '15px',
            backgroundColor: theme.colors.white,
            //backdropFilter: 'blur(3px)',
            ['&>.MuiGrid-container']: {
                flexWrap: 'nowrap',
            },
            ['& .separated']: {
                margin: '1rem 0',
            },
            ['& .tabed']: {
                paddingLeft: '1.5rem',
            },
        },
        cardTitle: { fontSize: '1.2rem' },
        cardCount: { fontSize: '1.7rem' },
        cardImg: { ['& img']: { width: '100%', maxWidth: '140px', minWidth: '90px' } },
        button: {
            flexWrap: 'nowrap',
            cursor: 'pointer',
            display: 'flex',
            padding: '.5rem',
            margin: '0 auto',
            ['& .active']: {
                backgroundColor: theme.palette.primary.main,
            },
        },
        separator: {
            display: 'block',
            width: '100%',
            margin: '0.5rem 0',
            position: 'relative',
            ['&::after']: {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'linear-gradient(90deg, rgba(204,213,210,0) 0%, rgba(255,255,255,1) 100%)',
            },
        },
        cardContainer: {
            position: 'relative',
            height: '800px',

            ['& > .MuiPaper-root']: {
                position: 'absolute',
                top: 0,
                left: 0,
                width: 'calc(100% - 4rem)',
                ['&.active']: {
                    animationDuration: '.5s',
                    animationName: 'slideIn',
                    animationTimingFunction: 'ease',
                    animationFillMode: 'forwards',
                },
                ['&.inactive']: {
                    visibility: 'hidden',
                },
                ['&.out']: {
                    animationDuration: '.5s',
                    animationName: 'slideOut',
                    animationTimingFunction: 'ease',
                    animationFillMode: 'forwards',
                },
            },
        },
    });
});
