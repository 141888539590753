import { AxiosInstance } from 'axios';
import { IAxiosDataSource } from '../../../../core/data/types/axios_datasource_interface';
import { httpToError } from '../../../../core/data/utils/httpToError';
import { EcofootprintEntity } from '../entities/ecofootprint_entity';
import { IEcoFootprintDataSource } from './types/ecofootprint_datasource_interface';

export class AxiosEcoFootprintDataSource implements IEcoFootprintDataSource, IAxiosDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async getEcoFootprint(consumerId: number): Promise<EcofootprintEntity[]> {
        try {
            const { data } = await this.axiosInstance.get(`ecofootprint`);
            return Promise.resolve((data as Array<any>).map((item: any) => EcofootprintEntity.fromObject(item)));
        } catch (error) {
            return Promise.reject(httpToError<null>(error));
        }
    }
}
