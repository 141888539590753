import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { ResidueType } from '../models/residue_type';
import { IResidueTypeRepository } from '../repositories/residue_type_repository_interface';

export class GetResidueTypesUseCase implements IUseCase<ResidueType[], void> {
    repository: IResidueTypeRepository;

    constructor(repository: IResidueTypeRepository) {
        this.repository = repository;
    }

    execute(): Promise<ResidueType[] | IFailure> {
        return this.repository.getResidueTypes();
    }
}
