import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        image_viewport: {
            height: '180px',
            margin: '0 -16px',
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '15px 15px 0px 0px',
        },
        info: {
            height: '50%',
        },
        title: {
            fontSize: '1.4rem',
            fontWeight: 'lighter',
        },
        terms: {
            fontSize: '11px',
            fontWeight: 'lighter',
        },
        price: {
            fontWeight: 'bolder',
            fontSize: '1.3rem',
        },
    });
});
