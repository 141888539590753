import { LatLng } from 'leaflet';
import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Coordinates } from '../../../domain/types/Coordinates';
import { useStyles } from './styles';

export type Point = {
    coordinates: Coordinates;
    popup?: React.ReactElement;
};

type MapProps = {
    points: Point[];
    center?: Coordinates;
};

export const Map = ({ points, center }: MapProps) => {
    const classes = useStyles();
    const convertedPoints = points.map((point) => ({
        ...point,
        coordinates: { lat: parseFloat(point.coordinates.lat), lng: parseFloat(point.coordinates.lng) },
    }));
    return (
        <MapContainer
            className={classes.map}
            bounds={
                (convertedPoints.length &&
                    convertedPoints.map((point) => [point.coordinates.lat, point.coordinates.lng])) ||
                undefined
            }
            center={(center && new LatLng(parseFloat(center.lat), parseFloat(center.lng))) || undefined}
            scrollWheelZoom={true}
            zoom={(center && 13) || undefined}
        >
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {convertedPoints.map((point, index) => (
                <Marker position={[point.coordinates.lat, point.coordinates.lng]} key={index}>
                    {point.popup ? <Popup>{point.popup}</Popup> : null}
                </Marker>
            ))}
        </MapContainer>
    );
};
