import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        paper: {
            borderRadius: '10px',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            padding: '1rem 0.2rem',
            left: 0,
            right: 0,
            top: 0,
            marginBottom: '1rem',
            position: 'sticky',
        },
        price: {
            fontSize: '1.1rem',
            fontWeight: 'normal',
        },
        info: {
            fontSize: '0.8rem',
            fontWeight: 'normal',
        },
        featuredHeader: {
            fontWeight: 'bolder',
            fontSize: '1rem',
        },
        featured: {
            fontWeight: 'bolder',
            fontSize: '1.2rem',
        },
        row: {
            padding: '0.5rem 0',
            borderBottom: `1px solid ${theme.colors.greyTransparent}`,
        },
        loading: {
            textAlign: 'center',
            width: '100%',
            minHeight: 'calc(100vh - 24.3rem)',
            paddingTop: '1rem',
        },
    });
});
