import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { IAuthenticationRepository } from '../repositories/IAuthenticationRepository';

export class RequestRecovery implements IUseCase<void, string> {
    repository: IAuthenticationRepository;

    constructor(repository: IAuthenticationRepository) {
        this.repository = repository;
    }

    execute(email: string): Promise<void | IFailure> {
        return this.repository.requestRecovery(email);
    }
}
