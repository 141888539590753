import { Failure } from '../../../../../core/domain/failures/failure';
import { ModalState } from '../../../../../core/presentation/blocs/modal/modal_states';
import { Residue } from '../../../domain/models/residue';

export interface ICommonState {
    residue?: Residue;
    count: number;
}

export interface IIdle {
    type: 'Idle';
}
export interface ICountChanged {
    type: 'CountChanged';
}
export interface ISubmittingCollection {
    type: 'SubmittingCollection';
}
export interface ICollectionSuccessful {
    type: 'CollectionSuccessful';
}
export interface IFailure {
    type: 'Failure';
    failure: Failure;
}

export type CollectionPageState = (IIdle | ISubmittingCollection | ICountChanged | ICollectionSuccessful | IFailure) &
    ICommonState &
    ModalState;

export const initialState: CollectionPageState = {
    type: 'Idle',
    residue: undefined,
    count: 1,
    open: false,
    block: false,
};
