import { Button, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import BlocBuilder from '../../../../../common/bloc/BlocBuilder';
import {
    CartIcon,
    CuponIcon,
    LocationIcon,
    RecicleIcon,
} from '../../../../../core/presentation/components/Icons/icons';
import { Logout } from '../../../../../core/presentation/components/LogoutRedirect/logout_redirect';
import { useWalletBloc } from '../../../../../core/presentation/contexts/contexts';
import { WalletState } from '../../blocs/wallet_bloc/wallet_states';
import { WalletCard } from '../WalletCard/wallet_card';
import { AuthorizationFailure } from './../../../../../core/domain/failures/authorization_failure';
import Language from './../../../../../core/presentation/strings/LanguageManager';

type WalletProps = {
    onResidueLoadButtonClick: (event: React.MouseEvent) => void;
    onValidationButtonClick: (event: React.MouseEvent) => void;
    onCouponPurchaseButtonClick: (event: React.MouseEvent) => void;
    onMyCouponsButtonClick: (event: React.MouseEvent) => void;
    fetchOnMount?: boolean;
};

const Wallet: React.FC<WalletProps> = ({
    onResidueLoadButtonClick,
    onValidationButtonClick,
    onCouponPurchaseButtonClick,
    onMyCouponsButtonClick,
    fetchOnMount = true,
}: WalletProps) => {
    const bloc = useWalletBloc();
    if (fetchOnMount) {
        useEffect(() => {
            bloc.getConsumerWallet();
        }, []);
    }

    return (
        <Grid container spacing={2}>
            <BlocBuilder
                bloc={bloc}
                builder={(state: WalletState) => {
                    let pending_balance = 0;
                    let effective_balance = 0;
                    const loading = state.type === 'LoadingWallet';
                    if (state.type === 'Failure' && state.failure instanceof AuthorizationFailure) return <Logout />;
                    if (state.type === 'WalletLoaded') {
                        pending_balance = state.wallet.pending_balance;
                        effective_balance = state.wallet.effective_balance;
                    }
                    return (
                        <>
                            <Grid item xs={12}>
                                <WalletCard
                                    title={Language.strings.wallet_pending_balance}
                                    balance={`${pending_balance}*`}
                                    helperText={Language.strings.wallet_helper}
                                    primaryButton={
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={onResidueLoadButtonClick}
                                        >
                                            <RecicleIcon />
                                            {Language.strings.wallet_collect}
                                        </Button>
                                    }
                                    secondaryButton={
                                        <Button variant="outlined" fullWidth onClick={onValidationButtonClick}>
                                            <LocationIcon />
                                            {Language.strings.wallet_ecopoints_map}
                                        </Button>
                                    }
                                    loading={loading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <WalletCard
                                    title={Language.strings.wallet_your_wallet}
                                    balance={`${effective_balance}`}
                                    helperText={Language.strings.wallet_available_balance}
                                    backgroundColor="#e5e5e5c9"
                                    primaryButton={
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={onCouponPurchaseButtonClick}
                                        >
                                            <CartIcon />
                                            {Language.strings.wallet_buy_coupons}
                                        </Button>
                                    }
                                    secondaryButton={
                                        <Button variant="outlined" fullWidth onClick={onMyCouponsButtonClick}>
                                            <CuponIcon />
                                            {Language.strings.wallet_my_coupons}
                                        </Button>
                                    }
                                    loading={loading}
                                />
                            </Grid>
                        </>
                    );
                }}
            />
        </Grid>
    );
};

export default Wallet;
