export interface GlobalStrings {
    email: string;
    submitting: string;
    submit: string;
    saving: string;
    save: string;
    welcome: string;
    unknown_failure: string;
    back: string;
    close: string;
    confirmation_title: string;
    confirmation_content: string;
    confirm: string;
    deny: string;
    help: string;
    pageNotFound: string;
    retry: string;
    select: string;
    search: string;
    location_uncertain: string;
}

export const globalStrings: GlobalStrings = {
    email: 'Email',
    submit: 'Enviar',
    submitting: 'Enviando...',
    welcome: '¡Bienvenido!',
    unknown_failure: '¡Ha ocurrido un error! Por favor inténtelo de nuevo más tarde.',
    back: 'Volver',
    close: 'Cerrar',
    save: 'GUARDAR',
    saving: 'GUARDANDO...',
    confirmation_title: 'Confirmación',
    confirmation_content: '¿Está seguro de que desea realizar ésta operación?',
    confirm: 'Confirmar',
    deny: 'CANCELAR',
    help: 'Ayuda',
    pageNotFound: 'La página que está buscando no pudo ser encontrada',
    retry: 'Volver a intentar',
    select: 'Seleccionar',
    search: 'Buscar',
    location_uncertain: 'La geoposición sólo está disponible en dispositivos móviles.',
};
