import { createStyles, makeStyles, Theme } from '@material-ui/core';
import noIcon from '../../../../../assets/images/no.svg';
import yesIcon from '../../../../../assets/images/yes.svg';

export const useStyles = makeStyles((theme: Theme) => {
    const media = theme.breakpoints.up('sm');

    return createStyles({
        searchBox: {
            ['& .MuiInputBase-root']: {
                backgroundColor: '#FBFBFBC4',
            },
            ['& .MuiOutlinedInput-adornedEnd']: {
                paddingRight: 0,
            },
            ['& .MuiIconButton-root']: {
                borderLeft: `1px solid ${theme.palette.secondary.main}`,
            },
            ['& .MuiOutlinedInput-notchedOutline']: {
                border: `1px solid ${theme.palette.secondary.main}`,
            },
        },
        searchBoxContainer: {
            padding: '0 1rem',
            marginTop: '1rem',
            marginBottom: '1rem',
            [theme.breakpoints.up('sm')]: {
                padding: '0',
            },
        },
        infoContainer: {},
        listContainer: {
            ['& ul']: {
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: 0,
                listStyle: 'none',
                margin: 0,
                ['& li']: {
                    paddingLeft: '20px',
                    position: 'relative',
                },
                ['& li::before']: {
                    position: 'absolute',
                    content: '""',
                    width: '11px',
                    height: '11px',
                    left: '5px',
                    top: '5px',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(${yesIcon})`,
                },
                ['& li.no::before']: {
                    backgroundImage: `url(${noIcon})`,
                },
            },
        },
        imgContainer: { ['& img']: { width: '100%', maxWidth: '120px' } },
    });
});
