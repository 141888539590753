import { AuthorizationFailure } from '../../domain/failures/authorization_failure';
import { ConvertionFailure } from '../../domain/failures/convertion_failure';
import { Failure } from '../../domain/failures/failure';
import { ServerFailure } from '../../domain/failures/server_failure';
import { UnknownFailure } from '../../domain/failures/unknown_failure';
import { ValidationFailure } from '../../domain/failures/validation_failure';
import { AuthorizationError } from '../errors/authorization_error';
import { CacheError } from '../errors/cache_error';
import { ConvertionError } from '../errors/convertion_error';
import { ServerError } from '../errors/server_error';
import { ValidationError } from '../errors/validation_error';

export function errorToFailure<ValidationsType>(error: Error): Failure {
    if (error instanceof ValidationError) return new ValidationFailure<ValidationsType>(error.fails, error.message);
    if (error instanceof ServerError) return new ServerFailure(error.message);
    if (error instanceof ConvertionError) return new ConvertionFailure(error.message);
    if (error instanceof AuthorizationError || error instanceof CacheError)
        return new AuthorizationFailure(error.message);

    return new UnknownFailure(error.message);
}
