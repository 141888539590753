import { Button, Grid, Typography } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { BlocBuilder } from '../../../../../../../common/bloc';
import Language from '../../../../../../../core/presentation/strings/LanguageManager';
import MuiTextField from '../../../../../../../core/presentation/components/MUITextField/MuiTextField';
import React from 'react';
import { RecoveryRequestState } from '../../../../blocs/recovery_request_bloc/recovery_request_states';
import { ValidationFailure } from '../../../../../../../core/domain/failures/validation_failure';
import _ from 'lodash';
import { useRecoveryRequestBloc } from '../../../../../../../core/presentation/contexts/contexts';
import { useStyles } from './styles';

const RecoveryRequestForm = ({ history }: RouteComponentProps) => {
    const classes = useStyles();
    const bloc = useRecoveryRequestBloc();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        bloc.changeEmail(event.target.value);
    };
    const handleSubmit = (e: any) => {
        bloc.submitRequest();
    };

    const handleBack = (e: any) => {
        history.push('/login');
    };
    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: RecoveryRequestState) => {
                const errors =
                    state.type === 'Failure' && state.failure instanceof ValidationFailure
                        ? state.failure.fails
                        : undefined;
                return (
                    <Grid container spacing={2} justify="space-between">
                        {state.type !== 'RequestSubmitted' ? (
                            <>
                                <Grid item>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {Language.strings.request_subtitle}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        autoFocus
                                        name="email"
                                        value={state.email}
                                        onChange={handleChange}
                                        type="email"
                                        label={Language.strings.request_email}
                                        variant="filled"
                                        fullWidth={true}
                                        className={`${classes.input}`}
                                        error={!_.isNil(errors) && !_.isNil(errors.email)}
                                        helperText={Language.getErrorString('request', 'email', state)}
                                    />
                                </Grid>
                                {state.type === 'Failure' && !(state.failure instanceof ValidationFailure) ? (
                                    <Grid item xs={12}>
                                        <Typography color="error">{Language.strings.unknown_failure}</Typography>
                                    </Grid>
                                ) : null}
                                <Grid item container justify="flex-end">
                                    <Grid item xs={12} className={classes.actionButton}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleBack}
                                            className={classes.backButton}
                                        >
                                            {Language.strings.back}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={state.type === 'SubmittingRequest'}
                                            onClick={handleSubmit}
                                        >
                                            {state.type === 'SubmittingRequest'
                                                ? Language.strings.submitting
                                                : Language.strings.submit}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <Typography color="primary" variant="h2">
                                        {Language.strings.request_success(state.email)}
                                    </Typography>
                                </Grid>
                                <Grid item container justify="flex-end">
                                    <Grid item xs={12} className={classes.actionButton}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleBack}
                                            className={classes.backButton}
                                        >
                                            {Language.strings.back}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                );
            }}
        />
    );
};

export default withRouter(RecoveryRequestForm);
