import AppBar from '@material-ui/core/AppBar';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Logo from '../Logo/logo';
import React from 'react';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import { WaveBackground } from '../wave_background';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type FullScreenDialogProps = {
    disabled?: boolean;
    open: boolean;
    title: string;
    children: React.ReactElement;
    disablePortal?: boolean;
    zIndex?: number;
    onClose: (event: React.MouseEvent) => void;
    allowScroll?: boolean;
    className?: string;
    waveTop?: number;
};
const FullScreenDialog: React.FC<FullScreenDialogProps> = ({
    disabled,
    open,
    title,
    children,
    disablePortal = false,
    zIndex,
    onClose,
    allowScroll = false,
    className,
    waveTop = 5,
}: FullScreenDialogProps) => {
    const classes = useStyles({ zIndex, allowScroll });
    return (
        <Dialog
            fullScreen
            className={classes.dialog + ' ' + className}
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            disablePortal={disablePortal}
        >
            <AppBar color="secondary" className={classes.appBar} elevation={0}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" disabled={disabled}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    <Logo variant="small" size={2.5} />
                </Toolbar>
            </AppBar>
            <>
                <WaveBackground top={waveTop} zIndex={0} />
                <div
                    style={{
                        zIndex: 0,
                        paddingTop: '4rem',
                        overflowX: 'hidden',
                        overflowY: allowScroll ? 'auto' : 'hidden',
                    }}
                >
                    <div className={classes.pageContainer}>{children}</div>
                </div>
            </>
        </Dialog>
    );
};
export default FullScreenDialog;
