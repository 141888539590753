import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            ['& .MuiInputBase-root']: {
                boxShadow: theme.shadows[3],
                borderRadius: '5px',
                ['&.MuiFilledInput-root']: {
                    backgroundColor: theme.palette.background.paper,
                },
                ['&.MuiFilledInput-underline::before']: {
                    borderBottomWidth: '5px',
                    borderBottomRightRadius: '3px',
                    borderBottomLeftRadius: '3px',
                    borderBottomStyle: 'solid',
                    borderBottomColor: theme.palette.background.default,
                },
                ['&.MuiFilledInput-underline::after']: {
                    borderBottomWidth: '5px',
                    borderBottomRightRadius: '3px',
                    borderBottomLeftRadius: '3px',
                },
            },
            ['& .MuiFormLabel-root:not(.Mui-focused)']: { color: theme.palette.text.primary },
        },
        success: {
            padding: theme.spacing(5),
        },
        actionButton: { textAlign: 'right' },
        backButton: { marginRight: '1rem' },
        link: {
            ['& > a']: { color: theme.palette.text.secondary, fontSize: '0.8rem' },
            marginTop: '1rem',
            textAlign: 'right',
        },
    }),
);
