import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        horizontalContainer: {
            flexWrap: 'nowrap',
            overflowX: 'auto',
            ['& .MuiPaper-root']: {
                minWidth: '170px',
            },
        },
        stackItem: {
            marginTop: '-20px',
            position: 'sticky',
            ['&:first-child']: {
                marginTop: '0px',
                top: '0px',
            },
            ['&:nth-child(2)']: {
                top: '36px',
            },
            ['&:nth-child(3) > div:nth-child(2)']: {
                minHeight: 'calc(100vh - 225px)',
            },
            ['& .rounded-container']: {
                minHeight: 'unset',
                maxHeight: 'unset',
                padding: '0 16px 40px 16px',
            },
        },
    }),
);
