import { Failure } from '../../../../../core/domain/failures/failure';
import { GetValidatedCollections } from '../../../../collections/domain/usecases/get_validated_collections_usecase';
import { Collection } from '../../../domain/models/collection';
import { ModalBloc } from './../../../../../core/presentation/blocs/modal/modal_bloc';
import { initialState, ValidatedCollectionsPageState } from './validated_collections_page_states';

export class ValidatedCollectionsPageBloc extends ModalBloc<ValidatedCollectionsPageState> {
    usecase: GetValidatedCollections;
    constructor(usecase: GetValidatedCollections) {
        super(initialState);
        this.usecase = usecase;
    }
    async getValidatedCollections(): Promise<void> {
        const { open, block } = this.state;
        this.changeState({ ...this.state, type: 'LoadingCollections' });
        const result = await this.usecase.execute();
        if (result instanceof Failure) {
            this.changeState({ open, block, type: 'Failure', failure: result });
            return;
        }
        this.changeState({ open, block, type: 'CollectionsLoaded', collections: result as Collection[] });
    }
}
