import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { BlocBuilder } from '../../../common/bloc';
import di from '../../../common/di/di';
import { AuthorizedRouteState } from '../blocs/authorized_route_bloc_states';
import { Logout } from '../components/LogoutRedirect/logout_redirect';
import { authRouteContext, useAuthRouteBloc } from '../contexts/contexts';
import HomePage from './../pages/HomePage/home_page';

export const AuthRoutes: React.FC = () => {
    return (
        <authRouteContext.Provider value={di.authorizedRouteBloc()}>
            <Authorizer />
        </authRouteContext.Provider>
    );
};
const Authorizer = withRouter(({ history }) => {
    const bloc = useAuthRouteBloc();
    useEffect(() => {
        bloc.getAuthenticated();
    }, []);
    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: AuthorizedRouteState) => {
                //TODO comment
                if (state.type === 'AuthorizedConsumerFailed') {
                    return <Logout />;
                }
                if (state.type === 'AuthorizedConsumerLoaded') {
                    return <Routes />;
                }
                return <></>;
            }}
        />
    );
});

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/home">
                <HomePage />
            </Route>
            <Route component={Page404} />
        </Switch>
    );
};

const Page404 = () => (
    <div>
        <h2>404</h2>
    </div>
);
