import { useEffect } from 'react';
import Bloc from '../../../common/bloc/Bloc';

export type useBlocSuscriptionProps<T> = {
    bloc: Bloc<T>;
    action: (state: T, previousState: T) => void;
};

export function useBlocSuscription<T>({ bloc, action }: useBlocSuscriptionProps<T>) {
    useEffect(() => {
        bloc.subscribe(action);
        return () => bloc.unsubscribe(action);
    }, []);
}
