import { Failure } from '../../../../../core/domain/failures/failure';
import { ModalState } from '../../../../../core/presentation/blocs/modal/modal_states';
import { Ecofootprint } from '../../../domain/models/ecofootprint';

export interface IIdle {
    type: 'Idle';
}

export interface ILoadingEcoFootprint {
    type: 'LoadingEcoFootprint';
}

export interface IEcoFootprintLoaded {
    type: 'EcoFootprintLoaded';
    ecofootprint: Ecofootprint[];
}

export interface IFailure {
    type: 'Failure';
    failure: Failure;
}

export type EcoFootprintPageState = (IIdle | ILoadingEcoFootprint | IEcoFootprintLoaded | IFailure) & ModalState;

export const initialState: EcoFootprintPageState = {
    type: 'Idle',
    open: false,
    block: false,
};
