import { FailureLogger } from '../../../../common/utils/FailureLogger';
import { CacheError } from '../../../../core/data/errors/cache_error';
import { IAuthenticationCacheDataSource } from '../../../../core/data/types/authentication_cache_datasource_interface';
import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { AuthorizationFailure } from '../../../../core/domain/failures/authorization_failure';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { Ecofootprint } from '../../domain/models/ecofootprint';
import { IEcoFootprintRepository } from '../../domain/repositories/ecofootprint_repository_interface';
import { IEcoFootprintDataSource } from '../datasources/types/ecofootprint_datasource_interface';
import { EcofootprintEntity } from '../entities/ecofootprint_entity';

type EcoFootprintRepositoryParams = {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: IEcoFootprintDataSource;
};

export class EcoFootprintRepository implements IEcoFootprintRepository {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: IEcoFootprintDataSource;

    constructor({ authCacheDataSource, apiDataSource }: EcoFootprintRepositoryParams) {
        this.apiDataSource = apiDataSource;
        this.authCacheDataSource = authCacheDataSource;
    }

    async getEcoFootprint(): Promise<Ecofootprint[] | IFailure> {
        try {
            const isAuthenticated = await this.authCacheDataSource.isAuthenticated();
            if (!isAuthenticated) {
                const failure = new AuthorizationFailure();
                FailureLogger.log(failure);
                return failure;
            }
            const consumer = await this.authCacheDataSource.getConsumer();
            const result = await this.apiDataSource.getEcoFootprint(consumer.id);
            return Promise.resolve((result as EcofootprintEntity[]).map((type) => type.toModel()));
        } catch (error) {
            if (error instanceof CacheError) return Promise.resolve(new AuthorizationFailure());
            return Promise.resolve(errorToFailure<null>(error));
        }
    }
}
