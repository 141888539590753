import { Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

type RoundedContainerTitleProps = {
    icon?: React.ReactElement;
    title: string;
};

export const RoundedContainerTitle = ({ icon, title }: RoundedContainerTitleProps) => {
    const classes = useStyles();
    return (
        <Typography variant="h1" className={classes.title}>
            {icon ? icon : null}
            {title}
        </Typography>
    );
};
