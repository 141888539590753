/* istanbul ignore file */

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { IAuthenticationCacheDataSource } from '../../core/data/types/authentication_cache_datasource_interface';
import { AuthorizedRouteBloc } from '../../core/presentation/blocs/authorized_route_bloc';
import { MomentSpanishDateFormatter } from '../../core/presentation/formatters/moment_date_formatter';
import { IDateFormatter } from '../../core/presentation/types/date_formatter_interface';
import { AxiosAuthenticationDataSource } from '../../features/authentication/data/datasources/axios_athentication_datasource';
import { SessionStorageCacheDataSource } from '../../features/authentication/data/datasources/session_storage_cache_datasource';
import { AuthenticationRepository } from '../../features/authentication/data/repositories/remote_authentication_repository';
import { IAuthenticationRepository } from '../../features/authentication/domain/repositories/IAuthenticationRepository';
import { AuthenticateConsumerUseCase } from '../../features/authentication/domain/usecases/authenticate_consumer';
import { GetAuthenticatedConsumerUseCase } from '../../features/authentication/domain/usecases/get_authenticated_consumer';
import { RecoverPassword } from '../../features/authentication/domain/usecases/recover_password';
import { RegisterConsumerUseCase } from '../../features/authentication/domain/usecases/register_consumer';
import { RequestRecovery } from '../../features/authentication/domain/usecases/request_recovery';
import { UpdateProfileUseCase } from '../../features/authentication/domain/usecases/update_profile';
import { AuthenticationBloc } from '../../features/authentication/presentation/blocs/authentication_bloc/authentication_bloc';
import { ProfilePageBloc } from '../../features/authentication/presentation/blocs/profile_page_bloc/profile_page_bloc';
import { RecoveryConfirmationBloc } from '../../features/authentication/presentation/blocs/recovery_confirmation_bloc/recovery_confirmation_bloc';
import { RecoveryRequestBloc } from '../../features/authentication/presentation/blocs/recovery_request_bloc/recovery_request_bloc';
import { RegistrationBloc } from '../../features/authentication/presentation/blocs/registration_bloc/registration_bloc';
import { AxiosCollectionsDataSource } from '../../features/collections/data/datasources/axios_collections_datasource';
import { AxiosResiduesDataSource } from '../../features/collections/data/datasources/axios_residues_datasource';
import { CollectionsRepository } from '../../features/collections/data/repositories/collections_repository';
import { ResiduesRepository } from '../../features/collections/data/repositories/residues_repository';
import { ICollectionsRepository } from '../../features/collections/domain/repositories/collection_repository_interface';
import { IResiduesRepository } from '../../features/collections/domain/repositories/residues_repository_interface';
import { CreateCollectionUseCase } from '../../features/collections/domain/usecases/create_collection_use_case';
import { GetResiduesUseCase } from '../../features/collections/domain/usecases/get_residues_use_case';
import { GetValidatedCollections } from '../../features/collections/domain/usecases/get_validated_collections_usecase';
import { CollectionPageBloc } from '../../features/collections/presentation/blocs/CollectionPageBloc/collection_page_bloc';
import { ResiduesPageBloc } from '../../features/collections/presentation/blocs/ResiduesPageBloc/residues_page_bloc';
import { ValidatedCollectionsPageBloc } from '../../features/collections/presentation/blocs/ValidatedCollectionsBloc/validated_collections_page_bloc';
import { AxiosCouponsDataSource } from '../../features/coupons/data/datasources/axios_coupons_datasource';
import { CouponsRepository } from '../../features/coupons/data/repositories/coupons_repository';
import { GetConsumerPurchasesUseCase } from '../../features/coupons/domain/usecases/get_consumer_purchases';
import { GetCouponsUseCase } from '../../features/coupons/domain/usecases/get_coupons_use_case';
import { GetCouponExchangeStoresUseCase } from '../../features/coupons/domain/usecases/get_coupon_exchange_stores';
import { PurchaseCouponUseCase } from '../../features/coupons/domain/usecases/purchase_coupon_use_case';
import { CouponsBrowserBloc } from '../../features/coupons/presentation/blocs/BrowserBloc/coupons_browser_bloc';
import { PurchaseBloc } from '../../features/coupons/presentation/blocs/PurchasePageBloc/purchase_bloc';
import { PurchasesPageBloc } from '../../features/coupons/presentation/blocs/PurchasesPageBloc/purchases_page_bloc';
import { StoresMapBloc } from '../../features/coupons/presentation/blocs/StoresMapBloc/store_map_bloc';
import { AxiosEcoFootprintDataSource } from '../../features/ecofootprint/data/datasources/axios_ecofootprint_datasource';
import { EcoFootprintRepository } from '../../features/ecofootprint/data/repositories/ecofootprint_repository';
import { IEcoFootprintRepository } from '../../features/ecofootprint/domain/repositories/ecofootprint_repository_interface';
import { GetEcoFootprint } from '../../features/ecofootprint/domain/usecases/get_ecofootprint_usecase';
import { EcoFootprintPageBloc } from '../../features/ecofootprint/presentation/blocs/EcoFootprintBloc/ecofootprint_page_bloc';
import { AxiosEcoPointsDataSource } from '../../features/ecopoints/data/datasources/axios_ecopoints_datasource';
import { EcoPointsRepository } from '../../features/ecopoints/data/repositories/ecopoints_repository';
import { IEcoPointsRepository } from '../../features/ecopoints/domain/repositories/ecopoints_repository_interface';
import { GetEcoPointsUseCase } from '../../features/ecopoints/domain/usecases/get_ecopoints_use_case';
import { EcoPointsBloc } from '../../features/ecopoints/presentation/blocs/ecopoints_bloc';
import { AxiosResidueTypeDataSource } from '../../features/residue_types/data/datasources/axios_residue_type_datasource';
import { ResidueTypeRepository } from '../../features/residue_types/data/repositories/residue_type_repository';
import { IResidueTypeRepository } from '../../features/residue_types/domain/repositories/residue_type_repository_interface';
import { GetResidueTypesUseCase } from '../../features/residue_types/domain/usecases/get_residue_types_usecase';
import { AxiosWalletDataSource } from '../../features/wallet/data/datasources/axios_wallet_datasource';
import { WalletRepository } from '../../features/wallet/data/repositories/wallet_repository';
import { GetConsumerWalletUseCase } from '../../features/wallet/domain/usecases/get_consumer_wallet_usecase';
import { WalletBloc } from '../../features/wallet/presentation/blocs/wallet_bloc/wallet_bloc';

const api_url = process.env.REACT_APP_API_URL;

class DependencyInjector {
    private static axiosInstance?: AxiosInstance = undefined;

    static async loadAsyncDependencies() {
        await this.createAxiosInstance(new SessionStorageCacheDataSource());
    }

    static async createAxiosInstance(
        cacheDataSource: IAuthenticationCacheDataSource,
        config?: AxiosRequestConfig,
    ): Promise<void> {
        const defaultHeaders = { 'Access-Control-Allow-Origin': true };
        if (!this.axiosInstance) {
            const token = await cacheDataSource.getToken();
            this.axiosInstance = axios.create({
                ...config,
                baseURL: api_url,
                headers: token ? { ...defaultHeaders, Authorization: 'Bearer ' + token } : defaultHeaders,
            });
        }
        this.axiosInstance.defaults = { ...this.axiosInstance.defaults, timeout: 180000, ...config };
    }

    private static authenticationRepository() {
        const cacheDataSource = new SessionStorageCacheDataSource();
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const apiDataSource = new AxiosAuthenticationDataSource({ axiosInstance: axios });
        return new AuthenticationRepository({ apiDataSource, cacheDataSource });
    }

    static registrationBloc(): RegistrationBloc {
        const authRepository = this.authenticationRepository();
        const registerConsumerUseCase = new RegisterConsumerUseCase(authRepository);
        return new RegistrationBloc({ registerConsumerUseCase });
    }
    static authenticationBloc(): AuthenticationBloc {
        const authRepository = this.authenticationRepository();
        const authenticationUseCase = new AuthenticateConsumerUseCase(authRepository);
        return new AuthenticationBloc({ authenticationUseCase });
    }
    static walletBloc(): WalletBloc {
        const authCacheDataSource = new SessionStorageCacheDataSource();
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const apiDataSource = new AxiosWalletDataSource({ axiosInstance: axios });
        const repository = new WalletRepository({ authCacheDataSource, apiDataSource });
        const getConsumerWalletUseCase = new GetConsumerWalletUseCase({ repository });
        return new WalletBloc({ getConsumerWalletUseCase });
    }

    private static couponsRepository() {
        const authCacheDataSource = new SessionStorageCacheDataSource();
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const apiDataSource = new AxiosCouponsDataSource({ axiosInstance: axios });
        return new CouponsRepository({ authCacheDataSource, apiDataSource });
    }

    static couponBrowserBloc(couponsPageSize: number): CouponsBrowserBloc {
        const couponsRepository: CouponsRepository = this.couponsRepository();
        const getCouponsUseCase = new GetCouponsUseCase(couponsRepository);
        return new CouponsBrowserBloc({ getCouponsUseCase, couponsPageSize });
    }

    static purchaseBloc(): PurchaseBloc {
        const couponsRepository: CouponsRepository = this.couponsRepository();
        const purchaseCouponUseCase = new PurchaseCouponUseCase(couponsRepository);
        return new PurchaseBloc(purchaseCouponUseCase);
    }
    static purchasesPageBloc(): PurchasesPageBloc {
        const couponsRepository: CouponsRepository = this.couponsRepository();
        const getConsumerPurchasesUseCase = new GetConsumerPurchasesUseCase(couponsRepository);
        return new PurchasesPageBloc(getConsumerPurchasesUseCase);
    }

    static storesMapBloc(): StoresMapBloc {
        const couponsRepository: CouponsRepository = this.couponsRepository();
        const usecase = new GetCouponExchangeStoresUseCase(couponsRepository);
        return new StoresMapBloc(usecase);
    }

    private static ecoPointsRepository() {
        const authCacheDataSource = new SessionStorageCacheDataSource();
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const apiDataSource = new AxiosEcoPointsDataSource(axios);
        return new EcoPointsRepository({ authCacheDataSource, apiDataSource });
    }

    static ecoPointsMapBloc(): EcoPointsBloc {
        const repository: IEcoPointsRepository = this.ecoPointsRepository();
        const usecase = new GetEcoPointsUseCase(repository);
        return new EcoPointsBloc(usecase);
    }

    private static residuesRepository() {
        const authCacheDataSource = new SessionStorageCacheDataSource();
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const apiDataSource = new AxiosResiduesDataSource(axios);
        return new ResiduesRepository({ authCacheDataSource, apiDataSource });
    }

    private static residuesTypeRepository() {
        const authCacheDataSource = new SessionStorageCacheDataSource();
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const apiDataSource = new AxiosResidueTypeDataSource(axios);
        return new ResidueTypeRepository({ authCacheDataSource, apiDataSource });
    }

    static residuesPageBloc(): ResiduesPageBloc {
        const residuesRepository: IResiduesRepository = this.residuesRepository();
        const residueTypeRepository: IResidueTypeRepository = this.residuesTypeRepository();
        const getResiduesUseCase = new GetResiduesUseCase(residuesRepository);
        const getResidueTypesUseCase = new GetResidueTypesUseCase(residueTypeRepository);
        return new ResiduesPageBloc({ getResiduesUseCase, getResidueTypesUseCase, residuesPageSize: 20 });
    }

    private static collectionRepository() {
        const authCacheDataSource = new SessionStorageCacheDataSource();
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const apiDataSource = new AxiosCollectionsDataSource(axios);
        return new CollectionsRepository({ authCacheDataSource, apiDataSource });
    }

    static collectionPageBloc(): CollectionPageBloc {
        const repository: ICollectionsRepository = this.collectionRepository();
        const usecase = new CreateCollectionUseCase(repository);
        return new CollectionPageBloc(usecase);
    }

    static recoveryRequestBloc(): RecoveryRequestBloc {
        const repository: IAuthenticationRepository = this.authenticationRepository();
        const usecase = new RequestRecovery(repository);
        return new RecoveryRequestBloc(usecase);
    }
    static recoveryConfirmationBloc(): RecoveryConfirmationBloc {
        const repository: IAuthenticationRepository = this.authenticationRepository();
        const usecase = new RecoverPassword(repository);
        return new RecoveryConfirmationBloc(usecase);
    }
    static profilePageBloc(): ProfilePageBloc {
        const repository: IAuthenticationRepository = this.authenticationRepository();
        const getConsumerUseCase: GetAuthenticatedConsumerUseCase = new GetAuthenticatedConsumerUseCase(repository);
        const updateProfileUseCase: UpdateProfileUseCase = new UpdateProfileUseCase(repository);
        return new ProfilePageBloc({ getConsumerUseCase, updateProfileUseCase });
    }
    static validatedCollectionsPageBloc(): ValidatedCollectionsPageBloc {
        const repository: ICollectionsRepository = this.collectionRepository();
        const usecase = new GetValidatedCollections(repository);
        return new ValidatedCollectionsPageBloc(usecase);
    }
    private static ecoFootprintRepository() {
        const authCacheDataSource = new SessionStorageCacheDataSource();
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const apiDataSource = new AxiosEcoFootprintDataSource(axios);
        return new EcoFootprintRepository({ authCacheDataSource, apiDataSource });
    }
    static ecoFootprintPageBloc(): EcoFootprintPageBloc {
        const repository: IEcoFootprintRepository = this.ecoFootprintRepository();
        const usecase = new GetEcoFootprint(repository);
        return new EcoFootprintPageBloc(usecase);
    }

    static authorizedRouteBloc(): AuthorizedRouteBloc {
        const repository = this.authenticationRepository();
        const usecase = new GetAuthenticatedConsumerUseCase(repository);
        return new AuthorizedRouteBloc({ usecase });
    }

    static dateFormatter(): IDateFormatter {
        return new MomentSpanishDateFormatter();
    }
}
export default DependencyInjector;
