import { Failure } from '../../../../../core/domain/failures/failure';

export const registrationInitialState: RegistrationState = {
    type: 'RegistrationDefaultState',
    name: '',
    surname: '',
    identification_number: '',
    email: '',
    username: '',
    password: '',
};

export interface ICommonRegistrationState {
    name: string;
    surname: string;
    identification_number: string;
    email: string;
    username: string;
    password: string;
}

export interface IFillingRegistration {
    type: 'FillingRegistration';
}

export interface ISubmittingRegistration {
    type: 'SubmittingRegistration';
}

export interface IRegistrationSuccessful {
    type: 'RegistrationSuccessful';
}

export interface IFailure {
    type: 'Failure';
    failure: Failure;
}
export interface IRegistrationDefaultState {
    type: 'RegistrationDefaultState';
}

export type RegistrationState = (
    | ISubmittingRegistration
    | IFailure
    | IRegistrationSuccessful
    | IRegistrationDefaultState
    | IFillingRegistration
) &
    ICommonRegistrationState;
