import { Button, CircularProgress, FormHelperText, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { BlocBuilder } from '../../../../../common/bloc';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import FullScreenDialog from '../../../../../core/presentation/components/FullScreenDialog/fullscreen_dialog';
import { Logout } from '../../../../../core/presentation/components/LogoutRedirect/logout_redirect';
import { RoundedContainer } from '../../../../../core/presentation/components/RoundedContainer/rounded_container';
import { usePurchaseBloc, useStoresMapBloc, useWalletBloc } from '../../../../../core/presentation/contexts/contexts';
import useGeoLocation from '../../../../../core/presentation/hooks/useLocation';
import { WalletBloc } from '../../../../wallet/presentation/blocs/wallet_bloc/wallet_bloc';
import SimpleWallet from '../../../../wallet/presentation/components/SimpleWallet/simple_wallet';
import { PurchaseBloc } from '../../blocs/PurchasePageBloc/purchase_bloc';
import { PurchaseState } from '../../blocs/PurchasePageBloc/purchase_states';
import { StoresMapBloc } from '../../blocs/StoresMapBloc/store_map_bloc';
import { PurchaseDetails } from '../../components/PurchaseDetails/purchase_details';
import { AuthorizationFailure } from './../../../../../core/domain/failures/authorization_failure';
import Language from './../../../../../core/presentation/strings/LanguageManager';
import { CouponDetails } from './../../components/CouponDetails/coupon_details';

type PurchasePageProps = {
    onClose?: () => void;
};

export const PurchasePage: React.FC<PurchasePageProps> = ({
    onClose = () => {
        return;
    },
}: PurchasePageProps) => {
    const walletBloc: WalletBloc = useWalletBloc();
    const purchaseBloc: PurchaseBloc = usePurchaseBloc();
    const storesMapBloc: StoresMapBloc = useStoresMapBloc();
    const location = useGeoLocation({ enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 });

    const handleClose = () => {
        purchaseBloc.resetPurchase();
        onClose();
    };
    const handleConfirm = () => {
        purchaseBloc.confirmPurchase();
    };
    const handleExchangeStores = () => {
        if (
            (purchaseBloc.state.type === 'PurchaseCompleted' ||
                purchaseBloc.state.type === 'SubmittingPurchase' ||
                purchaseBloc.state.type === 'PurchaseStarted') &&
            location &&
            location.loaded &&
            location.coordinates &&
            !location.error
        ) {
            const { coupon } = purchaseBloc.state;
            storesMapBloc.open(coupon, location.coordinates);
        }
    };

    useEffect(() => {
        const action = (state: PurchaseState) => {
            if (state.type === 'PurchaseCompleted') walletBloc.getConsumerWallet();
        };
        purchaseBloc.subscribe(action);
        return () => {
            purchaseBloc.unsubscribe(action);
        };
    }, []);

    return (
        <>
            <BlocBuilder
                bloc={purchaseBloc}
                builder={(state: PurchaseState) => {
                    if (state.type === 'Failure' && state.failure instanceof AuthorizationFailure) return <Logout />;

                    const open = state.type !== 'EmptyPurchase';

                    return (
                        <FullScreenDialog
                            open={open}
                            title={Language.strings.coupons_purchase_details}
                            onClose={handleClose}
                        >
                            <>
                                <SimpleWallet />
                                <div style={{ marginBottom: '48px' }}></div>
                                <RoundedContainer
                                    top={200}
                                    maxHeight={true}
                                    title={Language.strings.coupons_coupon}
                                    color="white-transparent"
                                >
                                    <>
                                        {state.type === 'PurchaseStarted' ||
                                        state.type === 'SubmittingPurchase' ||
                                        state.type === 'PurchaseCompleted' ||
                                        state.type === 'Failure' ? (
                                            <CouponDetails coupon={state.coupon} />
                                        ) : null}
                                        <Grid container spacing={2} style={{ marginTop: '0.2rem' }}>
                                            {state.type === 'PurchaseCompleted' ? (
                                                <PurchaseDetails purchase={state.purchase} />
                                            ) : null}
                                            <Grid
                                                item
                                                xs={12}
                                                container
                                                justify="space-between"
                                                style={{
                                                    marginTop: '.5rem',
                                                }}
                                            >
                                                {state.type === 'PurchaseCompleted' ? (
                                                    <Grid item>
                                                        <Button
                                                            fullWidth
                                                            color="secondary"
                                                            variant="outlined"
                                                            onClick={handleClose}
                                                        >
                                                            {Language.strings.back}
                                                        </Button>
                                                    </Grid>
                                                ) : (
                                                    <Grid item>
                                                        {state.type === 'SubmittingPurchase' ? (
                                                            <CircularProgress
                                                                color="primary"
                                                                style={{ margin: '1rem' }}
                                                            />
                                                        ) : (
                                                            <>
                                                                <Button
                                                                    fullWidth
                                                                    color="primary"
                                                                    variant="contained"
                                                                    onClick={handleConfirm}
                                                                >
                                                                    {state.type === 'Failure'
                                                                        ? Language.strings.retry
                                                                        : Language.strings.confirm}
                                                                </Button>
                                                            </>
                                                        )}
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <Button
                                                        fullWidth
                                                        color="secondary"
                                                        variant="outlined"
                                                        onClick={handleExchangeStores}
                                                    >
                                                        {Language.strings.coupons_see_stores}
                                                    </Button>
                                                </Grid>
                                                {state.type === 'Failure' &&
                                                state.failure instanceof ValidationFailure ? (
                                                    <Grid item xs={12}>
                                                        <FormHelperText
                                                            error
                                                            style={{ textAlign: 'center', marginTop: '1rem' }}
                                                        >
                                                            {Language.getErrorString(
                                                                'coupons',
                                                                'createPurchase',
                                                                state,
                                                            )}
                                                        </FormHelperText>
                                                    </Grid>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    </>
                                </RoundedContainer>
                            </>
                        </FullScreenDialog>
                    );
                }}
            ></BlocBuilder>
        </>
    );
};
