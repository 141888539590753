/* istanbul ignore file */

import { IFailure } from '../../core/domain/types/failure_interface';
export class FailureLogger {
    static log(failure: IFailure) {
        if (process.env.REACT_APP_FAILURE_LOG === 'true' && process.env.REACT_APP_ENV === 'development') {
            console.log(failure);
        }
    }
}
