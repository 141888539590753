import { BlocBuilder } from '../../../../../common/bloc';
import { BrowserPageBloc } from '../../blocs/BrowserPageBloc/browser_page_bloc';
import { BrowserPageState } from '../../blocs/BrowserPageBloc/browser_page_states';
import { CouponBrowser } from '../../components/CouponBrowser/coupon_browser';
import FullScreenDialog from '../../../../../core/presentation/components/FullScreenDialog/fullscreen_dialog';
import Language from './../../../../../core/presentation/strings/LanguageManager';
import React from 'react';
import SimpleWallet from '../../../../wallet/presentation/components/SimpleWallet/simple_wallet';
import di from '../../../../../common/di/di';
import { useBrowserPageBloc } from '../../../../../core/presentation/contexts/contexts';

export const BrowserPage = () => {
    const browserBloc: BrowserPageBloc = useBrowserPageBloc();
    const couponsBrowserBloc = di.couponBrowserBloc(20);
    return (
        <BlocBuilder
            bloc={browserBloc}
            builder={(state: BrowserPageState) => {
                return (
                    <FullScreenDialog
                        open={state.open}
                        title={Language.strings.coupons_purchase}
                        onClose={(e) => {
                            browserBloc.close();
                        }}
                    >
                        <>
                            <SimpleWallet />

                            <CouponBrowser
                                couponsBrowserBloc={couponsBrowserBloc}
                                withSearchBox={true}
                                browserContainerTop={221}
                                title="Cupones"
                                context="browser"
                            />
                        </>
                    </FullScreenDialog>
                );
            }}
        />
    );
};
