import { Coordinates } from '../../../../core/domain/types/Coordinates';
import { IModel } from '../../../../core/domain/types/model_interface';

export type StoreArgs = {
    address: string;
    coordinates: Coordinates;
    name: string;
    phoneNumber: string;
};

export class Store implements IModel {
    type = 'StoreModel';
    address: string;
    name: string;
    coordinates: Coordinates;
    phoneNumber: string;

    constructor({ address, name, coordinates, phoneNumber }: StoreArgs) {
        this.address = address;
        this.name = name;
        this.coordinates = coordinates;
        this.phoneNumber = phoneNumber;
    }
}
