import { Grid, IconButton, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import HelpOutline from '@material-ui/icons/HelpOutline';
import { InfoModal } from '../modal/modal';
import Language from '../../strings/LanguageManager';

type InputHelperProps = {
    message: string;
    icon?: React.ReactElement;
    children: React.ReactElement;
    className?: string;
    dialogClassName?: string;
};
export const InputHelper = ({ message, icon, children, className, dialogClassName }: InputHelperProps) => {
    const [open, setOpen] = useState(false);
    return (
        <Grid container className={className}>
            {React.cloneElement(children as React.ReactElement<any>)}
            <IconButton
                aria-label="help"
                onClick={() => {
                    setOpen(true);
                }}
            >
                {icon || <HelpOutline />}
            </IconButton>
            <InfoModal
                title={Language.strings.help}
                open={open}
                content={
                    <Typography style={{ whiteSpace: 'pre-wrap' }} color="textPrimary">
                        {message}
                    </Typography>
                }
                actions={<></>}
                onClose={() => setOpen(false)}
                className={dialogClassName}
            />
        </Grid>
    );
};
