import _ from 'lodash';
import { CacheError } from '../../../../core/data/errors/cache_error';
import { IAuthenticationCacheDataSource } from '../../../../core/data/types/authentication_cache_datasource_interface';
import { ConsumerEntity } from '../entities/consumer_entity';

export class SessionStorageCacheDataSource implements IAuthenticationCacheDataSource {
    async isAuthenticated(): Promise<boolean> {
        try {
            const result = await this.getToken();
            return Promise.resolve(!_.isNil(result) && !_.isEmpty(result));
        } catch (error) {
            return Promise.resolve(false);
        }
    }
    async cacheConsumer(consumer: ConsumerEntity): Promise<void> {
        try {
            await sessionStorage.setItem('user', JSON.stringify(consumer));
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(new CacheError(error.message));
        }
    }
    async cacheToken(token: string): Promise<void> {
        try {
            await sessionStorage.setItem('token', token);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(new CacheError(error.message));
        }
    }
    async getConsumer(): Promise<ConsumerEntity> {
        try {
            const json: string | null = await sessionStorage.getItem('user');
            return Promise.resolve(ConsumerEntity.fromObject(JSON.parse(json as string)));
        } catch (error) {
            return Promise.reject(new CacheError(error.message));
        }
    }
    async getToken(): Promise<string> {
        try {
            const result: string | null = await sessionStorage.getItem('token');
            return Promise.resolve(result as string);
        } catch (error) {
            return Promise.reject(new CacheError(error.message));
        }
    }
}
