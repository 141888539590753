import { FailureLogger } from '../../../../common/utils/FailureLogger';
import { IAuthenticationCacheDataSource } from '../../../../core/data/types/authentication_cache_datasource_interface';
import { AuthorizationFailure } from '../../../../core/domain/failures/authorization_failure';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { Residue } from '../../domain/models/residue';
import { IResiduesRepository } from '../../domain/repositories/residues_repository_interface';
import { GetResiduesUseCaseParams } from '../../domain/usecases/get_residues_use_case';
import { IResiduesDataSource } from '../datasources/types/residues_datasource_interface';
import { ResidueEntity } from '../entities/residues_entity';
import { errorToFailure } from './../../../../core/data/utils/error_to_failure';

type ResiduesRepositoryParams = {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: IResiduesDataSource;
};

export class ResiduesRepository implements IResiduesRepository {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: IResiduesDataSource;

    constructor({ authCacheDataSource, apiDataSource }: ResiduesRepositoryParams) {
        this.apiDataSource = apiDataSource;
        this.authCacheDataSource = authCacheDataSource;
    }

    async getResidues(params: GetResiduesUseCaseParams): Promise<Residue[] | IFailure> {
        try {
            const isAuthorized = await this.authCacheDataSource.isAuthenticated();
            if (isAuthorized) {
                const result = await this.apiDataSource.getResidues(params);
                return Promise.resolve((result as ResidueEntity[]).map((item: ResidueEntity) => item.toModel()));
            }
            const failure: IFailure = new AuthorizationFailure();
            FailureLogger.log(failure);
            return Promise.resolve(failure);
        } catch (error) {
            return Promise.resolve(errorToFailure<null>(error));
        }
    }
}
