import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { Consumer } from '../../../../core/domain/models/consumer_model';
import { IConsumer } from '../../../../core/domain/types/consumer_interface';
import { IEntity } from '../../../../core/domain/types/entity_interface';
import { areAnyNil } from './../../../../core/data/utils/areAnyNil';

interface ConsumerEntityArgs extends IConsumer {
    id: number;
}
export class ConsumerEntity implements IConsumer, IEntity {
    id: number;
    username: string;
    name: string;
    surname: string;
    identification_number: number;
    email: string;
    image?: string;
    code: string;

    constructor({ id, username, name, surname, identification_number, email, image, code }: ConsumerEntityArgs) {
        this.id = id;
        this.username = username;
        this.name = name;
        this.surname = surname;
        this.identification_number = identification_number;
        this.email = email;
        this.image = image;
        this.code = code;
    }
    toModel(): Consumer {
        return new Consumer({
            username: this.username,
            name: this.name,
            surname: this.surname,
            identification_number: this.identification_number,
            email: this.email,
            image: this.image,
            code: this.code,
        });
    }
    static fromObject(obj: any): ConsumerEntity {
        try {
            const { id, username, name, surname, identification_number, email, image, code } = obj;
            if (areAnyNil([id, username, name, surname, identification_number, email, code])) throw new Error();
            return new ConsumerEntity({ id, username, name, surname, identification_number, email, image, code });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'ConsumerEntity');
        }
    }
}
