export interface EcopointStrings {
    ecopoint_ecopoints: string;
    ecopoint_where_to_validate: string;
    ecopoint_address: string;
    ecopoint_schedules: string;
    ecopoint_residue_types: string;
}

export const ecopointStrings: EcopointStrings = {
    ecopoint_ecopoints: 'EcoPuntos',
    ecopoint_where_to_validate: 'DONDE VALIDAR',
    ecopoint_address: 'Dirección',
    ecopoint_schedules: 'Horarios',
    ecopoint_residue_types: 'Tipos de Residuos permitidos',
};
