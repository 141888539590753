import { CollectionPageState, initialState } from './collection_page_bloc_states';

import { CreateCollectionUseCase } from '../../../domain/usecases/create_collection_use_case';
import { Failure } from '../../../../../core/domain/failures/failure';
import { ModalBloc } from './../../../../../core/presentation/blocs/modal/modal_bloc';
import { Residue } from '../../../domain/models/residue';

export class CollectionPageBloc extends ModalBloc<CollectionPageState> {
    usecase: CreateCollectionUseCase;
    constructor(usecase: CreateCollectionUseCase) {
        super(initialState);
        this.usecase = usecase;
    }
    async selectResidue(residue: Residue): Promise<void> {
        this.changeState({ ...this.state, residue });
    }

    async createCollection(): Promise<void> {
        const { open, block, residue, count } = this.state;
        this.changeState({ open, block, residue, count, type: 'SubmittingCollection' });
        const result = await this.usecase.execute({ residue: this.state.residue as Residue, count: this.state.count });

        if (result instanceof Failure) {
            this.changeState({ open, block, residue, count, type: 'Failure', failure: result });
            return;
        }
        this.changeState({ open, block, residue, count, type: 'CollectionSuccessful' });
        return;
    }

    async changeCount(count: number): Promise<void> {
        const { open, block, residue } = this.state;
        if (count >= 1) {
            this.changeState({ open, block, residue, type: 'CountChanged', count });
        }
    }
    async close(): Promise<void> {
        this.changeState(initialState);
        super.close();
    }
}
