export class ValidationError<T> extends Error {
    protected _fails: any;

    constructor(fails: any, message?: string) {
        super(`ValidationError Error - ${message || 'no message'}`);
        Object.setPrototypeOf(this, ValidationError.prototype);
        console.log(this.message);
        const aux = fails;
        Object.keys(fails).forEach((e) => {
            if (fails[e] !== undefined) {
                aux[e] = fails[e][0];
            }
        });
        this._fails = aux;
    }

    get fails(): T {
        return this._fails as T;
    }
}
