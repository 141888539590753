import { AxiosInstance } from 'axios';
import { IAxiosDataSource } from '../../../../core/data/types/axios_datasource_interface';
import { httpToError } from '../../../../core/data/utils/httpToError';
import { Coordinates } from '../../../../core/domain/types/Coordinates';
import { EcoPointEntity } from '../entities/ecopoint_entity';
import { IEcoPointsDataSource } from './types/ecopoints_data_source_interface';

export class AxiosEcoPointsDataSource implements IAxiosDataSource, IEcoPointsDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async getEcoPoints(coordinates: Coordinates): Promise<EcoPointEntity[]> {
        try {
            const { data } = await this.axiosInstance.get(`ecopoints`, {
                params: { latitude: coordinates.lat, longitude: coordinates.lng },
            });
            return Promise.resolve((data as Array<any>).map((item: any) => EcoPointEntity.fromObject(item)));
        } catch (error) {
            return Promise.reject(httpToError<null>(error));
        }
    }
}
