import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import React, { memo } from 'react';

import { useStyles } from './styles';

type WalletCardProps = {
    title?: string;
    balance?: string;
    helperText?: string;
    backgroundColor?: string;
    primaryButton?: React.ReactNode;
    secondaryButton?: React.ReactNode;
    loading: boolean;
};
export const WalletCard = memo((props: WalletCardProps) => {
    const { title, balance, helperText, backgroundColor, primaryButton, secondaryButton, loading = false } = props;
    const classes = useStyles({ backgroundColor });
    return (
        <Paper className={classes.paper}>
            <Grid container alignItems="center">
                <Grid item xs={6}>
                    <Typography color="textPrimary" className={classes.card_title}>
                        {title}
                    </Typography>
                    {loading ? (
                        <CircularProgress style={{ margin: '1rem' }} />
                    ) : (
                        <Typography color="textPrimary" className={classes.balance}>
                            {balance} pts.
                        </Typography>
                    )}
                    <Typography color="textPrimary" className={classes.helper_text}>
                        {helperText}
                    </Typography>
                </Grid>
                <Grid container item xs={6} alignItems="center">
                    <Grid item xs={12} className={classes.button}>
                        {primaryButton}
                    </Grid>
                    <Grid item xs={12} className={classes.button}>
                        {secondaryButton}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
});
