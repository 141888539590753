import { Failure } from '../../../../core/domain/failures/failure';
import { EcoPoint } from '../../domain/models/ecopoint';

export interface IEcoPointsMapClosed {
    type: 'EcoPointsMapClosed';
}
export interface IEcoPointsMapLoading {
    type: 'EcoPointsMapLoading';
}
export interface IEcoPointsMapLoaded {
    type: 'EcoPointsMapLoaded';
    ecoPoints: EcoPoint[];
}
export interface IFailure {
    type: 'Failure';
    failure: Failure;
}

export type EcoPointsMapState = IEcoPointsMapClosed | IEcoPointsMapLoading | IEcoPointsMapLoaded | IFailure;

export const initialState: EcoPointsMapState = { type: 'EcoPointsMapClosed' };
