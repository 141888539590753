import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        paper: {
            borderRadius: '10px',
            minHeight: '220px',
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
        },
        image_viewport: {
            position: 'relative',
            paddingTop: '75%',
            width: '100%',
            borderBottom: `1px solid ${theme.colors.greyTransparent}`,
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            position: 'absolute',
            top: 0,
        },
        info: {
            padding: theme.spacing(1),
            alignItems: 'center',
            flexGrow: 1,
        },
        title: {
            fontSize: '0.8rem',
            fontWeight: 'lighter',
            paddingBottom: '0.25rem',
        },
        price: {
            fontWeight: 'bolder',
            fontSize: '1.2rem',
            paddingBottom: '0.25rem',
        },
    });
});
