import { Coordinates } from '../../../../core/domain/types/Coordinates';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { EcoPoint } from '../models/ecopoint';
import { IEcoPointsRepository } from '../repositories/ecopoints_repository_interface';

export class GetEcoPointsUseCase implements IUseCase<EcoPoint[], Coordinates> {
    repository: IEcoPointsRepository;

    constructor(repository: IEcoPointsRepository) {
        this.repository = repository;
    }

    async execute(coordinates: Coordinates): Promise<EcoPoint[] | IFailure> {
        return await this.repository.getEcoPoints(coordinates);
    }
}
