import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const media = theme.breakpoints.up('sm');

    return createStyles({
        text: {
            lineHeight: 1.25,
        },
        greeting: {
            color: '#F2C6A1',
        },
        name: {
            fontWeight: 'bold',
        },
        avatar: {
            width: '50px',
            height: '50px',
        },
    });
});
