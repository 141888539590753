import { Failure } from '../../../../../core/domain/failures/failure';
import { ModalState } from '../../../../../core/presentation/blocs/modal/modal_states';
import { ResidueType } from '../../../../residue_types/domain/models/residue_type';
import { Residue } from '../../../domain/models/residue';

export interface ICommonState {
    query?: string;
    page: number;
    hasMore: boolean;
    residues: Residue[];
    showInfo: boolean;
    residueTypes: ResidueType[];
}
export interface IIdle {
    type: 'Idle';
}

export interface IReloadingResiduesTypes {
    type: 'LoadingResiduesType';
}

export interface IResiduesTypesLoaded {
    type: 'ResiduesTypesLoaded';
}

export interface IReloadingResidues {
    type: 'ReloadingResidues';
}

export interface IResiduesLoaded {
    type: 'ResiduesLoaded';
}

export interface IQueryChanged {
    type: 'QueryChanged';
}

export interface IFailure {
    type: 'Failure';
    failure: Failure;
}

export type ResiduesPageState = (
    | IIdle
    | IReloadingResiduesTypes
    | IResiduesTypesLoaded
    | IQueryChanged
    | IResiduesLoaded
    | IFailure
    | IReloadingResidues
) &
    ICommonState &
    ModalState;

export const initialState: ResiduesPageState = {
    type: 'Idle',
    open: false,
    block: false,
    page: 0,
    hasMore: false,
    residues: [],
    residueTypes: [],
    showInfo: false,
};
