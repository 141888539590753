import _ from 'lodash';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { areAnyNil } from '../../../../core/data/utils/areAnyNil';
import { IEntity } from '../../../../core/domain/types/entity_interface';
import { Purchase } from '../../domain/models/purchase';
import { CouponEntity } from './coupon_entity';
import { StoreEntity } from './store_entity';

type PurchaseEntityArgs = {
    id: number;
    coupon: CouponEntity;
    price: number;
    purchaseDate: string;
    exchangeDate?: string;
    exchangeStore?: StoreEntity;
    status: 'pending' | 'exchanged' | 'expired';
    code: string;
};

export class PurchaseEntity implements IEntity {
    id: number;
    coupon: CouponEntity;
    price: number;
    purchaseDate: string;
    exchangeDate?: string;
    exchangeStore?: StoreEntity;
    status: 'pending' | 'exchanged' | 'expired';
    code: string;

    constructor({ id, coupon, price, purchaseDate, exchangeDate, exchangeStore, status, code }: PurchaseEntityArgs) {
        this.id = id;
        this.coupon = coupon;
        this.price = price;
        this.purchaseDate = purchaseDate;
        this.exchangeDate = exchangeDate;
        this.exchangeStore = exchangeStore;
        this.status = status;
        this.code = code;
    }

    toModel(): Purchase {
        const purchase = new Purchase({
            id: this.id,
            price: this.price,
            purchaseDate: this.purchaseDate,
            exchangeDate: this.exchangeDate,
            coupon: this.coupon.toModel(),
            exchangeStore: this.exchangeStore?.toModel(),
            status: this.status,
            code: this.code,
        });
        return purchase;
    }

    static fromObject(obj: any): PurchaseEntity {
        try {
            const { id, coupon, price, date, status, code, exchange_date, exchange_store } = obj;
            if (areAnyNil([id, coupon, price, date, status, code])) {
                throw new Error();
            }
            return new PurchaseEntity({
                id,
                coupon: CouponEntity.fromObject(coupon),
                price,
                purchaseDate: date.date,
                exchangeDate: exchange_date?.date,
                exchangeStore: _.isNil(exchange_store) ? undefined : StoreEntity.fromObject(exchange_store),
                status,
                code,
            });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'PurchaseEntity');
        }
    }
}
