import { Bloc } from '../../../../../common/bloc';
import { Failure } from '../../../../../core/domain/failures/failure';
import { RequestRecovery } from '../../../domain/usecases/request_recovery';
import { initialState, RecoveryRequestState } from './recovery_request_states';

export class RecoveryRequestBloc extends Bloc<RecoveryRequestState> {
    usecase: RequestRecovery;
    constructor(usecase: RequestRecovery) {
        super(initialState);
        this.usecase = usecase;
    }

    async submitRequest(): Promise<void> {
        this.changeState({ ...this.state, type: 'SubmittingRequest' });
        const result = await this.usecase.execute(this.state.email);
        if (result instanceof Failure) {
            this.changeState({ ...this.state, type: 'Failure', failure: result });
            return;
        }
        this.changeState({ ...this.state, type: 'RequestSubmitted' });
    }

    async changeEmail(email: string): Promise<void> {
        this.changeState({ ...this.state, type: 'EmailChanged', email });
    }
}
