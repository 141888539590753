export interface CollectionStrings {
    collection_type: string;
    collection_value: string;
    collection_count: string;
    collection_congrats: string;
    collection_title: string;
    collection_subtitle: string;
    collection_info: (value: number) => string;
    collection_validated_current_balance: string;
    collection_validated_pending_balance: string;
    collection_validated_final_balance: string;
    collection_validated_amount: string;
    collection_validated_residue: string;
    collection_collected_residue: string;
    collection_validated_value: string;
    collection_validated_no_results: string;
    collection_selected_residue: string;
    collection_collect: string;
    collection_validated_title: string;
    collection_validated_ecopoints: string;
}

export const collectionStrings: CollectionStrings = {
    collection_type: 'Tipo',
    collection_value: 'Puntos',
    collection_count: 'Cantidad',
    collection_congrats: '¡Felicitaciones!',
    collection_title: 'RECOLECTAR RESIDUOS',
    collection_subtitle: 'Acabás de reciclar',
    collection_info: (value) => `Se sumarán ${value} pts. a tu cuenta!`,
    collection_validated_current_balance: 'Pts. Disponibles',
    collection_validated_pending_balance: 'Pts. a Validar',
    collection_validated_final_balance: 'Pts. Finales',
    collection_validated_amount: 'Cant.',
    collection_validated_residue: 'Material',
    collection_validated_value: 'Puntos',
    collection_validated_no_results: 'No se han encontrado resultados... :(',
    collection_selected_residue: 'Material Seleccionado',
    collection_collected_residue: 'Material Recolectado',
    collection_collect: 'RECOLECTAR',
    collection_validated_title: 'VALIDACIONES',
    collection_validated_ecopoints: 'Ver Ecopuntos',
};
