import { Consumer } from '../../domain/models/consumer_model';

export interface ILoadingAuhtorizedConsumer {
    type: 'LoadingAuthorizedConsumer';
}

export interface IAuthorizedConsumerLoaded {
    type: 'AuthorizedConsumerLoaded';
    consumer: Consumer;
}

export interface IAuthorizedConsumerFailed {
    type: 'AuthorizedConsumerFailed';
}

export type AuthorizedRouteState = ILoadingAuhtorizedConsumer | IAuthorizedConsumerLoaded | IAuthorizedConsumerFailed;

export const athorizedRouteDefaultState: AuthorizedRouteState = {
    type: 'LoadingAuthorizedConsumer',
};
