import { Bloc } from '../../../../common/bloc';
import { Failure } from '../../../../core/domain/failures/failure';
import { Coordinates } from '../../../../core/domain/types/Coordinates';
import { EcoPoint } from '../../domain/models/ecopoint';
import { GetEcoPointsUseCase } from '../../domain/usecases/get_ecopoints_use_case';
import { EcoPointsMapState, initialState } from './ecopoints_states';

export class EcoPointsBloc extends Bloc<EcoPointsMapState> {
    usecase: GetEcoPointsUseCase;
    constructor(usecase: GetEcoPointsUseCase) {
        super(initialState);
        this.usecase = usecase;
    }

    async open(coordinates: Coordinates): Promise<void> {
        this.changeState({ type: 'EcoPointsMapLoading' });
        const result = await this.usecase.execute(coordinates);
        if (result instanceof Failure) {
            this.changeState({
                type: 'Failure',
                failure: result,
            });
            return;
        }
        this.changeState({ type: 'EcoPointsMapLoaded', ecoPoints: result as EcoPoint[] });
    }
    async close(): Promise<void> {
        this.changeState({ type: 'EcoPointsMapClosed' });
    }
}
