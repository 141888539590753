import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        paper: {
            borderRadius: '10px',
            minHeight: '320px',
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '250px',
            margin: '0 auto',
        },
        image_viewport: {
            height: '200px',
            borderBottom: `1px solid ${theme.colors.greyTransparent}`,
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        },
        info: {
            padding: theme.spacing(1),
            alignItems: 'center',
            flexGrow: 1,
            backgroundColor: theme.palette.secondary.main,
        },
        title: {
            fontWeight: 'bolder',
            fontSize: '1.2rem',
            paddingBottom: '0.25rem',
            color: theme.palette.text.secondary,
        },
        subtitle: {
            fontSize: '0.8rem',
            fontWeight: 'bold',
            paddingBottom: '0.25rem',
            color: theme.palette.text.secondary,
        },
        count: {
            padding: '0 1rem',
            marginTop: '1rem',
            marginBottom: '1rem',
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
                padding: '0',
            },
            ['& .MuiInputBase-root']: {
                color: theme.palette.primary.main,
                borderRadius: '10px 10px 0 0',
                backgroundColor: '#FFFFFF26',
                ['& input']: {
                    textAlign: 'center',
                },
            },
        },
    });
});
