import { Bloc } from '../../../../../common/bloc';
import { Failure } from '../../../../../core/domain/failures/failure';
import { Purchase } from '../../../domain/models/purchase';
import { GetConsumerPurchasesUseCase } from '../../../domain/usecases/get_consumer_purchases';
import { initialState, PurchasesPageState } from './purchases_page_bloc_states';

export class PurchasesPageBloc extends Bloc<PurchasesPageState> {
    usecase: GetConsumerPurchasesUseCase;
    constructor(usecase: GetConsumerPurchasesUseCase) {
        super(initialState);
        this.usecase = usecase;
    }

    async open(): Promise<void> {
        this.changeState({ type: 'LoadingPurchases', open: true });
        const result = await this.usecase.execute();
        if (result instanceof Failure) {
            this.changeState({ type: 'Failure', failure: result, open: false });
            return;
        }
        const pending = (result as Array<Purchase>).filter((purchase: Purchase) => purchase.status === 'pending');
        const expired = (result as Array<Purchase>).filter((purchase: Purchase) => purchase.status === 'expired');
        const exchanged = (result as Array<Purchase>).filter((purchase: Purchase) => purchase.status === 'exchanged');

        this.changeState({ type: 'PurchasesLoaded', open: true, pending, expired, exchanged });
    }
    async close(): Promise<void> {
        this.changeState({ type: 'PageClosed', open: false });
    }
}
