import { FailureLogger } from '../../../../common/utils/FailureLogger';
import { CacheError } from '../../../../core/data/errors/cache_error';
import { IAuthenticationCacheDataSource } from '../../../../core/data/types/authentication_cache_datasource_interface';
import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { AuthorizationFailure } from '../../../../core/domain/failures/authorization_failure';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { ResidueType } from '../../domain/models/residue_type';
import { IResidueTypeRepository } from '../../domain/repositories/residue_type_repository_interface';
import { IResidueTypeDataSource } from '../datasources/types/residue_type_datasource_interface';
import { ResidueTypeEntity } from '../entities/residue_type_entity';

type ResidueTypeRepositoryParams = {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: IResidueTypeDataSource;
};

export class ResidueTypeRepository implements IResidueTypeRepository {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: IResidueTypeDataSource;

    constructor({ authCacheDataSource, apiDataSource }: ResidueTypeRepositoryParams) {
        this.apiDataSource = apiDataSource;
        this.authCacheDataSource = authCacheDataSource;
    }

    async getResidueTypes(): Promise<ResidueType[] | IFailure> {
        try {
            const isAuthenticated = await this.authCacheDataSource.isAuthenticated();
            if (!isAuthenticated) {
                const failure = new AuthorizationFailure();
                FailureLogger.log(failure);
                return failure;
            }
            const result = await this.apiDataSource.getResidueTypes();
            return Promise.resolve((result as ResidueTypeEntity[]).map((type) => type.toModel()));
        } catch (error) {
            if (error instanceof CacheError) return Promise.resolve(new AuthorizationFailure());
            return Promise.resolve(errorToFailure<null>(error));
        }
    }
}
