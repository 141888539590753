import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import couponImg from '../../../../../assets/images/coupon.png';
import di from '../../../../../common/di/di';
import { Coupon } from '../../../domain/models/coupon';
import Language from './../../../../../core/presentation/strings/LanguageManager';
import { useStyles } from './styles';

const server_path = process.env.REACT_APP_SELLER_SERVER_URL;

type CouponDetailsProps = {
    coupon: Coupon;
};
export function CouponDetails({ coupon }: CouponDetailsProps) {
    const classes = useStyles();
    const dateFormatter = di.dateFormatter();
    return (
        <>
            <div className={classes.image_viewport}>
                <img className={classes.image} src={coupon.image ? `${server_path}${coupon.image}` : `${couponImg}`} />{' '}
            </div>
            <Grid container className={classes.info}>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.title}>
                        {coupon.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.price}>
                        {`$${coupon.price}`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.terms}>
                        {coupon.terms}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.terms}>
                        {`${Language.strings.coupons_valid_until} ${dateFormatter.format(
                            'DD/MM/YYYY',
                            coupon.finishDate,
                        )}`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.terms}>
                        {`${Language.strings.coupons_stock}: ${coupon.stock}`}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
