import { Consumer } from '../../../../core/domain/models/consumer_model';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { IAuthenticationRepository } from '../repositories/IAuthenticationRepository';

export type UpdateProfileUseCaseParams = {
    image?: File;
    name: string;
    surname: string;
    email: string;
    identification_number: number;
};

export class UpdateProfileUseCase implements IUseCase<Consumer, UpdateProfileUseCaseParams> {
    repository: IAuthenticationRepository;

    constructor(repository: IAuthenticationRepository) {
        this.repository = repository;
    }

    execute({
        image,
        name,
        surname,
        email,
        identification_number,
    }: UpdateProfileUseCaseParams): Promise<Consumer | IFailure> {
        return this.repository.updateProfile({
            image,
            name,
            surname,
            email,
            identification_number,
        });
    }
}
