import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import React, { useState } from 'react';
import di from '../../../common/di/di';
import { Routes } from '../routes/Routes';
import createLightTheme from '../themes/light_theme';

const version = process.env.REACT_APP_VERSION;
function App(): JSX.Element {
    const [dependenciesLoaded, setDependenciesLoaded] = useState(false);
    di.loadAsyncDependencies().then(() => {
        setDependenciesLoaded(true);
    });
    console.log(`Ecobuy v ${version}`);
    if (dependenciesLoaded) {
        console.log('Async Dependencies Loaded');
        return (
            <div className="App" style={{ overflowX: 'hidden', minHeight: '100vh' }}>
                <ThemeProvider theme={createLightTheme()}>
                    <SnackbarProvider maxSnack={3} dense={true} preventDuplicate={true}>
                        <Routes />
                    </SnackbarProvider>
                </ThemeProvider>
            </div>
        );
    } else {
        console.log('Loading Async Dependencies');
        return <></>;
    }
}

export default App;
