import { useEffect, useState } from 'react';

type GeoLocationState = {
    loaded: boolean;
    coordinates?: { lat: string; lng: string };
    error?: GeoLocationError;
};

type GeoLocationError = {
    code: number;
    message: string;
};

const useGeoLocation = (options?: PositionOptions) => {
    const [location, setLocation] = useState<GeoLocationState>({
        loaded: false,
        coordinates: undefined,
        error: undefined,
    });

    const onError = (error: GeoLocationError) => {
        setLocation({
            loaded: true,
            coordinates: undefined,
            error: { code: error.code, message: error.message },
        });
    };

    useEffect(() => {
        if (!('geolocation' in navigator)) {
            onError({
                code: 0,
                message: 'Geolocation not supported',
            });
        }
        navigator.geolocation.getCurrentPosition(
            (location) => {
                setLocation({
                    loaded: true,
                    coordinates: {
                        lat: location.coords.latitude.toString(),
                        lng: location.coords.longitude.toString(),
                    },
                    error: undefined,
                });
            },
            onError,
            options,
        );
    }, []);

    return location;
};

export default useGeoLocation;
