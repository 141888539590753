import { IconButton } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import React from 'react';
import { useStyles } from './style';

type ActionbuttonProps = {
    onClick?(event: React.MouseEvent): void;
    icon?: React.ReactElement;
};

const ActionButton: React.FC<ActionbuttonProps> = ({ onClick, icon }: ActionbuttonProps) => {
    const classes = useStyles();

    return (
        <IconButton onClick={onClick} className={classes.raised_button}>
            {icon ? icon : <AddIcon />}
        </IconButton>
    );
};

export default React.memo(ActionButton);
