import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { areAnyNil } from '../../../../core/data/utils/areAnyNil';
import { IEntity } from '../../../../core/domain/types/entity_interface';
import { Collection } from '../../domain/models/collection';
import { Residue } from '../../domain/models/residue';
import { ResidueEntity } from './residues_entity';

export type CollectionEntityArgs = {
    id: number;
    residue: ResidueEntity;
    date: string;
    count: number;
    value: number;
};

export class CollectionEntity implements IEntity {
    id: number;
    residue: ResidueEntity;
    date: string;
    count: number;
    value: number;

    constructor({ id, residue, date, count, value }: CollectionEntityArgs) {
        this.id = id;
        this.residue = residue;
        this.date = date;
        this.count = count;
        this.value = value;
    }

    toModel(): Collection {
        return new Collection({
            residue: new Residue(this.residue),
            date: this.date,
            count: this.count,
            value: this.value,
        });
    }

    static fromObject(obj: any): CollectionEntity {
        try {
            const { id, residue, date, count, amount } = obj;
            if (areAnyNil([id, residue, date, date?.date, count, amount])) {
                throw new Error();
            }
            return new CollectionEntity({
                id,
                residue: ResidueEntity.fromObject(residue),
                date: date.date,
                count,
                value: amount,
            });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'CollectionEntity');
        }
    }
}
