import { AxiosInstance } from 'axios';
import {
    IAuthenticationDataSource,
    UpdateProfileParams,
} from '../../../../core/data/types/authentication_datasource_interface';
import { IAuthenticationResponse } from '../../../../core/data/types/authentication_response_interface';
import { IAxiosDataSource } from '../../../../core/data/types/axios_datasource_interface';
import { httpToError } from '../../../../core/data/utils/httpToError';
import { AuthenticationParams } from '../../domain/usecases/authenticate_consumer';
import { RecoverPasswordParams } from '../../domain/usecases/recover_password';
import { RegistrationParams } from '../../domain/usecases/register_consumer';
import { ConsumerEntity } from '../entities/consumer_entity';
import { LoginValidations } from '../validations/authenticate_validations';
import { RecoverPasswordValidations } from '../validations/recover_password_validations';
import { RegisterValidations } from '../validations/register_validations';
import { UpdateProfileValidations } from '../validations/update_profile_validations';
import { RequestRecoveryValidations } from './../validations/request_recovery_validations';

type AxiosAuthenticationDataSourceArgs = {
    axiosInstance: AxiosInstance;
};

export class AxiosAuthenticationDataSource implements IAxiosDataSource, IAuthenticationDataSource {
    axiosInstance: AxiosInstance;

    constructor({ axiosInstance }: AxiosAuthenticationDataSourceArgs) {
        this.axiosInstance = axiosInstance;
    }

    async register(params: RegistrationParams): Promise<boolean> {
        try {
            const response = await this.axiosInstance.post(`consumers`, params);
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(httpToError<RegisterValidations>(error));
        }
    }

    async authenticate(params: AuthenticationParams): Promise<IAuthenticationResponse> {
        try {
            const { data } = await this.axiosInstance.post('consumers/login', params);
            const token = data.token;
            this.axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
            const user: ConsumerEntity = ConsumerEntity.fromObject(data.user);
            return Promise.resolve({ token, user } as IAuthenticationResponse);
        } catch (error) {
            return Promise.reject(httpToError<LoginValidations>(error));
        }
    }

    async requestRecovery(email: string): Promise<void> {
        try {
            await this.axiosInstance.post('consumers/password-reset-request', { email: email });
            return;
        } catch (error) {
            return Promise.reject(httpToError<RequestRecoveryValidations>(error));
        }
    }

    async recoverPassword({ token, password }: RecoverPasswordParams): Promise<void> {
        try {
            await this.axiosInstance.post('consumers/password-reset-confirm', { token, password });
            return;
        } catch (error) {
            return Promise.reject(httpToError<RecoverPasswordValidations>(error));
        }
    }

    async updateProfile({
        image,
        name,
        surname,
        email,
        identification_number,
    }: UpdateProfileParams): Promise<ConsumerEntity> {
        try {
            const formData = new FormData();
            if (image) formData.append('image', image);
            formData.append('name', name);
            formData.append('surname', surname);
            formData.append('email', email);
            formData.append('_method', 'put');
            formData.append('identification_number', identification_number.toString());
            const { data } = await this.axiosInstance.post(`consumers`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });

            return Promise.resolve(ConsumerEntity.fromObject(data));
        } catch (error) {
            return Promise.reject(httpToError<UpdateProfileValidations>(error));
        }
    }
}
