import { Theme, createStyles, makeStyles } from '@material-ui/core';

interface PaperProps {
    backgroundColor: string | undefined;
}

export const useStyles = makeStyles((theme: Theme) => {
    const media = theme.breakpoints.up('sm');

    return createStyles({
        container: {
            marginBottom: '15px',
            padding: '0 1.5rem',
            '& .MuiGrid-container': {
                justifyContent: 'flex-start',
                [theme.breakpoints.up('sm')]: {
                    justifyContent: 'flex-end',
                },
            },
            '& > .MuiGrid-item > .MuiTypography-root': {
                textAlign: 'left',
                [theme.breakpoints.up('sm')]: {
                    textAlign: 'right',
                },
            },
        },
        helper: {
            '& button': {
                color: theme.palette.text.secondary,
                padding: '0 0 0 .5rem',
            },
            '& .MuiDialogContent-root p': {
                color: theme.palette.text.primary,
            },
        },
        code: {
            fontWeight: 'bold',
            fontSize: '2rem',
        },
    });
});
