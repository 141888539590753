import { Failure } from './failure';

export class ValidationFailure<T> extends Failure {
    fails: T;

    constructor(fails: T, message?: string) {
        super('ValidationFailure', message);
        this.fails = fails;
        this.log();
    }
    log(): void {
        console.log(`${this._type} due to ${this.message}`, { fails: this.fails });
    }
}
