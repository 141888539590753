import { FailureLogger } from '../../../../common/utils/FailureLogger';
import { IAuthenticationCacheDataSource } from '../../../../core/data/types/authentication_cache_datasource_interface';
import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { AuthorizationFailure } from '../../../../core/domain/failures/authorization_failure';
import { Coordinates } from '../../../../core/domain/types/Coordinates';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { EcoPoint } from '../../domain/models/ecopoint';
import { IEcoPointsRepository } from '../../domain/repositories/ecopoints_repository_interface';
import { IEcoPointsDataSource } from '../datasources/types/ecopoints_data_source_interface';
import { EcoPointEntity } from '../entities/ecopoint_entity';

type EcopointsRepositoryParams = {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: IEcoPointsDataSource;
};

export class EcoPointsRepository implements IEcoPointsRepository {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: IEcoPointsDataSource;

    constructor({ authCacheDataSource, apiDataSource }: EcopointsRepositoryParams) {
        this.apiDataSource = apiDataSource;
        this.authCacheDataSource = authCacheDataSource;
    }

    async getEcoPoints(coordinates: Coordinates): Promise<EcoPoint[] | IFailure> {
        try {
            const isAuthenticated = await this.authCacheDataSource.isAuthenticated();
            if (isAuthenticated) {
                const result: EcoPointEntity[] = await this.apiDataSource.getEcoPoints(coordinates);
                return Promise.resolve(result.map((ecoPoint: EcoPointEntity) => ecoPoint.toModel()));
            }
            const failure: IFailure = new AuthorizationFailure();
            FailureLogger.log(failure);
            return Promise.resolve(failure);
        } catch (error) {
            return Promise.resolve(errorToFailure<null>(error));
        }
    }
}
