import { Grid, Toolbar } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    authContext,
    browserPageContext,
    collectionPageContext,
    ecoFootprintPageContext,
    ecoPointsMapContext,
    profilePageContext,
    purchaseContext,
    purchasesPageContext,
    residuesPageContext,
    storesMapContext,
    useEcoFootprintPageBloc,
    usePurchasesPageBloc,
    useValidationsPageBloc,
    validationsPageContext,
    walletContext,
} from '../../contexts/contexts';

import ActionButton from '../../components/ActionButton/action_button';
import AppBar from '../../components/AppBar/app_bar';
import { BrowserPage } from '../../../../features/coupons/presentation/pages/browser_page/browser_page';
import { BrowserPageBloc } from '../../../../features/coupons/presentation/blocs/BrowserPageBloc/browser_page_bloc';
import { CollectionPage } from '../../../../features/collections/presentation/pages/CollectionPage/collection_page';
import { CouponBrowser } from '../../../../features/coupons/presentation/components/CouponBrowser/coupon_browser';
import { EcoFootprintIcon } from '../../components/Icons/icons';
import { EcoFootprintPage } from '../../../../features/ecofootprint/presentation/pages/EcoFootprintPage/ecofootprint_page';
import { EcoPointsPage } from '../../../../features/ecopoints/presentation/pages/ecopoints_page';
import { ExchangeStoresPage } from '../../../../features/coupons/presentation/pages/exchange_stores_page/exchange_stores';
import HomeProfile from '../../components/HomeProfile/home_profile';
import Logo from '../../components/Logo/logo';
import { ProfilePage } from '../../../../features/authentication/presentation/pages/ProfilePage/profile_page';
import { PurchasePage } from '../../../../features/coupons/presentation/pages/purchase_page/purchase_page';
import { PurchasesPage } from '../../../../features/coupons/presentation/pages/purchases_page/purchases_page';
import React from 'react';
import { ResiduesPage } from '../../../../features/collections/presentation/pages/ResiduesPage/residues_page';
import { ValidatedCollectionsPage } from '../../../../features/collections/presentation/pages/ValidatedCollectionsPage/validated_collections_page';
import Wallet from '../../../../features/wallet/presentation/components/Wallet/wallet';
import { WaveBackground } from '../../components/wave_background';
import di from '../../../../common/di/di';
import { useStyles } from './styles';

export const HomePageContent = () => {
    const featuredCouponsBloc = di.couponBrowserBloc(6);
    const browserPage = new BrowserPageBloc();
    const ecoFootprintBloc = useEcoFootprintPageBloc();
    const validationsPageBloc = useValidationsPageBloc();
    const purchasesPageBloc = usePurchasesPageBloc();
    const residuesPagebloc = di.residuesPageBloc();
    const classes = useStyles();
    return (
        <>
            <WaveBackground top={5} />
            <AppBar
                toolbar={
                    <Toolbar>
                        <Logo size={8} variant="large" />
                    </Toolbar>
                }
            >
                <Grid container className={`${classes.pageContainer}`}>
                    <div>
                        <Grid container direction="column" spacing={4}>
                            <Grid
                                item
                                container
                                justify="space-between"
                                alignItems="center"
                                style={{ flexWrap: 'nowrap' }}
                            >
                                <Grid item>
                                    <authContext.Provider value={di.authorizedRouteBloc()}>
                                        <profilePageContext.Provider value={di.profilePageBloc()}>
                                            <HomeProfile />
                                            <ProfilePage />
                                        </profilePageContext.Provider>
                                    </authContext.Provider>
                                </Grid>
                                <Grid item className={classes.action}>
                                    <ActionButton
                                        onClick={() => {
                                            ecoFootprintBloc.open();
                                        }}
                                        icon={<EcoFootprintIcon />}
                                    />
                                    <EcoFootprintPage />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <residuesPageContext.Provider value={residuesPagebloc}>
                                    <browserPageContext.Provider value={browserPage}>
                                        <>
                                            <Wallet
                                                onResidueLoadButtonClick={() => {
                                                    residuesPagebloc.open();
                                                }}
                                                onValidationButtonClick={() => {
                                                    validationsPageBloc.open();
                                                }}
                                                onCouponPurchaseButtonClick={() => {
                                                    browserPage.open();
                                                }}
                                                onMyCouponsButtonClick={() => {
                                                    purchasesPageBloc.open();
                                                }}
                                            />
                                            <BrowserPage />
                                            <PurchasesPage />
                                            <collectionPageContext.Provider value={di.collectionPageBloc()}>
                                                <ResiduesPage />
                                                <CollectionPage />
                                            </collectionPageContext.Provider>
                                        </>
                                    </browserPageContext.Provider>
                                </residuesPageContext.Provider>
                            </Grid>
                            <Grid item className={classes.overflow}>
                                <CouponBrowser
                                    couponsBrowserBloc={featuredCouponsBloc}
                                    title="Cupones Destacados"
                                    context="featured"
                                    once={true}
                                    browserContainerTop={482}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </AppBar>

            <storesMapContext.Provider value={di.storesMapBloc()}>
                <>
                    <PurchasePage />
                    <ExchangeStoresPage />
                </>
            </storesMapContext.Provider>
            <ecoPointsMapContext.Provider value={di.ecoPointsMapBloc()}>
                <>
                    <EcoPointsPage />
                    <ValidatedCollectionsPage />
                </>
            </ecoPointsMapContext.Provider>
        </>
    );
};

const HomePage = ({ history }: RouteComponentProps) => {
    return (
        <ecoFootprintPageContext.Provider value={di.ecoFootprintPageBloc()}>
            <purchasesPageContext.Provider value={di.purchasesPageBloc()}>
                <validationsPageContext.Provider value={di.validatedCollectionsPageBloc()}>
                    <walletContext.Provider value={di.walletBloc()}>
                        <purchaseContext.Provider value={di.purchaseBloc()}>
                            <HomePageContent></HomePageContent>
                        </purchaseContext.Provider>
                    </walletContext.Provider>
                </validationsPageContext.Provider>
            </purchasesPageContext.Provider>
        </ecoFootprintPageContext.Provider>
    );
};

export default withRouter(HomePage);
