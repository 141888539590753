import { createContext } from '../../../common/bloc/Context';
import { ProfilePageBloc } from '../../../features/authentication/presentation/blocs/profile_page_bloc/profile_page_bloc';
import { RecoveryConfirmationBloc } from '../../../features/authentication/presentation/blocs/recovery_confirmation_bloc/recovery_confirmation_bloc';
import { RecoveryRequestBloc } from '../../../features/authentication/presentation/blocs/recovery_request_bloc/recovery_request_bloc';
import { CollectionPageBloc } from '../../../features/collections/presentation/blocs/CollectionPageBloc/collection_page_bloc';
import { ResiduesPageBloc } from '../../../features/collections/presentation/blocs/ResiduesPageBloc/residues_page_bloc';
import { ValidatedCollectionsPageBloc } from '../../../features/collections/presentation/blocs/ValidatedCollectionsBloc/validated_collections_page_bloc';
import { BrowserPageBloc } from '../../../features/coupons/presentation/blocs/BrowserPageBloc/browser_page_bloc';
import { PurchaseBloc } from '../../../features/coupons/presentation/blocs/PurchasePageBloc/purchase_bloc';
import { PurchasesPageBloc } from '../../../features/coupons/presentation/blocs/PurchasesPageBloc/purchases_page_bloc';
import { StoresMapBloc } from '../../../features/coupons/presentation/blocs/StoresMapBloc/store_map_bloc';
import { EcoFootprintPageBloc } from '../../../features/ecofootprint/presentation/blocs/EcoFootprintBloc/ecofootprint_page_bloc';
import { EcoPointsBloc } from '../../../features/ecopoints/presentation/blocs/ecopoints_bloc';
import { WalletBloc } from '../../../features/wallet/presentation/blocs/wallet_bloc/wallet_bloc';
import { AuthorizedRouteBloc } from '../blocs/authorized_route_bloc';

export const [walletContext, useWalletBloc] = createContext<WalletBloc>();

export const [browserPageContext, useBrowserPageBloc] = createContext<BrowserPageBloc>();

export const [purchaseContext, usePurchaseBloc] = createContext<PurchaseBloc>();

export const [storesMapContext, useStoresMapBloc] = createContext<StoresMapBloc>();

export const [ecoPointsMapContext, useEcoPointsMapBloc] = createContext<EcoPointsBloc>();

export const [validationsPageContext, useValidationsPageBloc] = createContext<ValidatedCollectionsPageBloc>();

export const [purchasesPageContext, usePurchasesPageBloc] = createContext<PurchasesPageBloc>();

export const [residuesPageContext, useResiduesPageBloc] = createContext<ResiduesPageBloc>();

export const [collectionPageContext, useCollectionPageBloc] = createContext<CollectionPageBloc>();

export const [recoveryRequestContext, useRecoveryRequestBloc] = createContext<RecoveryRequestBloc>();

export const [recoveryConfirmationContext, useRecoveryConfirmationBloc] = createContext<RecoveryConfirmationBloc>();

export const [profilePageContext, useProfilePageBloc] = createContext<ProfilePageBloc>();

export const [ecoFootprintPageContext, useEcoFootprintPageBloc] = createContext<EcoFootprintPageBloc>();

export const [authRouteContext, useAuthRouteBloc] = createContext<AuthorizedRouteBloc>();

export const [authContext, useAuthBloc] = createContext<AuthorizedRouteBloc>();
