import { Button, Grid, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { SimpleHeader } from '../../components/SimpleHeader/simple_header';
import { WaveBackground } from '../../components/wave_background';
import { useStyles } from './styles';

const WelcomePage = ({ history }: RouteComponentProps) => {
    const classes = useStyles();

    const handleLogin = () => {
        history.push('/login');
    };
    const handleRegister = () => {
        history.push('/register');
    };

    return (
        <Fragment>
            <WaveBackground top={12} />
            <Grid container className={`${classes.pageContainer}`}>
                <Grid container item direction="column" spacing={4}>
                    <SimpleHeader text="EMPEZÁ UNA NUEVA ETAPA" variant="big" logo="large" />
                    <Grid container item className={`${classes.space1}`}>
                        <Typography variant="h2" color="textPrimary">
                            Bienvenido,
                            <span style={{ paddingLeft: '0.5rem', fontWeight: 'bold' }}>
                                ¡Ahora reciclás y tu aporte vale más!
                            </span>
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3} justify="space-between" className={`${classes.space2}`}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={`${classes.button} ${classes.secondaryButton}`}
                                onClick={handleLogin}
                            >
                                Ingresar
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={`${classes.button}`}
                                onClick={handleRegister}
                            >
                                Registrate
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default withRouter(WelcomePage);
