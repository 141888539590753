import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import React from 'react';

type InfoModalProps = {
    className?: string;
    onClose?: () => void;
    onOpen?: () => void;
    open: boolean;
    title: string;
    content: React.ReactElement | string;
    actions: React.ReactElement;
};

export const InfoModal: React.FC<InfoModalProps> = ({
    className,
    onClose,
    onOpen,
    open,
    title,
    content,
    actions,
}: InfoModalProps) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={className}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions>{actions}</DialogActions>
        </Dialog>
    );
};
