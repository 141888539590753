import { AuthorizationError } from '../../../../core/data/errors/authorization_error';
import { IAuthenticationCacheDataSource } from '../../../../core/data/types/authentication_cache_datasource_interface';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { ConsumerEntity } from '../../../authentication/data/entities/consumer_entity';
import { Coupon } from '../../domain/models/coupon';
import { Purchase } from '../../domain/models/purchase';
import { Store } from '../../domain/models/store';
import { ICouponsRepository } from '../../domain/repositories/coupons_repository_interface';
import { GetCouponsParams } from '../../domain/usecases/get_coupons_use_case';
import { GetCouponExchangeStoresParams } from '../../domain/usecases/get_coupon_exchange_stores';
import { PurchaseCouponParams } from '../../domain/usecases/purchase_coupon_use_case';
import { ICouponsDataSource } from '../datasources/types/coupons_data_source_interface';
import { PurchaseEntity } from '../entities/purchase_entity';
import { StoreEntity } from '../entities/store_entity';
import { errorToFailure } from './../../../../core/data/utils/error_to_failure';
import { PurchaseCouponValidations } from './../validations/purchase_coupon_validations';

type CouponsRepositoryParams = {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: ICouponsDataSource;
};

export class CouponsRepository implements ICouponsRepository {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: ICouponsDataSource;

    constructor({ authCacheDataSource, apiDataSource }: CouponsRepositoryParams) {
        this.apiDataSource = apiDataSource;
        this.authCacheDataSource = authCacheDataSource;
    }
    async getConsumerPurchases(): Promise<Purchase[] | IFailure> {
        try {
            const isAuthenticated = await this.authCacheDataSource.isAuthenticated();
            if (isAuthenticated) {
                const consumer = await this.authCacheDataSource.getConsumer();
                const result = await this.apiDataSource.getConsumerPurchases(consumer.id);
                return Promise.resolve((result as PurchaseEntity[]).map((item: PurchaseEntity) => item.toModel()));
            }
            throw new AuthorizationError();
        } catch (error) {
            return Promise.resolve(errorToFailure<null>(error));
        }
    }
    async getCouponExchangeStores({ coupon, coordinates }: GetCouponExchangeStoresParams): Promise<IFailure | Store[]> {
        try {
            const isAuthenticated = await this.authCacheDataSource.isAuthenticated();
            if (isAuthenticated) {
                const result: StoreEntity[] = await this.apiDataSource.getCouponExchangeStores({
                    couponId: coupon.id,
                    coordinates,
                });
                return Promise.resolve(result.map((store: StoreEntity) => store.toModel()));
            }
            throw new AuthorizationError();
        } catch (error) {
            return Promise.resolve(errorToFailure<null>(error));
        }
    }
    async purchaseCoupon({ coupon }: PurchaseCouponParams): Promise<IFailure | Purchase> {
        try {
            const isAuthenticated = await this.authCacheDataSource.isAuthenticated();
            if (isAuthenticated) {
                const consumer: ConsumerEntity = await this.authCacheDataSource.getConsumer();
                const result: PurchaseEntity = await this.apiDataSource.purchaseCoupon({
                    couponId: coupon.id,
                    consumerId: consumer.id,
                });
                return Promise.resolve(result.toModel());
            }
            throw new AuthorizationError();
        } catch (error) {
            return Promise.resolve(errorToFailure<PurchaseCouponValidations>(error));
        }
    }

    async getCoupons(params: GetCouponsParams): Promise<Coupon[] | IFailure> {
        try {
            const isAuthenticated = await this.authCacheDataSource.isAuthenticated();
            if (isAuthenticated) {
                const result = await this.apiDataSource.getCoupons(params);
                return Promise.resolve(result);
            } else {
                throw new AuthorizationError();
            }
        } catch (error) {
            return Promise.resolve(errorToFailure<null>(error));
        }
    }
}
