import { Coordinates } from '../../../../core/domain/types/Coordinates';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { Coupon } from '../models/coupon';
import { ICouponsRepository } from '../repositories/coupons_repository_interface';

export type GetCouponsParams = {
    query?: string;
    coordinates: Coordinates;
    page: number;
    size: number;
};

export class GetCouponsUseCase implements IUseCase<Coupon[], GetCouponsParams> {
    repository: ICouponsRepository;

    constructor(repository: ICouponsRepository) {
        this.repository = repository;
    }

    async execute({ query, coordinates, page, size }: GetCouponsParams): Promise<Coupon[] | IFailure> {
        return this.repository.getCoupons({ query, coordinates, page, size });
    }
}
