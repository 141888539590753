import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { IAuthenticationRepository } from '../repositories/IAuthenticationRepository';

export class RegisterConsumerUseCase implements IUseCase<boolean, RegistrationParams> {
    authRepository: IAuthenticationRepository;

    constructor(authRepository: IAuthenticationRepository) {
        this.authRepository = authRepository;
    }

    async execute(params: RegistrationParams): Promise<boolean | IFailure> {
        const result = await this.authRepository.register(params);
        return Promise.resolve(result);
    }
}

export type RegistrationParams = {
    username: string;
    password: string;
    name: string;
    surname: string;
    identification_number: number;
    email: string;
};
