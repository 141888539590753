import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { Coupon } from '../models/coupon';
import { Purchase } from '../models/purchase';
import { ICouponsRepository } from '../repositories/coupons_repository_interface';

export type PurchaseCouponParams = {
    coupon: Coupon;
};

export class PurchaseCouponUseCase implements IUseCase<Purchase, PurchaseCouponParams> {
    repository: ICouponsRepository;
    constructor(repository: ICouponsRepository) {
        this.repository = repository;
    }
    execute({ coupon }: PurchaseCouponParams): Promise<Purchase | IFailure> {
        return this.repository.purchaseCoupon({ coupon });
    }
}
