import { AxiosInstance } from 'axios';
import { IAxiosDataSource } from '../../../../core/data/types/axios_datasource_interface';
import { httpToError } from '../../../../core/data/utils/httpToError';
import { GetCouponsParams } from '../../domain/usecases/get_coupons_use_case';
import { CouponEntity } from '../entities/coupon_entity';
import { PurchaseEntity } from '../entities/purchase_entity';
import { StoreEntity } from '../entities/store_entity';
import { PurchaseCouponValidations } from '../validations/purchase_coupon_validations';
import {
    GetCouponExchangeStoresParams,
    ICouponsDataSource,
    PurchaseCouponParams,
} from './types/coupons_data_source_interface';

type AxiosCouponsDataSourceArgs = {
    axiosInstance: AxiosInstance;
};

export class AxiosCouponsDataSource implements IAxiosDataSource, ICouponsDataSource {
    axiosInstance: AxiosInstance;

    constructor({ axiosInstance }: AxiosCouponsDataSourceArgs) {
        this.axiosInstance = axiosInstance;
    }
    async getConsumerPurchases(consumerId: number): Promise<PurchaseEntity[]> {
        try {
            const { data } = await this.axiosInstance.get(`purchases`);
            return Promise.resolve((data as Array<any>).map((item: any) => PurchaseEntity.fromObject(item)));
        } catch (error) {
            return Promise.reject(httpToError<null>(error));
        }
    }
    async getCouponExchangeStores({ couponId, coordinates }: GetCouponExchangeStoresParams): Promise<StoreEntity[]> {
        try {
            const { data } = await this.axiosInstance.get(`coupons/${couponId}/stores`, {
                params: { latitude: coordinates.lat, longitude: coordinates.lng },
            });
            return Promise.resolve((data as Array<any>).map((item: any) => StoreEntity.fromObject(item)));
        } catch (error) {
            return Promise.reject(httpToError<null>(error));
        }
    }
    async purchaseCoupon({ couponId, consumerId }: PurchaseCouponParams): Promise<PurchaseEntity> {
        try {
            const { data } = await this.axiosInstance.post(`purchases`, {
                coupon_id: couponId,
            });
            return Promise.resolve(PurchaseEntity.fromObject(data));
        } catch (error) {
            return Promise.reject(httpToError<PurchaseCouponValidations>(error));
        }
    }
    async getCoupons({ query, coordinates, page, size }: GetCouponsParams): Promise<CouponEntity[]> {
        try {
            const { data } = await this.axiosInstance.get('coupons', {
                params: { criteria: query, latitude: coordinates.lat, longitude: coordinates.lng, page, size },
            });
            return Promise.resolve((data as Array<any>).map((item: any) => CouponEntity.fromObject(item)));
        } catch (error) {
            return Promise.reject(httpToError<null>(error));
        }
    }
}
