import { Grid, Paper, Typography } from '@material-ui/core';

import { BlocBuilder } from '../../../../../common/bloc';
import { CollectionPageState } from '../../blocs/CollectionPageBloc/collection_page_bloc_states';
import Language from './../../../../../core/presentation/strings/LanguageManager';
import MuiTextField from '../../../../../core/presentation/components/MUITextField/MuiTextField';
import React from 'react';
import residueImg from '../../../../../assets/images/residue.svg';
import { useCollectionPageBloc } from '../../../../../core/presentation/contexts/contexts';
import { useStyles } from './styles';

const server_path = process.env.REACT_APP_ADMIN_SERVER_URL;

export const CollectionCard: React.FC = () => {
    const classes = useStyles();
    const collectionPageBloc = useCollectionPageBloc();

    const handleCountChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        collectionPageBloc.changeCount(parseInt(event.target.value));
    };
    return (
        <BlocBuilder
            bloc={collectionPageBloc}
            builder={(state: CollectionPageState) => {
                const { residue, count } = state;
                if (!residue) return <></>;
                return (
                    <Paper elevation={3} className={classes.paper}>
                        <div className={classes.image_viewport}>
                            <img
                                className={classes.image}
                                src={residue.image ? `${server_path}${residue.image}` : `${residueImg}`}
                            />
                        </div>
                        <Grid container className={classes.info}>
                            <Grid item xs={12}>
                                <Typography color="textPrimary" className={classes.title}>
                                    {residue.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="textPrimary" className={classes.subtitle}>
                                    {Language.strings.collection_type}: {residue.kind}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="textPrimary" className={classes.subtitle}>
                                    {`${Language.strings.collection_value}: $${residue.value}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.count}>
                                <MuiTextField
                                    autoFocus
                                    type="number"
                                    variant="filled"
                                    color="primary"
                                    label={Language.strings.collection_count}
                                    value={count}
                                    onChange={handleCountChanged}
                                    InputProps={{
                                        inputProps: {
                                            min: 1,
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                );
            }}
        />
    );
};
