import { Bloc } from '../../../../../common/bloc';
import { Failure } from '../../../../../core/domain/failures/failure';
import { Coordinates } from '../../../../../core/domain/types/Coordinates';
import { Coupon } from '../../../domain/models/coupon';
import { Store } from '../../../domain/models/store';
import { GetCouponExchangeStoresUseCase } from '../../../domain/usecases/get_coupon_exchange_stores';
import { initialState, StoresMapState } from './store_map_bloc_states';

export class StoresMapBloc extends Bloc<StoresMapState> {
    usecase: GetCouponExchangeStoresUseCase;
    constructor(usecase: GetCouponExchangeStoresUseCase) {
        super(initialState);
        this.usecase = usecase;
    }

    async open(coupon: Coupon, coordinates: Coordinates): Promise<void> {
        this.changeState({ type: 'StoresMapLoading' });
        const result = await this.usecase.execute({ coupon, coordinates });
        if (result instanceof Failure) {
            this.changeState({
                type: 'Failure',
                failure: result,
            });
            return;
        }
        this.changeState({ type: 'StoresMapLoaded', stores: result as Store[] });
    }
    async close(): Promise<void> {
        this.changeState({ type: 'StoresMapClosed' });
    }
}
