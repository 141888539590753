import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import couponImg from '../../../../../assets/images/coupon.png';
import di from '../../../../../common/di/di';
import { Coupon } from '../../../domain/models/coupon';
import Language from './../../../../../core/presentation/strings/LanguageManager';
import { useStyles } from './styles';

const server_path = process.env.REACT_APP_SELLER_SERVER_URL;

type CouponCardProps = {
    coupon: Coupon;
    buttonText?: string;
    onClick?(event: React.MouseEvent): void;
    showExpireDate?: boolean;
    showExchangeDate?: boolean;
    exchangeDate?: string;
    showAction?: boolean;
};

export const CouponCard: React.FC<CouponCardProps> = ({
    coupon,
    onClick,
    buttonText = 'Comprar',
    showExpireDate = false,
    showExchangeDate = false,
    showAction = true,
    exchangeDate = undefined,
}: CouponCardProps) => {
    const classes = useStyles();
    const dateFormatter = di.dateFormatter();
    return (
        <Paper elevation={3} className={classes.paper}>
            <div className={classes.image_viewport}>
                <img className={classes.image} src={coupon.image ? `${server_path}${coupon.image}` : `${couponImg}`} />
            </div>
            <Grid container className={classes.info}>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.title}>
                        {coupon.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.price}>
                        {`${coupon.price} pts.`}
                    </Typography>
                </Grid>
                {showExpireDate ? (
                    <Grid item xs={12}>
                        <Typography color="textPrimary" className={classes.title}>
                            {`${Language.strings.coupons_expired_abr} ${dateFormatter.format(
                                'DD/MM/YYYY',
                                coupon.finishDate,
                            )}`}
                        </Typography>
                    </Grid>
                ) : null}

                {showExchangeDate ? (
                    <Grid item xs={12}>
                        <Typography color="textPrimary" className={classes.title}>
                            {`${Language.strings.coupons_exchanged_at} ${dateFormatter.format(
                                'DD/MM/YYYY',
                                exchangeDate as string,
                            )}`}
                        </Typography>
                    </Grid>
                ) : null}
                {showAction ? (
                    <Grid item xs={12}>
                        <Button color="primary" variant="contained" onClick={onClick}>
                            {buttonText}
                        </Button>
                    </Grid>
                ) : null}
            </Grid>
        </Paper>
    );
};
