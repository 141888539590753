import { Failure } from '../../../../../core/domain/failures/failure';
import { Store } from '../../../domain/models/store';

export interface IStoresMapClosed {
    type: 'StoresMapClosed';
}
export interface IStoresMapLoading {
    type: 'StoresMapLoading';
}
export interface IStoresMapLoaded {
    type: 'StoresMapLoaded';
    stores: Store[];
}
export interface IFailure {
    type: 'Failure';
    failure: Failure;
}
export interface IStoresMapUnknownFailure {
    type: 'StoresMapUnknownFailure';
    failure: IFailure;
}

export type StoresMapState = IStoresMapClosed | IStoresMapLoading | IStoresMapLoaded | IFailure;

export const initialState: StoresMapState = { type: 'StoresMapClosed' };
