import { Failure } from '../../../../../core/domain/failures/failure';
import { Coupon } from '../../../domain/models/coupon';
import { Purchase } from '../../../domain/models/purchase';

export interface IEmptyPurchase {
    type: 'EmptyPurchase';
}
export interface IPurchaseStarted {
    type: 'PurchaseStarted';
    coupon: Coupon;
}
export interface ISubmittingPurchase {
    type: 'SubmittingPurchase';
    coupon: Coupon;
}
export interface IPurchaseCompleted {
    type: 'PurchaseCompleted';
    purchase: Purchase;
    coupon: Coupon;
}

export interface IFailure {
    type: 'Failure';
    failure: Failure;
    coupon: Coupon;
}

export type PurchaseState = IEmptyPurchase | IPurchaseStarted | ISubmittingPurchase | IPurchaseCompleted | IFailure;

export const initialState: PurchaseState = { type: 'EmptyPurchase' };
