import { IModel } from '../../../../core/domain/types/model_interface';
import { ResidueType } from '../../../residue_types/domain/models/residue_type';

export type EcofootprintArgs = {
    count?: number;
    residueType: ResidueType;
};
export class Ecofootprint implements IModel {
    type = 'EcofootprintModel';
    count?: number;
    residueType: ResidueType;

    constructor({ count, residueType }: EcofootprintArgs) {
        this.count = count;
        this.residueType = residueType;
    }
}
