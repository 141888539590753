import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { IAuthenticationRepository } from '../repositories/IAuthenticationRepository';

export type RecoverPasswordParams = {
    token: string;
    password: string;
};
export class RecoverPassword implements IUseCase<void, RecoverPasswordParams> {
    repository: IAuthenticationRepository;

    constructor(repository: IAuthenticationRepository) {
        this.repository = repository;
    }

    execute(params: RecoverPasswordParams): Promise<void | IFailure> {
        return this.repository.recoverPassword(params);
    }
}
