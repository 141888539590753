import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        paper: (props: any) => {
            let color = theme.colors.main;
            switch (props.color) {
                case 'white-transparent':
                    color = theme.colors.whiteTransparent;
                    break;
                case 'grey-transparent':
                    color = theme.colors.greyTransparent;
                    break;
                case 'white':
                    color = theme.colors.white;
                    break;
                case 'grey':
                    color = theme.colors.grey;
                    break;
            }
            return {
                padding: `0px 16px 16px 16px`,
                backgroundColor: color,
                bottom: 0,
                minHeight: props.minHeight,
                maxHeight: props.maxHeight ? props.minHeight : 'initial',
                overflowY: props.maxHeight ? 'auto' : 'initial',
                borderRadius: '0px',
                position: 'relative',
                //backdropFilter: 'blur(3px)',
            };
        },
        header: (props: any) => {
            let color = theme.colors.main;
            switch (props.color) {
                case 'white-transparent':
                    color = theme.colors.whiteTransparent;
                    break;
                case 'grey-transparent':
                    color = theme.colors.greyTransparent;
                    break;
                case 'white':
                    color = theme.colors.white;
                    break;
                case 'grey':
                    color = theme.colors.grey;
                    break;
            }
            return {
                backgroundColor: color,
                //backdropFilter: 'blur(3px)',
                padding: theme.spacing(2),
                borderRadius: '15px 15px 0px 0px',
                height: '15.2px',
            };
        },
        title: {
            fontSize: '0.9rem',
        },
    });
});
