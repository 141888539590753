import { Failure } from '../../../../../core/domain/failures/failure';
import { Coupon } from '../../../domain/models/coupon';

export interface ICommonState {
    query?: string;
    page: number;
    hasMore: boolean;
    coupons: Coupon[];
}

export interface IBrowserIdle {
    type: 'BrowserIdle';
}
export interface ICouponsLoaded {
    type: 'CouponsLoaded';
}
export interface IFailure {
    type: 'Failure';
    failure: Failure;
}

export interface ILocationPermissionDeniedFailure {
    type: 'LocationPermissionDeniedFailure';
}
export interface ILocationFailure {
    type: 'LocationFailure';
}

export interface ICouponsQueryChanged {
    type: 'CouponsQueryChanged';
}

export type CouponsBrowserState = (
    | IBrowserIdle
    | ICouponsLoaded
    | IFailure
    | ICouponsQueryChanged
    | ILocationPermissionDeniedFailure
    | ILocationFailure
) &
    ICommonState;

export const browserInitialState: CouponsBrowserState = {
    type: 'BrowserIdle',
    query: '',
    page: 0,
    coupons: [],
    hasMore: false,
};
