export interface ProfileStrings {
    profile_surname: string;
    profile_name: string;
    profile_identification_number: string;
    profile_email: string;
    profile_logout: string;
    profile_saving: string;
    profile_save: string;
    profile_saved: string;

    profile_name_required_error: string;
    profile_image_mimes_error: string;
    profile_image_max_error: string;
    profile_surname_required_error: string;
    profile_identification_number_required_error: string;
    profile_identification_number_min_error: string;
    profile_email_required_error: string;
    profile_email_email_error: string;
    profile_email_unique_error: string;
    profile_change_password: string;
}

export const profileStrings: ProfileStrings = {
    profile_surname: 'Apellido',
    profile_name: 'Nombre',
    profile_identification_number: 'DNI',
    profile_email: 'Email',
    profile_logout: 'CERRAR SESIÓN',
    profile_saving: 'GUARDANDO CAMBIOS...',
    profile_save: 'GUARDAR CAMBIOS',
    profile_saved: '¡Se han guardado los cambios correctamente!',
    profile_change_password: 'CAMBIAR CONTRASEÑA',
    profile_name_required_error: 'El campo Nombre es requerido.',
    profile_surname_required_error: 'El campo Apellido es requerido.',
    profile_identification_number_required_error: 'El campo DNI es requerido.',
    profile_identification_number_min_error: 'El campo DNI debe tener al menos 8 dígitos.',
    profile_email_required_error: 'El campo Email es requerido.',
    profile_email_email_error: 'El valor del campo Email es inválido.',
    profile_email_unique_error: 'El email ingresado ya existe.',
    profile_image_mimes_error: 'Error: La imagen debe ser png, jpg o jpeg.',
    profile_image_max_error: 'Error: La imagen debe ser menor a 5 MB',
};
