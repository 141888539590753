export interface ConsumerStrings {
    consumer_code: string;
    consumer_code_helper: string;
}

export const consumerStrings: ConsumerStrings = {
    consumer_code: 'Código',
    consumer_code_helper:
        'Anotá el código en el exterior de la bolsa de tus residuos a dejar en el ECOPUNTO. Con el mismo te podrán validar tus puntos para poder canjearlos por cupones. Si hacen recolección a domicilio pasale el código al recolector.',
};
