import { Grid } from '@material-ui/core';
import React, { Fragment } from 'react';
import { createContext } from '../../../../../common/bloc/Context';
import di from '../../../../../common/di/di';
import { SimpleHeader } from '../../../../../core/presentation/components/SimpleHeader/simple_header';
import { WaveBackground } from '../../../../../core/presentation/components/wave_background';
import Language from '../../../../../core/presentation/strings/LanguageManager';
import { RegistrationBloc } from '../../blocs/registration_bloc/registration_bloc';
import { RegistrationForm } from '../../components/registration_form';
import { useStyles } from './styles';

const [blocContext, useBloc] = createContext<RegistrationBloc>();
export const useRegistrationBloc = useBloc;

const RegisterPage: React.FC = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <WaveBackground top={8} />
            <Grid container className={`${classes.pageContainer}`}>
                <Grid item container direction="column" spacing={2}>
                    <SimpleHeader text={Language.strings.registration_instructions} variant="small" logo="large" />
                    <Grid item className={`${classes.formContainer}`}>
                        <blocContext.Provider value={di.registrationBloc()}>
                            <RegistrationForm />
                        </blocContext.Provider>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default RegisterPage;
