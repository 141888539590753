import { AxiosInstance } from 'axios';
import { IAxiosDataSource } from '../../../../core/data/types/axios_datasource_interface';
import { httpToError } from '../../../../core/data/utils/httpToError';
import { ResidueTypeEntity } from '../entities/residue_type_entity';
import { IResidueTypeDataSource } from './types/residue_type_datasource_interface';

export class AxiosResidueTypeDataSource implements IResidueTypeDataSource, IAxiosDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async getResidueTypes(): Promise<ResidueTypeEntity[]> {
        try {
            const { data } = await this.axiosInstance.get(`residuetypes`);
            return Promise.resolve((data as Array<any>).map((item: any) => ResidueTypeEntity.fromObject(item)));
        } catch (error) {
            return Promise.reject(httpToError<null>(error));
        }
    }
}
