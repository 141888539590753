export interface LoginStrings {
    login_username: string;
    login_password: string;

    login_username_required_error: string;
    login_password_required_error: string;
    login_password_min_error: string;
    login: string;
    logout: string;
    login_welcome: string;

    login_login_authentication_error: string;
    login_exist_consumer_error: string;
}

export const loginStrings: LoginStrings = {
    login_username: 'Usuario',
    login_password: 'Contraseña',
    login_login_authentication_error: 'Usuario o contraseña incorrectos',
    login_exist_consumer_error: 'Usuario o contraseña incorrectos',
    login_username_required_error: 'El campo usuario es requerido',
    login_password_required_error: 'El campo contraseña es requerido',
    login_password_min_error: 'El contraseña debe tener al menos 8 carateres',
    login: 'Acceder',
    logout: 'Cerrar Sesión',
    login_welcome: 'BIENVENIDO',
};
