import { Button, CircularProgress, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { BlocBuilder } from '../../../../../common/bloc';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import FullScreenDialog from '../../../../../core/presentation/components/FullScreenDialog/fullscreen_dialog';
import { Logout } from '../../../../../core/presentation/components/LogoutRedirect/logout_redirect';
import { RoundedContainer } from '../../../../../core/presentation/components/RoundedContainer/rounded_container';
import { useCollectionPageBloc, useWalletBloc } from '../../../../../core/presentation/contexts/contexts';
import { useBlocSuscription } from '../../../../../core/presentation/hooks/useBlocSuscription';
import LanguageManager from '../../../../../core/presentation/strings/LanguageManager';
import { CollectionPageState } from '../../blocs/CollectionPageBloc/collection_page_bloc_states';
import { CollectionCard } from '../../components/CollectionCard/collection_card';
import { CollectionSuccessfulCard } from '../../components/CollectionSuccessfulCard/collection_successful_card';
import { useStyles } from './styles';

type CollectionPageProps = {
    onClose?: () => void;
};

export const CollectionPage: React.FC<CollectionPageProps> = ({
    onClose = () => {
        return;
    },
}: CollectionPageProps) => {
    const bloc = useCollectionPageBloc();
    const walletBloc = useWalletBloc();
    const classes = useStyles();

    const handleClose = () => {
        bloc.close();
        onClose();
    };

    const handleSubmitCollection = () => {
        bloc.createCollection();
    };

    useEffect(() => {
        const action = (state: CollectionPageState) => {
            if (state.type === 'CollectionSuccessful') walletBloc.getConsumerWallet();
        };
        bloc.subscribe(action);
        return () => {
            bloc.unsubscribe(action);
        };
    }, []);

    const { enqueueSnackbar } = useSnackbar();

    useBlocSuscription<CollectionPageState>({
        bloc,
        action: (state) => {
            if (state.type === 'Failure') {
                enqueueSnackbar(LanguageManager.strings.unknown_failure, { variant: 'error' });
            }
        },
    });

    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: CollectionPageState) => {
                if (state.type === 'Failure' && state.failure instanceof AuthorizationFailure) return <Logout />;

                return (
                    <FullScreenDialog
                        open={state.open}
                        title={LanguageManager.strings.collection_title}
                        onClose={handleClose}
                    >
                        <>
                            <RoundedContainer
                                top={80}
                                maxHeight={true}
                                title={LanguageManager.strings.collection_selected_residue}
                                color="grey-transparent"
                            >
                                <Grid container>
                                    {state.type !== 'CollectionSuccessful' ? (
                                        <>
                                            <CollectionCard />
                                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                {state.type === 'SubmittingCollection' ? (
                                                    <CircularProgress color="primary" style={{ margin: '1rem' }} />
                                                ) : (
                                                    <>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            fullWidth
                                                            onClick={handleSubmitCollection}
                                                            className={classes.button}
                                                        >
                                                            {LanguageManager.strings.collection_collect}
                                                        </Button>
                                                    </>
                                                )}
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                            {state.residue ? (
                                                <CollectionSuccessfulCard count={state.count} residue={state.residue} />
                                            ) : null}
                                        </Grid>
                                    )}
                                </Grid>
                            </RoundedContainer>
                        </>
                    </FullScreenDialog>
                );
            }}
        ></BlocBuilder>
    );
};
