import { Failure } from '../../../../../core/domain/failures/failure';
import { ModalState } from '../../../../../core/presentation/blocs/modal/modal_states';
import { Collection } from '../../../domain/models/collection';

export interface IIdle {
    type: 'Idle';
}

export interface ILoadingCollections {
    type: 'LoadingCollections';
}

export interface ICollectionsLoaded {
    type: 'CollectionsLoaded';
    collections: Collection[];
}

export interface IFailure {
    type: 'Failure';
    failure: Failure;
}

export type ValidatedCollectionsPageState = (IIdle | ILoadingCollections | ICollectionsLoaded | IFailure) & ModalState;

export const initialState: ValidatedCollectionsPageState = {
    type: 'Idle',
    open: false,
    block: false,
};
