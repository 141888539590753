import { Avatar, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { BlocBuilder } from '../../../../common/bloc';
import { ProfilePageState } from '../../../../features/authentication/presentation/blocs/profile_page_bloc/profile_page_state';
import { AuthorizedRouteState } from '../../blocs/authorized_route_bloc_states';
import { useAuthBloc, useProfilePageBloc } from '../../contexts/contexts';
import { useStyles } from './styles';

const server_url = process.env.REACT_APP_CONSUMER_SERVER_URL;

const HomeProfile: React.FC = () => {
    const classes = useStyles();
    const authBloc = useAuthBloc();
    const profileBloc = useProfilePageBloc();
    const handleClick = () => {
        profileBloc.open();
    };

    useEffect(() => {
        const action = (state: ProfilePageState) => {
            if (state.type === 'ProfileSubmitted') authBloc.getAuthenticated();
        };
        profileBloc.subscribe(action);
        authBloc.getAuthenticated();
        return () => {
            profileBloc.unsubscribe(action);
        };
    }, []);

    return (
        <BlocBuilder
            bloc={authBloc}
            builder={(state: AuthorizedRouteState) => {
                const consumer = state.type === 'AuthorizedConsumerLoaded' ? state.consumer : undefined;
                let avatar = '';
                if (consumer && consumer.image) {
                    avatar = `${server_url}${consumer.image}`;
                }
                return (
                    <Grid
                        container
                        alignItems="center"
                        style={{ flexWrap: 'nowrap', cursor: 'pointer' }}
                        spacing={2}
                        onClick={handleClick}
                    >
                        <Grid item>
                            <Avatar className={classes.avatar} src={avatar} />
                        </Grid>
                        <Grid container item>
                            <Grid item xs={12}>
                                <Typography className={`${classes.text} ${classes.greeting}`}>Hola</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="textSecondary" className={`${classes.text} ${classes.name}`}>
                                    {consumer ? `${consumer.name} ${consumer.surname}` : 'Usuario Anónimo'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};

export default React.memo(HomeProfile);
