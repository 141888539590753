import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoginPage from '../../../features/authentication/presentation/pages/LoginPage/login_page';
import RecoveryConfirmationPage from '../../../features/authentication/presentation/pages/RecoveryConfirmationPage/recovery_confirmation_page';
import RecoveryRequestPage from '../../../features/authentication/presentation/pages/RecoveryRequestPage/recovery_request_page';
import RegisterPage from '../../../features/authentication/presentation/pages/RegisterPage/register_page';
import { ScrollToTop } from '../components/ScrollToTop/scroll_to_top';
import WelcomePage from '../pages/WelcomePage/welcome_page';
import { AuthRoutes } from './AuthRoutes';

export const Routes = () => {
    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route exact path="/">
                    <WelcomePage />
                </Route>
                <Route exact path="/register">
                    <RegisterPage />
                </Route>
                <Route exact path="/login">
                    <LoginPage />
                </Route>
                <Route exact path="/password/request">
                    <RecoveryRequestPage />
                </Route>
                <Route path="/password/confirmation">
                    <RecoveryConfirmationPage />
                </Route>
                <Route component={AuthRoutes} />
            </Switch>
        </Router>
    );
};
