import { createMuiTheme } from '@material-ui/core/styles';

export default function createLightTheme() {
    return createMuiTheme({
        palette: {
            background: { default: '#0C3A2D' },
            primary: { main: '#FFB901' },
            secondary: { main: '#0C3A2D', contrastText: '#FFFFFF' },
            text: { primary: '#0C3A2D', secondary: '#FFFFFF' },
        },
        typography: {
            h1: { fontStyle: 'normal', fontWeight: 900, fontSize: '32px', lineHeight: '100%' },
            h2: { fontSize: '1.25rem', fontStyle: 'normal', fontWeight: 'normal' },
            button: { textTransform: 'none' },
        },
        shape: { borderRadius: 20 },
        colors: {
            main: '#CCD5D2C4',
            whiteTransparent: '#FFFFFFC4',
            white: '#FFFFFF',
            grey: '#EAEAEA',
            greyTransparent: '#EAEAEAC4',
        },
        button: { secondary: { main: '#B36616', dark: '#915313', contrastText: '#FFFFFF' } },
    });
}

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        colors: {
            main: string;
            whiteTransparent: string;
            greyTransparent: string;
            white: string;
            grey: string;
        };
        button: { secondary: { main: string; dark: string; contrastText: string } };
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        colors?: {
            main?: string;
            whiteTransparent?: string;
            greyTransparent?: string;
            white?: string;
            grey?: string;
        };
        button?: {
            secondary?: {
                main?: string;
                dark: string;
                contrastText?: string;
            };
        };
    }
}
