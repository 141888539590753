import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { areAnyNil } from '../../../../core/data/utils/areAnyNil';
import { IEntity } from '../../../../core/domain/types/entity_interface';
import { ResidueTypeEntity } from '../../../residue_types/data/entities/residue_type_entity';
import { Ecofootprint } from '../../domain/models/ecofootprint';

export type EcofootprintEntityArgs = {
    count?: number;
    residueType: ResidueTypeEntity;
};

export class EcofootprintEntity implements IEntity {
    type = 'EcofootprintEntity';
    count?: number;
    residueType: ResidueTypeEntity;

    constructor({ count, residueType }: EcofootprintEntityArgs) {
        this.count = count;
        this.residueType = residueType;
    }

    toModel(): Ecofootprint {
        return new Ecofootprint({ ...this, residueType: this.residueType.toModel() });
    }

    static fromObject(obj: any): EcofootprintEntity {
        try {
            const { count, residue_type } = obj;
            if (areAnyNil([residue_type])) {
                throw new Error();
            }
            return new EcofootprintEntity({ count, residueType: ResidueTypeEntity.fromObject(residue_type) });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'EcofootprintEntity');
        }
    }
}
