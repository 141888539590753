import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { areAnyNil } from '../../../../core/data/utils/areAnyNil';
import { IEntity } from '../../../../core/domain/types/entity_interface';
import { ResidueType, ResidueTypeArgs } from '../../domain/models/residue_type';

export type ResidueTypeEntityArgs = {
    id: number;
};

export class ResidueTypeEntity extends ResidueType implements IEntity {
    id: number;

    constructor({ id, name, kg, water, energy, co2, km, description, image }: ResidueTypeArgs & ResidueTypeEntityArgs) {
        super({ name, kg, water, energy, co2, km, description, image });
        this.id = id;
    }

    toModel(): ResidueType {
        return new ResidueType(this);
    }

    static fromObject(obj: any): ResidueTypeEntity {
        try {
            const { id, name, kg, water, energy, co2, km, description, image } = obj;
            if (areAnyNil([id, name, kg, water, energy, co2, km, description, image])) {
                throw new Error();
            }
            return new ResidueTypeEntity({ id, name, kg, water, energy, co2, km, description, image });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'ResidueTypeEntity');
        }
    }
}
