export interface RequestStrings {
    request_email: string;
    request_success: (email: string) => string;
    request_title: string;
    request_subtitle: string;
    request_email_required_error: string;
    request_email_email_error: string;
    request_email_exists_error: string;
}

export const requestStrings: RequestStrings = {
    request_email: 'Email',
    request_success: (email: string) =>
        `Ya casi terminamos!...enviamos un correo a ${email} con un enlace donde podrás cambiar tu contraseña!`,
    request_title: 'CAMBIO DE CONTRASEÑA',
    request_subtitle:
        '¡Escribí el email con el que te registraste y te enviaremos un enlace para que puedas cambiar tu contraseña!',
    request_email_required_error: 'El campo Email es requerido.',
    request_email_email_error: 'El valor del campo Email es inválido.',
    request_email_exists_error: 'El email ingresado no existe.',
};
