import { AxiosInstance } from 'axios';
import { IAxiosDataSource } from '../../../../core/data/types/axios_datasource_interface';
import { httpToError } from '../../../../core/data/utils/httpToError';
import { WalletEntity } from '../entities/wallet_entity';
import { IWalletDataSource } from './types/wallet_datasource_interface';

type AxiosWalletDatasourceParams = {
    axiosInstance: AxiosInstance;
};

export class AxiosWalletDataSource implements IWalletDataSource, IAxiosDataSource {
    axiosInstance: AxiosInstance;

    constructor({ axiosInstance }: AxiosWalletDatasourceParams) {
        this.axiosInstance = axiosInstance;
    }

    async getConsumerWallet(): Promise<WalletEntity> {
        try {
            const { data } = await this.axiosInstance.get(`wallet`);
            const entity = WalletEntity.fromObject(data);
            return entity;
        } catch (error) {
            return Promise.reject(httpToError<null>(error));
        }
    }
}
