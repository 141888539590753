import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { Collection } from '../models/collection';
import { Residue } from '../models/residue';
import { ICollectionsRepository } from '../repositories/collection_repository_interface';

export type CreateCollectionParams = {
    count: number;
    residue: Residue;
};

export class CreateCollectionUseCase implements IUseCase<Collection, CreateCollectionParams> {
    collectionsRepository: ICollectionsRepository;

    constructor(repository: ICollectionsRepository) {
        this.collectionsRepository = repository;
    }

    async execute(params: CreateCollectionParams): Promise<Collection | IFailure> {
        return this.collectionsRepository.createCollection(params);
    }
}
