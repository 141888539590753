import { AuthorizationError } from '../errors/authorization_error';
import { ServerError } from '../errors/server_error';
import { ValidationError } from '../errors/validation_error';

export type HttpResponse = {
    status: number;
    data?: any;
};

export function httpToError<ValidationsType>(error: any): Error {
    if (error.response) {
        const { response, message } = error;
        if (response.status === 500) return new ServerError(message);
        if (response.status === 400) return new ValidationError<ValidationsType>(response.data, message);
        if (response.status === 401) return new AuthorizationError(message);
        return new Error(message);
    }
    return error;
}
