import { CircularProgress, Grid, Typography } from '@material-ui/core';

import { AuthorizedRouteState } from '../../../../../core/presentation/blocs/authorized_route_bloc_states';
import BlocBuilder from '../../../../../common/bloc/BlocBuilder';
import { InputHelper } from '../../../../../core/presentation/components/input_helper/input_helper';
import Language from '../../../../../core/presentation/strings/LanguageManager';
import { Logout } from '../../../../../core/presentation/components/LogoutRedirect/logout_redirect';
import React from 'react';
import { useAuthRouteBloc } from '../../../../../core/presentation/contexts/contexts';
import { useStyles } from './styles';

const UserCode: React.FC = () => {
    const classes = useStyles();
    const bloc = useAuthRouteBloc();

    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: AuthorizedRouteState) => {
                if (state.type === 'AuthorizedConsumerFailed') return <Logout />;

                return (
                    <Grid container className={classes.container}>
                        <Grid item xs={12}>
                            <InputHelper message={Language.strings.consumer_code_helper} className={classes.helper}>
                                <Typography color="textSecondary">{`${Language.strings.consumer_code}:`}</Typography>
                            </InputHelper>
                        </Grid>
                        {state.type === 'LoadingAuthorizedConsumer' && <CircularProgress />}
                        {state.type === 'AuthorizedConsumerLoaded' && (
                            <Grid item xs={12}>
                                <Typography color="textSecondary" className={classes.code}>
                                    {state.consumer.code || '-'}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                );
            }}
        />
    );
};

export default UserCode;
