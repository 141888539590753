import { IModel } from '../../../../core/domain/types/model_interface';
import { Residue } from './residue';

export type CollectionArgs = {
    residue: Residue;
    date: string;
    count: number;
    value: number;
};

export class Collection implements IModel {
    type = 'CollectionModel';
    residue: Residue;
    date: string;
    count: number;
    value: number;
    constructor({ residue, date, count, value }: CollectionArgs) {
        this.residue = residue;
        this.date = date;
        this.count = count;
        this.value = value;
    }
}
