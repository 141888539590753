import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const media = theme.breakpoints.up('sm');

    return createStyles({
        pageContainer: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
            pageContainer: {
                width: '550px',
                margin: '0 auto',
            },
        },
        subtitle: {
            width: '12rem',
        },
        space1: {
            marginTop: '10rem',
        },
        space2: {
            marginTop: '1rem',
        },
        button: {
            width: '100%',
        },
        overflow: {
            ['&.MuiGrid-item']: {
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
                [`${media}`]: {
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                },
            },
        },
        action: {
            ['& .MuiButtonBase-root ']: {
                padding: '7px 3px 7px 11px',
            },
            ['& .icon img']: {
                width: '57px',
                height: '57px',
            },
        },
    });
});
