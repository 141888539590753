import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { BlocBuilder } from '../../../../../common/bloc';
import { Logout } from '../../../../../core/presentation/components/LogoutRedirect/logout_redirect';
import { useValidationsPageBloc, useWalletBloc } from '../../../../../core/presentation/contexts/contexts';
import { useBlocSuscription } from '../../../../../core/presentation/hooks/useBlocSuscription';
import LanguageManager from '../../../../../core/presentation/strings/LanguageManager';
import { WalletState } from '../../../../wallet/presentation/blocs/wallet_bloc/wallet_states';
import { ValidatedCollectionsPageState } from '../../blocs/ValidatedCollectionsBloc/validated_collections_page_states';
import { AuthorizationFailure } from './../../../../../core/domain/failures/authorization_failure';
import { useStyles } from './styles';

export const ValidatedCollectionsList = () => {
    const walletBloc = useWalletBloc();
    const validatedCollectionsBloc = useValidationsPageBloc();
    const classes = useStyles();

    useEffect(() => {
        if (validatedCollectionsBloc.state.open) validatedCollectionsBloc.getValidatedCollections();
    }, [validatedCollectionsBloc.state.open]);

    const { enqueueSnackbar } = useSnackbar();

    useBlocSuscription<ValidatedCollectionsPageState>({
        bloc: validatedCollectionsBloc,
        action: (state) => {
            if (state.type === 'Failure') {
                enqueueSnackbar(LanguageManager.strings.unknown_failure, { variant: 'error' });
            }
        },
    });

    return (
        <>
            <BlocBuilder
                bloc={walletBloc}
                builder={(state: WalletState) => {
                    if (state.type === 'WalletLoaded')
                        return (
                            <Paper className={classes.paper}>
                                <Grid container justify="space-between" alignItems="baseline">
                                    <Grid xs={4} item className={classes.info}>
                                        {LanguageManager.strings.collection_validated_current_balance}
                                    </Grid>
                                    <Grid xs={4} item className={classes.info}>
                                        {LanguageManager.strings.collection_validated_pending_balance}
                                    </Grid>
                                    <Grid xs={4} item className={classes.featuredHeader}>
                                        {LanguageManager.strings.collection_validated_final_balance}
                                    </Grid>
                                </Grid>
                                <Grid container justify="space-between" alignItems="flex-end">
                                    <Grid xs={4} item className={classes.price}>
                                        {`$${state.wallet.effective_balance}`}
                                    </Grid>
                                    <Grid xs={4} item className={classes.price}>
                                        {`$${state.wallet.pending_balance}`}
                                    </Grid>
                                    <Grid xs={4} item className={classes.featured}>
                                        {`$${state.wallet.effective_balance + state.wallet.pending_balance}`}
                                    </Grid>
                                </Grid>
                            </Paper>
                        );
                    return <></>;
                }}
            />
            <Grid container>
                <BlocBuilder
                    bloc={validatedCollectionsBloc}
                    builder={(state: ValidatedCollectionsPageState) => {
                        if (state.type === 'Failure' && state.failure instanceof AuthorizationFailure)
                            return <Logout />;
                        if (state.type === 'LoadingCollections')
                            return (
                                <Grid item className={classes.loading}>
                                    <CircularProgress color="primary" />
                                </Grid>
                            );

                        if (state.type === 'CollectionsLoaded' && state.collections.length > 0)
                            return (
                                <>
                                    <Grid container item justify="space-between">
                                        <Grid item xs={3} className={classes.price}>
                                            {LanguageManager.strings.collection_validated_amount}
                                        </Grid>
                                        <Grid item xs={6} className={classes.price}>
                                            {LanguageManager.strings.collection_validated_residue}
                                        </Grid>
                                        <Grid item xs={3} className={classes.price}>
                                            {LanguageManager.strings.collection_validated_value}
                                        </Grid>
                                    </Grid>
                                    {state.collections.map((collection) => {
                                        return (
                                            <Grid
                                                container
                                                item
                                                key={collection.residue.id}
                                                justify="space-between"
                                                className={classes.row}
                                            >
                                                <Grid item xs={3}>
                                                    {collection.count}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {collection.residue.name}
                                                </Grid>
                                                <Grid item xs={3}>
                                                    ${collection.value * collection.count}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </>
                            );
                        if (state.type === 'CollectionsLoaded' && state.collections.length === 0)
                            return (
                                <Grid item xs={12}>
                                    <Typography className={classes.loading}>
                                        {LanguageManager.strings.collection_validated_no_results}
                                    </Typography>
                                </Grid>
                            );
                        return <></>;
                    }}
                />
            </Grid>
        </>
    );
};
