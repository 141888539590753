import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React from 'react';
import BlocBuilder from '../../../../../common/bloc/BlocBuilder';
import { Logout } from '../../../../../core/presentation/components/LogoutRedirect/logout_redirect';
import { useWalletBloc } from '../../../../../core/presentation/contexts/contexts';
import { WalletState } from '../../blocs/wallet_bloc/wallet_states';
import { AuthorizationFailure } from './../../../../../core/domain/failures/authorization_failure';
import Language from './../../../../../core/presentation/strings/LanguageManager';
import { useStyles } from './styles';

type SimpleWalletProps = {
    variant?: 'pending_balance' | 'effective_balance';
};

const SimpleWallet: React.FC<SimpleWalletProps> = ({ variant = 'effective_balance' }: SimpleWalletProps) => {
    const classes = useStyles();
    const bloc = useWalletBloc();

    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: WalletState) => {
                let balance = '-';
                switch (state.type) {
                    case 'Failure':
                        if (state.failure instanceof AuthorizationFailure) {
                            return <Logout />;
                        }
                        break;
                    case 'WalletLoaded':
                        balance = `${
                            variant === 'effective_balance'
                                ? state.wallet.effective_balance
                                : state.wallet.pending_balance
                        }`;

                        break;
                }
                return (
                    <Grid container className={classes.container}>
                        <Grid item xs={12}>
                            <Typography color="textSecondary">
                                {variant === 'effective_balance'
                                    ? `${Language.strings.wallet_balance}:`
                                    : `${Language.strings.wallet_pending_balance}:`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="textSecondary" className={classes.balance}>
                                {state.type === 'LoadingWallet' ? <CircularProgress color="primary" /> : `$${balance}`}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};

export default SimpleWallet;
