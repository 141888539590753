import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import {
    default as Language,
    default as LanguageManager,
} from './../../../../../core/presentation/strings/LanguageManager';
import React, { Fragment, useEffect } from 'react';

import { AuthorizationFailure } from './../../../../../core/domain/failures/authorization_failure';
import { BlocBuilder } from '../../../../../common/bloc';
import { Coupon } from '../../../domain/models/coupon';
import { CouponCard } from '../CouponCard/coupon_card';
import { CouponsBrowserBloc } from '../../blocs/BrowserBloc/coupons_browser_bloc';
import { CouponsBrowserState } from '../../blocs/BrowserBloc/coupons_browser_states';
import { CuponIcon } from '../../../../../core/presentation/components/Icons/icons';
import InfiniteScroll from 'react-infinite-scroller';
import { Logout } from '../../../../../core/presentation/components/LogoutRedirect/logout_redirect';
import MuiTextField from '../../../../../core/presentation/components/MUITextField/MuiTextField';
import { PurchaseState } from '../../blocs/PurchasePageBloc/purchase_states';
import { RoundedContainer } from '../../../../../core/presentation/components/RoundedContainer/rounded_container';
import { SearchOutlined } from '@material-ui/icons';
import { useBlocSuscription } from '../../../../../core/presentation/hooks/useBlocSuscription';
import useGeoLocation from '../../../../../core/presentation/hooks/useLocation';
import { usePurchaseBloc } from '../../../../../core/presentation/contexts/contexts';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';

type CouponBrowserProps = {
    title: string;
    withSearchBox?: boolean;
    browserContainerTop?: number;
    couponsBrowserBloc: CouponsBrowserBloc;
    context?: 'featured' | 'browser';
    once?: boolean;
};

export const CouponBrowser: React.FC<CouponBrowserProps> = ({
    title,
    withSearchBox = false,
    browserContainerTop = 471,
    couponsBrowserBloc,
    context = 'featured',
    once = false,
}: CouponBrowserProps) => {
    const purchaseBloc = usePurchaseBloc();
    const classes = useStyles();
    const location = useGeoLocation({ enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 });

    useEffect(() => {
        const onPurchaseClosed = (state: PurchaseState) => {
            if (state.type === 'EmptyPurchase' && location) {
                couponsBrowserBloc.resetBrowser().then(() => {
                    handleFetchMore(location);
                });
            }
        };
        purchaseBloc.subscribe(onPurchaseClosed);
        return () => {
            purchaseBloc.unsubscribe(onPurchaseClosed);
        };
    }, [location, purchaseBloc.state]);

    const { enqueueSnackbar } = useSnackbar();

    useBlocSuscription<CouponsBrowserState>({
        bloc: couponsBrowserBloc,
        action: (state) => {
            switch (state.type) {
                case 'LocationFailure':
                    enqueueSnackbar(LanguageManager.strings.coupons_purchase_location_failure, { variant: 'error' });
                    break;
                case 'LocationPermissionDeniedFailure':
                    enqueueSnackbar(LanguageManager.strings.coupons_purchase_location_permission_failure, {
                        variant: 'error',
                    });
                    break;
                case 'Failure':
                    enqueueSnackbar(LanguageManager.strings.unknown_failure, { variant: 'error' });
            }
        },
    });

    useEffect(() => {
        if (location !== undefined) {
            handleFetchMore(location);
        }
    }, [location]);

    const handleFetchMore = async (location: any) => {
        if (location && location.loaded && location.coordinates && !location.error) {
            couponsBrowserBloc.getCoupons(location.coordinates);
        } else {
            if (location && location.loaded) {
                if (location && location.error?.code === 1) {
                    couponsBrowserBloc.locationPermissionDeniedFailure();
                    return;
                }
                couponsBrowserBloc.locationFailure();
            }
        }
    };

    const handleResetAndFetch = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await couponsBrowserBloc.resetBrowser();
        handleFetchMore(location);
    };

    const handleQueryChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        couponsBrowserBloc.changeQuery(event.target.value);
    };

    const handleCouponSelected = (coupon: Coupon) => {
        purchaseBloc.selectCoupon(coupon);
    };

    return (
        <Grid container direction="column">
            <BlocBuilder
                bloc={couponsBrowserBloc}
                builder={(state: CouponsBrowserState) => {
                    if (state.type === 'Failure' && state.failure instanceof AuthorizationFailure) return <Logout />;

                    let content: React.ReactElement = <></>;
                    if (state.type === 'BrowserIdle') {
                        content = (
                            <Grid container>
                                <Grid item xs={12}>
                                    <div style={{ textAlign: 'center', padding: '10px' }}>
                                        <CircularProgress style={{ margin: 'auto' }} />
                                    </div>
                                </Grid>
                            </Grid>
                        );
                    }

                    if (
                        state.type === 'Failure' ||
                        state.type === 'LocationFailure' ||
                        state.type === 'LocationPermissionDeniedFailure'
                    )
                        content = (
                            <Grid container>
                                <Grid item style={{ margin: '0 auto' }}>
                                    <Typography variant="subtitle1" color="error">
                                        {Language.strings.coupons_browser_not_found}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    if (state.type === 'CouponsLoaded' || state.type === 'CouponsQueryChanged') {
                        if (state.coupons.length === 0)
                            content = (
                                <Grid container>
                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                        <Typography variant="subtitle1">
                                            {Language.strings.coupons_browser_not_found}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );
                        else
                            content = (
                                <InfiniteScroll
                                    pageStart={0}
                                    threshold={500}
                                    loadMore={handleFetchMore}
                                    hasMore={!once ? state.hasMore : false}
                                    useWindow={context === 'featured'}
                                >
                                    <Grid container spacing={2}>
                                        {state.coupons?.map((coupon) => {
                                            return (
                                                <Grid item xs={6} key={coupon.id}>
                                                    <CouponCard
                                                        coupon={coupon}
                                                        onClick={() => {
                                                            handleCouponSelected(coupon);
                                                        }}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </InfiniteScroll>
                            );
                    }
                    return (
                        <Fragment>
                            {withSearchBox ? (
                                <form onSubmit={handleResetAndFetch}>
                                    <Grid item className={classes.searchBoxContainer}>
                                        <MuiTextField
                                            autoFocus
                                            className={classes.searchBox}
                                            variant="outlined"
                                            size="small"
                                            placeholder={Language.strings.coupons_search}
                                            fullWidth
                                            value={state.query || ''}
                                            onChange={handleQueryChanged}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton type="submit">
                                                        <SearchOutlined color="secondary" />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </form>
                            ) : null}

                            <RoundedContainer
                                top={browserContainerTop}
                                maxHeight={context === 'browser'}
                                title={Language.strings.coupons_coupons}
                                icon={<CuponIcon />}
                            >
                                {content}
                            </RoundedContainer>
                        </Fragment>
                    );
                }}
            />
        </Grid>
    );
};
