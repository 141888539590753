import { IFailure } from '../../../../core/domain/types/failure_interface';
import { IUseCase } from '../../../../core/domain/types/usecase_interface';
import { Purchase } from '../models/purchase';
import { ICouponsRepository } from '../repositories/coupons_repository_interface';

export class GetConsumerPurchasesUseCase implements IUseCase<Purchase[], undefined> {
    repository: ICouponsRepository;
    constructor(repository: ICouponsRepository) {
        this.repository = repository;
    }

    async execute(): Promise<Purchase[] | IFailure> {
        return await this.repository.getConsumerPurchases();
    }
}
