import { Bloc } from '../../../../../common/bloc';
import { Failure } from '../../../../../core/domain/failures/failure';
import { RegisterConsumerUseCase, RegistrationParams } from '../../../domain/usecases/register_consumer';
import { registrationInitialState, RegistrationState } from './registration_states';

type RegistrationBlocArgs = {
    registerConsumerUseCase: RegisterConsumerUseCase;
};
type InputChangedParams = {
    property: string;
    value: any;
};

export class RegistrationBloc extends Bloc<RegistrationState> {
    registerConsumerUseCase: RegisterConsumerUseCase;

    constructor({ registerConsumerUseCase }: RegistrationBlocArgs) {
        super(registrationInitialState);
        this.registerConsumerUseCase = registerConsumerUseCase;
    }

    async register(): Promise<void> {
        this.changeState({ ...this.state, type: 'SubmittingRegistration' });
        const result = await this.registerConsumerUseCase.execute(this.mapStateToUseCaseParams());
        if (result instanceof Failure) {
            this.changeState({ ...this.state, type: 'Failure', failure: result });
            return;
        }
        this.changeState({ ...this.state, type: 'RegistrationSuccessful' });
    }

    async changeInput(params: InputChangedParams): Promise<void> {
        const newState: RegistrationState = {
            ...this.state,
            [params.property]: params.value,
            type: 'FillingRegistration',
        };
        this.changeState(newState);
    }

    mapStateToUseCaseParams(): RegistrationParams {
        return {
            username: this.state.username,
            name: this.state.name,
            surname: this.state.surname,
            email: this.state.email,
            password: this.state.password,
            identification_number: parseInt(this.state.identification_number),
        };
    }
}
