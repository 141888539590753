import { Failure } from '../../../../../core/domain/failures/failure';
import { ResidueType } from '../../../../residue_types/domain/models/residue_type';
import { Residue } from '../../../domain/models/residue';
import { GetResiduesUseCase } from '../../../domain/usecases/get_residues_use_case';
import { ModalBloc } from './../../../../../core/presentation/blocs/modal/modal_bloc';
import { GetResidueTypesUseCase } from './../../../../residue_types/domain/usecases/get_residue_types_usecase';
import { initialState, ResiduesPageState } from './residues_page_states';

export type ResiduesPageBlocArgs = {
    getResiduesUseCase: GetResiduesUseCase;
    getResidueTypesUseCase: GetResidueTypesUseCase;
    residuesPageSize: number;
};

export class ResiduesPageBloc extends ModalBloc<ResiduesPageState> {
    getResiduesUseCase: GetResiduesUseCase;
    getResidueTypesUseCase: GetResidueTypesUseCase;
    residuesPageSize: number;

    constructor({ getResiduesUseCase, getResidueTypesUseCase, residuesPageSize }: ResiduesPageBlocArgs) {
        super(initialState);
        this.getResiduesUseCase = getResiduesUseCase;
        this.getResidueTypesUseCase = getResidueTypesUseCase;
        this.residuesPageSize = residuesPageSize;
    }

    async getResidues(): Promise<void> {
        const { page, query, hasMore, residues, showInfo, open, block, residueTypes } = this.state;

        const params = {
            query,
            page,
            size: this.residuesPageSize,
        };

        const result = await this.getResiduesUseCase.execute(params);
        if (result instanceof Failure) {
            this.changeState({
                page,
                query,
                hasMore,
                residues,
                showInfo: false,
                open,
                block,
                type: 'Failure',
                failure: result,
                residueTypes,
            });
            return;
        }
        this.changeState({
            query,
            open,
            block,
            showInfo: false,
            type: 'ResiduesLoaded',
            residues: [...this.state.residues, ...(result as Residue[])],
            page: this.state.page + 1,
            hasMore: (result as Residue[]).length / this.residuesPageSize >= 1,
            residueTypes,
        });
    }

    async getResiduesTypes(): Promise<void> {
        const { page, query, hasMore, residues, showInfo, open, block, residueTypes } = this.state;
        this.changeState({
            query,
            open,
            block,
            showInfo: false,
            type: 'LoadingResiduesType',
            residues,
            page,
            hasMore,
            residueTypes,
        });
        const result = await this.getResidueTypesUseCase.execute();
        if (result instanceof Failure) {
            this.changeState({
                page,
                query,
                hasMore,
                residues,
                showInfo: false,
                open,
                block,
                type: 'Failure',
                failure: result,
                residueTypes,
            });
            return;
        }
        this.changeState({
            query,
            open,
            block,
            showInfo: true,
            type: 'ResiduesTypesLoaded',
            residues,
            page,
            hasMore,
            residueTypes: result as ResidueType[],
        });
    }

    // TODO Test this
    async reset(): Promise<void> {
        const { query, showInfo, open, block, residueTypes } = this.state;

        this.changeState({
            query,
            showInfo,
            open,
            block,
            type: 'ReloadingResidues',
            page: 0,
            residues: [],
            hasMore: false,
            residueTypes,
        });
    }

    async changeQuery(query: string): Promise<void> {
        const { page, hasMore, residues, showInfo, open, block, residueTypes } = this.state;

        this.changeState({
            page,
            hasMore,
            residues,
            showInfo,
            open,
            block,
            type: 'QueryChanged',
            query,
            residueTypes,
        });
    }
}
