import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import wave1 from '../../../assets/images/wave1.svg';
import wave2 from '../../../assets/images/wave2.svg';
import wave3 from '../../../assets/images/wave3.svg';

interface WaveBackgroundProps {
    top: number;
    zIndex?: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        background: (props: WaveBackgroundProps) => ({
            position: 'fixed',
            zIndex: props.zIndex,
        }),
        common: (props: WaveBackgroundProps) => ({
            position: 'absolute',
            top: '0',
            width: '100vw',
            height: `${props.top}rem`,
            '&::before': {
                content: '""',
                width: 'calc( 100vw + 3918px )',
                display: 'block',
                position: 'absolute',
                left: 0,
            },
        }),
        front: {
            backgroundColor: theme.palette.background.default,
            '&::before': {
                background: `url(${wave1})`,
                backgroundRepeat: 'repeat-x',
                height: '119px',
                bottom: '-119px',
                animation: 'forward 35s linear infinite',
            },
        },
        middle: (props: WaveBackgroundProps) => ({
            backgroundColor: theme.palette.background.default,
            height: `${props.top + 2.2}rem`,
            '&::before': {
                background: `url(${wave2})`,
                height: '123px',
                bottom: '-123px',
                animation: 'backward 35s linear infinite',
            },
        }),
        back: (props: WaveBackgroundProps) => ({
            backgroundColor: theme.palette.secondary.main,
            height: `${props.top + 3}rem`,
            '&::before': {
                background: `url(${wave3})`,
                height: '120px',
                bottom: '-120px',
                animation: 'forward 30s linear infinite',
            },
        }),
    }),
);

export const WaveBackground: React.FC<WaveBackgroundProps> = ({ top, zIndex = -1 }: WaveBackgroundProps) => {
    const classes = useStyles({ top, zIndex });
    return (
        <div className={`${classes.background}`}>
            <div className={`${classes.common} ${classes.back}`} />
            <div className={`${classes.common} ${classes.middle}`} />
            <div className={`${classes.common} ${classes.front}`} />
        </div>
    );
};
