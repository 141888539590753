import { FailureLogger } from '../../../../common/utils/FailureLogger';
import { CacheError } from '../../../../core/data/errors/cache_error';
import { IAuthenticationCacheDataSource } from '../../../../core/data/types/authentication_cache_datasource_interface';
import { AuthorizationFailure } from '../../../../core/domain/failures/authorization_failure';
import { IFailure } from '../../../../core/domain/types/failure_interface';
import { Collection } from '../../domain/models/collection';
import { ICollectionsRepository } from '../../domain/repositories/collection_repository_interface';
import { CreateCollectionParams } from '../../domain/usecases/create_collection_use_case';
import { ICollectionsDataSource } from '../datasources/types/collections_datasource_interface';
import { CollectionEntity } from '../entities/collection_entity';
import { errorToFailure } from './../../../../core/data/utils/error_to_failure';

type CollectionsRepositoryParams = {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: ICollectionsDataSource;
};

export class CollectionsRepository implements ICollectionsRepository {
    authCacheDataSource: IAuthenticationCacheDataSource;
    apiDataSource: ICollectionsDataSource;

    constructor({ authCacheDataSource, apiDataSource }: CollectionsRepositoryParams) {
        this.apiDataSource = apiDataSource;
        this.authCacheDataSource = authCacheDataSource;
    }

    async createCollection(params: CreateCollectionParams): Promise<Collection | IFailure> {
        try {
            const isAuthorized = await this.authCacheDataSource.isAuthenticated();
            if (isAuthorized) {
                const consumer = await this.authCacheDataSource.getConsumer();
                const result = await this.apiDataSource.createCollection({
                    consumerId: consumer.id,
                    residueId: params.residue.id,
                    count: params.count,
                });
                return Promise.resolve((result as CollectionEntity).toModel());
            }
            return Promise.resolve(new AuthorizationFailure());
        } catch (error) {
            if (error instanceof CacheError) return Promise.resolve(new AuthorizationFailure());
            return Promise.resolve(errorToFailure<null>(error));
        }
    }

    async getValidatedCollections(): Promise<Collection[] | IFailure> {
        try {
            const isAuthenticated = await this.authCacheDataSource.isAuthenticated();
            if (!isAuthenticated) {
                const failure = new AuthorizationFailure();
                FailureLogger.log(failure);
                return failure;
            }
            const consumer = await this.authCacheDataSource.getConsumer();
            const result = await this.apiDataSource.getValidatedCollections(consumer.id);
            return Promise.resolve((result as CollectionEntity[]).map((validation) => validation.toModel()));
        } catch (error) {
            return Promise.resolve(errorToFailure<null>(error));
        }
    }
}
