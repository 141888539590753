import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    const media = theme.breakpoints.up('sm');

    return createStyles({
        pageContainer: {
            padding: theme.spacing(4),
        },
        [theme.breakpoints.up('sm')]: {
            pageContainer: {
                width: '550px',
                margin: '0 auto',
            },
        },
        subtitle: {
            width: '12rem',
        },
        space1: {
            marginTop: '10rem',
        },
        space2: {
            marginTop: '1rem',
        },
        button: {
            width: '100%',
        },
        secondaryButton: {
            backgroundColor: theme.button.secondary.main,
            color: theme.button.secondary.contrastText,
            ['&:hover']: {
                backgroundColor: theme.button.secondary.dark,
            },
        },
    });
});
