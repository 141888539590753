import { IModel } from '../../../../core/domain/types/model_interface';

export type CouponParams = {
    id: number;
    title: string;
    description: string;
    price: number;
    image: string;
    terms: string;
    finishDate: string;
    stock: number;
    category: string;
};

export class Coupon implements IModel {
    type = 'CouponModel';
    id: number;
    title: string;
    description: string;
    price: number;
    image: string;
    terms: string;
    finishDate: string;
    stock: number;
    category: string;

    constructor({ id, title, description, price, image, terms, finishDate, stock, category }: CouponParams) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.price = price;
        this.image = image;
        this.terms = terms;
        this.finishDate = finishDate;
        this.stock = stock;
        this.category = category;
    }
}
