import _ from 'lodash';
import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';
import { ValidationFailure } from '../../domain/failures/validation_failure';
import { collectionStrings, CollectionStrings } from './ES/collections';
import { consumerStrings, ConsumerStrings } from './ES/consumer';
import { couponStrings, CouponStrings } from './ES/coupons';
import { ecopointStrings, EcopointStrings } from './ES/ecopoints';
import { globalStrings, GlobalStrings } from './ES/global';
import { loginStrings, LoginStrings } from './ES/login';
import { ProfileStrings, profileStrings } from './ES/profile';
import { RecoveryStrings, recoveryStrings } from './ES/recovery';
import { RegistrationStrings, registrationStrings } from './ES/registration';
import { requestStrings, RequestStrings } from './ES/request';
import { ResidueStrings, residueStrings } from './ES/residues';
import { walletStrings, WalletStrings } from './ES/wallet';

class Language {
    static set = (key: 'es') => {
        Language.strings.setLanguage(key);
    };
    static strings: LocalizedStringsMethods &
        GlobalStrings &
        LoginStrings &
        RegistrationStrings &
        RequestStrings &
        ProfileStrings &
        ConsumerStrings &
        RecoveryStrings &
        EcopointStrings &
        CouponStrings &
        WalletStrings &
        ConsumerStrings &
        CollectionStrings &
        ResidueStrings = new LocalizedStrings({
        es: {
            ...globalStrings,
            ...loginStrings,
            ...registrationStrings,
            ...profileStrings,
            ...recoveryStrings,
            ...requestStrings,
            ...walletStrings,
            ...ecopointStrings,
            ...couponStrings,
            ...consumerStrings,
            ...collectionStrings,
            ...residueStrings,
        },
    });
    static getErrorString = (prefix: string, input: string, state: any): string => {
        if (state.type === 'Failure' && state.failure instanceof ValidationFailure) {
            if (state.failure.fails[input]) {
                const s = Language.strings.getString(`${prefix}_${input}_${state.failure.fails[input].code}`);
                if (!s || _.isEmpty(s)) return Language.strings.unknown_failure;
                return s;
            }
        }
        return '';
    };
}
export default Language;
