import MuiAppBar from '@material-ui/core/AppBar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.secondary,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }),
);

interface Props {
    children: React.ReactElement;
    toolbar: React.ReactElement;
}

function ElevationScroll(props: Props) {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(props.children, {
        elevation: trigger ? 4 : 0,
    });
}

export default function AppBar(props: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <React.Fragment>
            <ElevationScroll {...props}>
                <MuiAppBar className={classes.root} color="primary">
                    {props.toolbar}
                </MuiAppBar>
            </ElevationScroll>
            <Toolbar />

            {props.children}
        </React.Fragment>
    );
}
